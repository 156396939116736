import { useSelector } from 'react-redux'; // Import the useSelector hook from Redux
import { VStack, Heading, Box, Text, useColorMode } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { RootState } from "../types/interface";
import { Header } from '../components';

  const volunteeringInformationEn = `
  If you are interested in volunteering, we have various opportunities available in different lanes:
  
  Lane 1. Ecological Knowledge and Preservation
    A. Field work
      We seek scholars (from anywhere) dedicated to working in remote areas, focusing on the preservation of Ewe-speaking regions' biodiversity. This could involve tasks such as studying plants, animals, trees, birds, and collecting ecological data. Individuals with backgrounds in environmental science, biology, or related fields are encouraged to apply.

      Required skills:
      Fieldwork interview skills, interpersonal skills, data collection, and recording.

    B. Botanical and Zoological Preservation
      We are looking for individuals passionate about preserving Ewe's rich flora and fauna. This involves freelance work in annotating, editing, and moderating entries related to plants, animals, and ecological data. Your work will contribute to the overall preservation of our natural resources.

      Required skills:
      General computer knowledge, deep bilingual proficiency in Ewe and English, knowledge of local flora and fauna, and/or a willingness to research.

    C. Ecological Data Management
      For individuals with a background in environmental science or related fields, we seek assistance in managing ecological data. This includes verifying and organizing data entries, ensuring accuracy, and contributing to the development of our ecological database.

      Required skills:
      General computer knowledge, knowledge of data management, and a passion for ecological preservation.

  Lane 2. Social Media related to Ecological Awareness
    A. Social Media/PR
      We seek individuals interested in contributing to our PR department with a focus on ecological awareness. This includes managing social media (Facebook, Twitter, Instagram), newsletter creation, blog posts, and other outreach activities related to our ecological initiatives.

      Required skills:
      Knowledge of social media, proficiency in Ewe, and basic computer design skills.

    B. Environmental Outreach
      We are looking for individuals to assist with public outreach, seeking support from universities, environmental organizations, studios, institutes, and other relevant entities to bolster our ecological preservation efforts.

      Required skills:
      Local and international contacts/connection, passion for environmental conservation.

  Lane 3. Software Development related to Ecological Data
    To maintain and enhance the software supporting our ecological database, we seek developers proficient in Nest.js programming and/or JavaScript programming with experience or interest in working with AngularJs.

      Required skills:
      Proficient in Javascript, knowledge of NEST-JS framework or AngularJs.

  All these positions are on a volunteer basis. We are not – for now – able to offer financial incentives. However, the work carries intrinsic rewards, and we hope that your decision to join us stems from a belief in the importance of preserving our ecological heritage.

  Send an email to contact@ewe.com.ng with the word “volunteer” and the specific lane and category in the subject line. For instance: “Volunteer 3A” for those interested in the PR position.
`;
const volunteeringInformationYo = `
Ọ̀nà àkọ́kọ́: Ìmọ̀ nípa àyíká wa àti bí aṣẹ ma ṣe ìtọ́jú rẹ̀ 
A. À ńwá àwọn ọ̀jọ̀gbọ́n tí wọ́n lè ṣíṣẹ́ ní ibikíbi láti lè mójú tó àyíká ibi tí ójẹ́ èdè Yorùbá ni wọ́n sọ níbẹ̀.
Àwọn iṣẹ́ tí wọ́n ma ṣe pẹ̀lú kí wọ́n ma ṣọ́ oun ọ̀gbìn, eranko, igi, ẹyẹ àti bí wọ́n ṣe ń ṣe pẹ̀lú àyíká wọn. Àwọn tí wọ́n ní ìmọ̀ ìjìnlẹ̀ nípa àyíká wa, ìmò nípa bí ara ṣe ńṣiṣẹ́ àti àwọn ìmọ̀ tí ó jọ mọ́ irú àwọn ìmọ̀ báyì, a gbà wọ́n ní ìyànjú pé kí wọ́n dara pọ̀. 

Àwọn oun tí wọ́n ní láti ní fún iṣẹ́ yìí
Bí a ṣe lè gbé ìbéèrè kalẹ lẹ́nu iṣẹ́ oko 
Bí a ṣe lè bá ara wa ṣe
Bí a ṣe lè ṣe àkójọpò ìmọ̀ 
Bí a ṣe lè ṣe àyà wòran 
B. Ẹ̀kọ́ nípa ewé pẹ̀lú àwọn ẹranko àti àmójútó wọn. 
A ńwá àwọn ẹni tí wọ́n nífẹ̀ sí àmójútó àwọn oun ọ̀gbìn wa. Láti ṣe iṣẹ́ yìí, ẹni náà ní láti mọ bí a ṣe ńkọ ìwé ránpé, bí a ṣe ńṣe àtúnṣe àti bí a ṣe ma ṣe ètò àti kó àwọn ìmọ̀ yí wọlé nípa àwọn oun ọ̀gbìn, ẹranko pẹ̀lú ìmọ̀ bí wọ́n ṣe ńṣe pẹ̀lú àyíká wọn. Iṣẹ́ yìí á kópa nínú àmójútó àwọn àlùmọ́nì ilẹ̀ wa

Àwọn oun tí wọ́n ní láti ní fún iṣẹ́ yìí
Ìmọ̀ Bí a ṣe ńlo kọ̀mpútà, ìmọ̀ ìjìnlẹ̀ nínú ède Yorùbá àti gẹ̀ẹ́sì, ìmọ̀ ewé àti egbò ilẹ̀ Yorùbá tàbí kẹ̀ tí wọ́n bá nífẹ̀ sí àti ṣe iṣẹ́ ìwádìí.

Ọ̀nà ìkejì: ayélujára tó ní ṣe pẹ̀lú àkíyèsí àyíká wa
A. Ayélujára /Ìrínisí
À ńwá àwọn tí ó wù wọ́n láti báwa ṣiṣẹ́ lórí ìrínisí wa tí ó sì ní ṣe pẹ̀lú ìfojúsùn àyíká wa. Èyí ní ṣe pẹ̀lú ìmójútó ayélujára lórí (Facebook, Twitter, Instagram) ìwé ìròyìn kíkọ èro àtẹ̀jísẹ́ pẹ̀lú àwọn iṣẹ́ tí a bá ṣe kiri lórí ọ̀rọ̀ àyíká wa. 
Àwọn oun tí wọ́n ní láti ní fún iṣẹ́ yìí
Bí a ṣe ńlo ayélujára 
Ìmọ̀ ìjìnlẹ̀ nínú Ewé 
Bí a ṣé ńkọ iṣẹ́ fún kọmpútà 
B. Nína ọwọ́ sí ìta fún agbèbè wa
À ń wá àwọn tí wọ́n lè ràn wá lọ́wọ́ nípasẹ̀ kí a na'wọ́ sí ìta gbangba, kí a wá ìrànlọwọ láti ọ̀dọ fásitì, àwọn ẹgbẹ́ tó jọ mọ́ mímójútó àyíká, iyàrá eré, ilé ìmọ̀ àti àwọn ìgbìmọ̀ ìyókù tó ní ṣe pẹ̀lú àmójútó àyíká. 
Àwọn oun tí wọ́n ní láti ní fún iṣẹ́ yìí
Mímọ ẹni ní ilẹ̀ wa àti ní òkè òkun 
Mímú ìmójútó àyíká ní òkúkúdùn
Ọ̀nà Ìkẹta 
Mímọ bí a ṣe ń kọ́ kọ̀mpútà bí ó ṣe ma ṣiṣẹ́ ni ìlànà ìmọ̀ àyíká wa
Àti lè ṣe àmójútó àti ìdàgbàsókè fún àwọn iṣẹ́ kọ̀mpútà tí yíò ti ìmọ̀ àyíká wa lẹ́yìn, à ńwá àwọn tí wọ́n lè dá nǹkan nínú Nest-Js Programming tàbí Javascript programming pẹ̀lú ìmọ̀ abí ìfẹ́ sí àti ṣiṣẹ́ pẹ̀lú AngularJs.
Àwọn oun tí wọ́n ní láti ní fún iṣẹ́ yìí
Ìmọ̀ ìjìnlè nínú Javascript, ìmọ̀ nínú iṣẹ́ Nest-Js abi AngularJs. 
Àwọn ipò tí ó wà yí jẹ́ iṣẹ́ ìyànda. A ò tíì lè san owó iṣẹ́ fún ẹnikẹ́ni ni àkókò yìí, ṣùgbọ́n iṣẹ́ náà jẹ́ iṣẹ́ tí óní ìtumọ̀ tí ó sì ní èrè púpọ̀. A sì lérò pé ìpinu àti dara pọ̀ mọ́ wa ní ṣe pẹ̀lú ìpinu pe ìmójútó àyíká waá ṣe pàtàkì fún ọjọ́ ọ̀la wa.
Ẹ fi àtẹ̀jíṣẹ́ ránṣẹ́ sí contact@ewe.com.ng kẹ kọ "ìyànda" sí ọ̀nà èyí tí ẹ bá fẹ́ pẹ̀lú ọ̀nà tó tẹ̀lé wọn. Fún àpẹrẹ: Ọ̀nà Ìkẹta A fún àwọn tí wọ́n fẹ́ ṣe iṣẹ́ ìrínisí.

`;
const volunteeringInformationEs = `
Si estás interesado en ser voluntario, tenemos varias oportunidades disponibles en diferentes áreas:

Área 1. Conocimiento Ecológico y Preservación
  A. Trabajo de campo
    Buscamos académicos (de cualquier lugar) dedicados a trabajar en áreas remotas, enfocándose en la preservación de la biodiversidad de las regiones de habla Ewe. Esto podría implicar tareas como estudiar plantas, animales, árboles, aves y recolectar datos ecológicos. Se anima a postularse a personas con formación en ciencias ambientales, biología o campos relacionados.

    Habilidades requeridas:
    Habilidades para entrevistas de trabajo de campo, habilidades interpersonales, recopilación y registro de datos.

  B. Preservación Botánica y Zoológica
    Buscamos personas apasionadas por preservar la rica flora y fauna de Ewe. Esto implica trabajo freelance en la anotación, edición y moderación de entradas relacionadas con plantas, animales y datos ecológicos. Tu trabajo contribuirá a la preservación general de nuestros recursos naturales.

    Habilidades requeridas:
    Conocimiento general de computadoras, alta competencia bilingüe en Ewe e inglés, conocimiento de la flora y fauna local, y/o disposición a investigar.

  C. Gestión de Datos Ecológicos
    Para personas con formación en ciencias ambientales o campos relacionados, buscamos ayuda en la gestión de datos ecológicos. Esto incluye verificar y organizar entradas de datos, asegurar la precisión y contribuir al desarrollo de nuestra base de datos ecológica.

    Habilidades requeridas:
    Conocimiento general de computadoras, conocimiento de gestión de datos y pasión por la preservación ecológica.

Área 2. Redes Sociales relacionadas con la Conciencia Ecológica
  A. Redes Sociales/PR
    Buscamos personas interesadas en contribuir a nuestro departamento de relaciones públicas con un enfoque en la conciencia ecológica. Esto incluye gestionar redes sociales (Facebook, Twitter, Instagram), creación de boletines, publicaciones de blog y otras actividades de difusión relacionadas con nuestras iniciativas ecológicas.

    Habilidades requeridas:
    Conocimiento de redes sociales, competencia en Ewe y habilidades básicas de diseño en computadora.

  B. Difusión Ambiental
    Buscamos personas que ayuden con la divulgación pública, buscando apoyo de universidades, organizaciones ambientales, estudios, institutos y otras entidades relevantes para fortalecer nuestros esfuerzos de preservación ecológica.

    Habilidades requeridas:
    Contactos/conexiones locales e internacionales, pasión por la conservación ambiental.

Área 3. Desarrollo de Software relacionado con Datos Ecológicos
  Para mantener y mejorar el software que apoya nuestra base de datos ecológica, buscamos desarrolladores con experiencia en programación Nest.js y/o JavaScript con experiencia o interés en trabajar con AngularJs.

    Habilidades requeridas:
    Competencia en Javascript, conocimiento del framework NEST-JS o AngularJs.

Todos estos puestos son voluntarios. No podemos ofrecer incentivos financieros por ahora. Sin embargo, el trabajo tiene recompensas intrínsecas y esperamos que tu decisión de unirte a nosotros provenga de la creencia en la importancia de preservar nuestro patrimonio ecológico.

Envía un correo electrónico a contact@ewe.com.ng con la palabra "voluntario" y el área específica y categoría en la línea de asunto. Por ejemplo: "Voluntario 3A" para aquellos interesados en la posición de PR.
`;


const Contact = () => {
  // Assuming you have a Redux store set up with the 'language' state
  const language = useSelector((state: RootState) => state.language.language) 
  const { colorMode } = useColorMode();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeIn" }}
      style={{ backgroundColor:  colorMode === 'light' ? 'white' : '#345430'}}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us Page</title>
      </Helmet>
      <Header />
      <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeIn" }}
      style={{ backgroundColor:  colorMode === 'light' ? 'white' : '#345430'}}
    >
      <Box 
       paddingTop="20px"
       paddingBottom="20px"
       marginRight={{ base: "15", md: "20"}}
       marginLeft={{ base: "15", md: "20"}}
        textAlign="justify"
        fontFamily="Inter">
        <VStack spacing={8} fontFamily="Inter" align="center" mt={8} paddingTop={20} paddingBottom={20}>
          <Heading
            fontFamily="Inter"
            as="h4"
            size="xl"
            textAlign="justify"
            fontWeight={600}
          >
      {language === 'en' ? 'Contact Us' : language === 'es' ? 'Contáctenos' : 'Kàn Sí Wa'}
          </Heading>
          <Text
            fontSize="lg"
          >
          {language === 'en' ? 'Welcome to Ewe, your gateway to the rich world of Isese and Yoruba culture! We are on a mission to contribute to the Isese and Yoruba community by building an app that focuses on plants, herbs, flowers, seeds, trees, animals, birds, and all herbal services in the community.' 
            : language === 'es' ? `Bienvenido a Ewe, tu puerta de entrada al rico mundo de la cultura de Isese y Yoruba! Estamos en una misión para contribuir a la comunidad de Isese y Yoruba construyendo una aplicación que se centre en plantas, hierbas, flores, semillas, árboles, animales, aves, y todos los servicios herbales en la comunidad.` 
            : `Ẹkáàbọ̀ sí Ewé, ọ̀nà tí yí ò gbé e yín wọ inú agbagbin ọrọ̀ Ìṣẹ̀ṣe àti àṣà pẹ̀lú ìṣe àwùjọ Yòrùbá! Ìpinu wa ni láti dá sí ìdàgbàsókè Ìṣẹ̀ṣe àti àwùjọ Yòrùbá ni o mu wá dá ìmúlò (app) yí tí ó jẹ́ pé oun ọ̀gbìn, ewé ati egbò, òdòdó, èso, igi, ẹranko, ẹyẹ àti gbogbo tewé'tegbò ni àwùjọ. 
            `}
          </Text>
          <Text
            fontSize="lg"
          >
            {language === 'en' ? 'At Ewe, we believe in the power of knowledge sharing. Our platform brings together herbal specialists and enthusiasts from the Yoruba community and beyond to share their extensive knowledge of plant culture, herbal remedies, and much more.' 
            : language === 'es' ? 'En Ewe, creemos en el poder de compartir conocimientos. Nuestra plataforma reúne a especialistas en hierbas y entusiastas de la comunidad Yoruba y más allá para compartir su extenso conocimiento de la cultura de las plantas, remedios herbales y mucho más.'
            : 'Ni Ewé, a ní ìgbàgbó nínú agbára tí ó wà nínú kí a ma kọ́ ara wa. Àkójọpò wa kó àwọn oníṣègùn àti àwọn onímọ̀ nínú àwùjọ Yorùbá àti jù bẹ́ẹ̀ lọ pọ̀ láti lè kọ́ ara wa ní ìmọ̀ ìjìnlẹ̀ lórí ìṣesí ewé, ògùn ṣíṣe àti jù bẹ́ẹ̀ lọ '}

          </Text>
          <Text
            fontSize="lg"
            letterSpacing="2px" // Add letter spacing
            lineHeight="2" // Adjust line height
          >
          {language === 'en' ? 'Whether you are passionate about traditional healing methods or simply curious about the rich herbal traditions of the Yoruba culture, Ewe is the place for you. Contact us today to join our community, learn from experts, and contribute your knowledge to this vibrant ecosystem.' 
            : language === 'es' ? 'Ya sea que estés apasionado por los métodos de curación tradicionales o simplemente curioso sobre las ricas tradiciones herbales de la cultura Yoruba, Ewe es el lugar para ti. ¡Contáctanos hoy para unirte a nuestra comunidad, aprender de expertos y contribuir tu conocimiento a este ecosistema vibrante!'
            : 'Bí ó bá jẹ́ ìmúniláradá lọ̀nà tí ìbílẹ̀ ni ó ya yín lára ni tàbí ẹ kàn fẹ́ mọ nípa ògùn ìbílẹ̀ ti àṣa Yorùbá, Ewé ni ibi tí ó yẹ kẹ wà. Ẹ kàn sí wa lónìí láti dara pọ̀ mọ́ àwùjọ wa, láti kọ́ ẹ̀kọ́ ìjìnlẹ̀ láti ọwọ́ àwọn ọ̀jọ̀gbọ́n àti láti fi ìmọ̀ ti yín kún ìmọ̀ tiwa ni àwùjọ wa.'}
          </Text>


          {/* Volunteering Information */}
          <Heading
            fontSize="lg"
            as="h6"
            size="xl"
            textAlign="justify"
            fontWeight={600}
          >
          {language === 'en' ? 'If you are interested in volunteering, we have various opportunities available in different lanes:' 
          : language === 'es' ? 'Si estás interesado en ser voluntario, tenemos diversas oportunidades disponibles en diferentes áreas:'
          : 'Tí ó bá wù yín láti dara pọ̀mọ́ wa, a ni oríṣiríṣi ipò tí a ti nílò ìrànlọ́wọ́ ní ọ̀nà ọ̀tọ̀tọ̀'}
          </Heading>  ``

          <Text
            fontSize="lg"
            textAlign="justify"
            style={{ whiteSpace: 'pre-line' }}
          >
            {language === 'en' ? volunteeringInformationEn :  language === 'es' ? volunteeringInformationEs : volunteeringInformationYo}
          </Text>

          <Text
            fontSize="lg"
          >
        {language === 'en' ? 'We appreciate your willingness to contribute to the Ewé project. Together, let\'s make this initiative a success!'
          : language === 'es' ? 'Agradecemos tu disposición para contribuir al proyecto Ewé. ¡Juntos, hagamos de esta iniciativa un éxito!'
          : 'A dúpẹ́ gidi lọ́wọ́ yín wípé ẹ ní èrò láti báwa dá sí iṣẹ́ Ewé. Ní àpapọ̀ wa, ẹ jẹ́ kí a ṣe àṣeyọrí lórí àgbékalè yi!'}

          </Text>
          <Box>
          </Box>
        </VStack>
      </Box>
      </motion.div>
    </motion.div>
  );
};

export default Contact;
