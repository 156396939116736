import React, { useRef, useState } from 'react';
import {
  VStack,
  Input,
  Textarea,
  Button,
  Heading,
  Box,
  useColorMode,
  Select,
  Flex,
  Image,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useForm, SubmitHandler, Controller, useFieldArray } from 'react-hook-form';
import ToastNotification from '../../components/ToastNotification';
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from 'react-icons/md';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import axios from 'axios';
import { selectAccessToken, selectCurrentUser } from '../../slice/userSlice';
import { useCreateStoreProductsMutation } from '../../api/stores';
import { useParams, useSearchParams } from 'react-router-dom';
import { AutoBackButton } from '../../components';
import { User } from '../../types/interface';

enum ItemCategory {
  OIL = "Oils",
  SOAP = "Soaps",
  HERBAL_MEDICINE = "Herbal Medicines",
  SPICE = "Spices",
  HERBS = "Herbs",
  SEED = "Seeds",
  SPIRITUAL_ITEMS = "Spiritual Items",
  HERBAL_TEA = "Herbal Teas",
  CANDLES = "Candles",
  BEADS = "Beads"
}

interface Price {
  size: string;
  price_dollar: number;
  price_naira: number;
}

interface ProductVariant {
  prices: Price[];
}

interface FormData {
  priceDollar: number;
  priceNaira: number;
  images: string[];
  quantity: string;
  name: string;
  description: string;
  category: ItemCategory;
  product_variant: ProductVariant[];
}

const MAX_COUNT = 4;

interface CreateStoreProductsProps {
  maxProducts: number;
}

const CreateStoreProducts: React.FC<CreateStoreProductsProps> = () => {
  const [searchParams] = useSearchParams();
  const maxProducts = parseInt(searchParams.get('maxProducts') || '0', 5);
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: "",
    status: "",
  });
  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [previewImages, setPreviewImages] = useState<string[]>([]);
  const [fileLimit, setFileLimit] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState(false);
  const accessToken = useSelector(selectAccessToken);
  const currentUser = useSelector(selectCurrentUser) as User;
  const [fileUrls, setFileUrls] = useState<string[]>([]);

  const {
    handleSubmit,
    control,
    register,
    formState: { isValid, isSubmitting },
    reset,
    setValue,
    getValues
  } = useForm<FormData>({
    defaultValues: {
      priceNaira: 0,
      priceDollar: 0,
      images: [],
      quantity: '',
      name: '',
      description: '',
      category: ItemCategory.OIL,
      product_variant: [{ prices: [{ size: '', price_dollar: 0, price_naira: 0 }] }],
    },
    mode: 'onBlur',
  });

  const { fields: variantFields, append: appendVariant, remove: removeVariant } = useFieldArray({
    control,
    name: "product_variant"
  });

  const { storeId } = useParams<{ storeId: string }>();
  const [createPost, { error }] = useCreateStoreProductsMutation();

  // This is your main file upload handler
  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      const filesArray = Array.from(files);
      const fileUrls: string[] = [];

      setLoading(true);

      for (const file of filesArray) {
        if (file.type.startsWith('image/')) {

          setToastConfig({
            description: 'Uploading image...',
            status: 'info',
          });
          setShowToast(true);

          const formData = new FormData();
          formData.append('files', file);

          try {
            const response = await axios.post(
              `https://ewe-api.herokuapp.com/api/users/${currentUser.id}/upload`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${accessToken}`,
                  'Access-Control-Allow-Origin': '*/*',
                },
              }
            );

            console.log("response upload", response)

            if ('data' in response && response?.data?.data) {
              const fileUrl = response.data.data;
              fileUrls.push(fileUrl);
            }
          } catch (error) {
            console.error('An error occurred while uploading images:', error);
            setToastConfig({
              description: 'An error occurred during the upload.',
              status: 'error',
            });
            setShowToast(true);
          }
        }
      }

     
    if (fileUrls.length > 0) {
      setValue('images', fileUrls); // Store URLs in form data
      setPreviewImages((prevImages) => [...prevImages, ...fileUrls]);
      setFileUrls((prevUrls) => [...prevUrls, ...fileUrls]);
      setToastConfig({
        description: 'Success! Images uploaded successfully.',
        status: 'success',
      });
      setShowToast(true);
    }

      setLoading(false);
    }
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    if (selectedFiles.length > maxProducts) {
      setToastConfig({
        description: `Error: You can only upload up to ${maxProducts} products.`,
        status: 'error',
      });
      setShowToast(true);
      return;
    }

    try {

       // Construct the JSON payload
  const payload = {
    naira_price: data.priceNaira,
    dollar_price: data.priceDollar,
    quantity: data.quantity,
    name: data.name,
    description: data.description,
    category: data.category,
    images: fileUrls,  
    product_variant: data.product_variant.map((variant) => ({
      prices: variant.prices.map((price) => ({
        size: price.size,
        price_dollar: price.price_dollar,
        price_naira: price.price_naira,
      })),
    })),
  };

      const response = await axios.post(
        `https://ewe-api.herokuapp.com/api/stores/${storeId}/products`,
        payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${accessToken}`,
          },
        }
      );

      console.log("Response: ", response);
      setToastConfig({
        description: 'Success! Your product was successfully added.',
        status: 'success',
      });
      setShowToast(true);
      reset();
    } catch (error) {
      setToastConfig({
        description: 'Error: Something went wrong',
        status: 'error',
      });
      setShowToast(true);
    }
  };

  const { colorMode } = useColorMode();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
      style={{
        backgroundColor: colorMode === 'light' ? 'white' : '#345430',
        color: colorMode === 'light' ? '#345430' : 'white',
      }}
    >
      <AutoBackButton />
      <motion.div
        style={{
          backgroundColor: colorMode === 'light' ? 'white' : '#345430',
          paddingBottom: '20px',
        }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.5, ease: 'easeIn' }}
      >
        <Box
          fontFamily="Inter"
          paddingTop={20}
          marginRight={{ base: '15', md: '20' }}
          marginLeft={{ base: '15', md: '20' }}
          pb={20}
        >
          <Heading
            fontFamily="Inter"
            as="h6"
            fontSize={{ base: '20px', md: '30px' }}
            textAlign="center"
          >
            Create Products
          </Heading>
          <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" method="POST">
  <VStack spacing={10} align="center" mt={5} mb={5}>
    <Controller
      control={control}
      name="images"
      render={({ field }) => (
        <Flex
          justify="center"
          align="center"
          flexDirection="column"
          borderWidth="2px"
          borderStyle="dotted"
          borderColor="gray.300"
          backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
          borderRadius={20}
          p={5}
          w="full"
          h="auto"
          {...field}
        >
          <FormLabel htmlFor="file-upload" display="none">Upload Images</FormLabel>
          <Input
            id="file-upload"
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileUpload} 
            accept="image/png, application/pdf, image/svg, image/jpeg, image/gif, image/tiff"
            multiple
          />
          <Button
            fontFamily="Inter"
            colorScheme="teal"
            borderRadius={20}
            p={5}
            onClick={() => fileInputRef.current?.click()}
          >
            <AiOutlineCloudUpload
              size="48px"
              color={colorMode === 'light' ? 'white' : '#345430'}
              style={{ marginRight: '5px' }}
            />
            Upload Images
          </Button>
          {previewImages.length > 0 && (
            <Flex
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              wrap="wrap"
              gap={4}
              mt={4}
            >
              {previewImages.map((image, index) => (
                <Box
                  key={index}
                  width="full"
                  height="full"
                  borderRadius="lg"
                  overflow="hidden"
                  position="relative"
                >
                  <Image
                    src={image}
                    alt={`Preview ${index}`}
                    h="300px"
                    w="300px"
                    objectFit="cover"
                    borderRadius="lg"
                  />
                  <Button
                    size="xs"
                    position="absolute"
                    top="0"
                    right="0"
                    colorScheme="red"
                    onClick={() => {
                      setPreviewImages(previewImages.filter((_, i) => i !== index));
                      setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
                    }}
                  >
                    <MdDelete />
                  </Button>
                </Box>
              ))}
            </Flex>
          )}
          {loading && <p>Loading...</p>}
        </Flex>
      )}
    />

    <FormControl isRequired>
      <FormLabel htmlFor="name">Name of Product</FormLabel>
      <Input
        id="name"
        fontFamily="Inter"
        placeholder="Name of Product"
        borderRadius={20}
        p={5}
        {...register('name', { required: 'This is required' })}
      />
    </FormControl>

    <FormControl isRequired>
      <FormLabel htmlFor="description">Product Description</FormLabel>
      <Textarea
        id="description"
        fontFamily="Inter"
        borderRadius={20}
        p={5}
        h={500}
        placeholder="Product Description"
        {...register('description', { required: 'This is required' })}
      />
    </FormControl>

    <FormControl isRequired>
      <FormLabel htmlFor="priceNaira">Price Naira</FormLabel>
      <Input
        id="priceNaira"
        fontFamily="Inter"
        placeholder="Price Naira"
        borderRadius={20}
        p={5}
        {...register('priceNaira', { required: 'This is required' })}
      />
    </FormControl>

    <FormControl isRequired>
      <FormLabel htmlFor="priceDollar">Price Dollar</FormLabel>
      <Input
        id="priceDollar"
        fontFamily="Inter"
        placeholder="Price Dollar"
        borderRadius={20}
        p={5}
        {...register('priceDollar', { required: 'This is required' })}
      />
    </FormControl>

    <FormControl isRequired>
      <FormLabel htmlFor="quantity">Product Quantity</FormLabel>
      <Input
        id="quantity"
        fontFamily="Inter"
        placeholder="Product Quantity"
        borderRadius={20}
        p={5}
        {...register('quantity', { required: 'This is required' })}
      />
    </FormControl>

    <FormControl isRequired>
      <FormLabel htmlFor="category">Select Product Category</FormLabel>
      <Controller
        control={control}
        name="category"
        render={({ field }) => (
          <Select
            id="category"
            fontFamily="Inter"
            placeholder="Select Product Category"
            {...field}
            value={field.value}
            onChange={(e) => setValue('category', e.target.value as ItemCategory)}
          >
            {Object.entries(ItemCategory).map(([key, value]) => (
              <option key={key} value={value}>
                {value}
              </option>
            ))}
          </Select>
        )}
      />
    </FormControl>

    <Heading as="h3" size="lg" textAlign="center" my={5}>
      Product Variants
    </Heading>

    {variantFields.map((item, index) => (
      <Flex key={item.id} direction="column" align="center" w="full" gap={5} mb={5}>
        <Controller
          control={control}
          name={`product_variant.${index}.prices`}
          render={({ field }) => (
            <>
              {field.value.map((price, priceIndex) => (
                <Flex key={priceIndex} align="center" w="full" gap={5} flexDirection={{ base: 'column', md: 'row' }}>
                  <FormControl>
                    <FormLabel htmlFor={`size-${index}-${priceIndex}`}>Product Size</FormLabel>
                    <Input
                      id={`size-${index}-${priceIndex}`}
                      fontFamily="Inter"
                      placeholder="Product Size"
                      borderRadius={20}
                      p={5}
                      w={{ base: '90%', md: '40%' }}
                      {...register(`product_variant.${index}.prices.${priceIndex}.size`)}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel htmlFor={`priceDollar-${index}-${priceIndex}`}>Price Dollar</FormLabel>
                    <Input
                      id={`priceDollar-${index}-${priceIndex}`}
                      fontFamily="Inter"
                      placeholder="Price Dollar"
                      borderRadius={20}
                      p={5}
                      w={{ base: '90%', md: '40%' }}
                      {...register(`product_variant.${index}.prices.${priceIndex}.price_dollar`)}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel htmlFor={`priceNaira-${index}-${priceIndex}`}>Price Naira</FormLabel>
                    <Input
                      id={`priceNaira-${index}-${priceIndex}`}
                      fontFamily="Inter"
                      placeholder="Price Naira"
                      borderRadius={20}
                      p={5}
                      w={{ base: '90%', md: '40%' }}
                      {...register(`product_variant.${index}.prices.${priceIndex}.price_naira`)}
                    />
                  </FormControl>
                </Flex>
              ))}
            </>
          )}
        />
        <Button
          colorScheme="red"
          w={{ base: '90%', md: '40%' }}
          onClick={() => removeVariant(index)}
        >
          Remove Variant
        </Button>
      </Flex>
    ))}

    <Button
      colorScheme="teal"
      onClick={() => appendVariant({ prices: [{ size: '', price_dollar: 0, price_naira: 0 }] })}
      w={{ base: '90%', md: '40%' }}
      mt={5}
    >
      Add Variant
    </Button>

    <Button
      fontFamily="Inter"
      colorScheme="teal"
      borderRadius={20}
      p={5}
      type="submit"
      isLoading={isSubmitting}
      isDisabled={!isValid || isSubmitting}
    >
      Upload Products
    </Button>
  </VStack>
</form>

          {showToast && <ToastNotification description={toastConfig.description} status={toastConfig.status} />}
        </Box>
      </motion.div>
    </motion.div>
  );
};

export default CreateStoreProducts;
