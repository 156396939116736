import React, { useEffect } from 'react';
import { motion, useMotionValue, useTransform, animate } from 'framer-motion';
import { VStack, HStack, Image, Text, useColorMode, Button } from '@chakra-ui/react';
import { useInView } from 'react-intersection-observer';
import bird from "../../assets/bird.png";
import animal from '../../assets/elephant.png';
import leaf from "../../assets/leaf_icon.png";
import CountUp from 'react-countup';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useNavigate } from 'react-router-dom';
import { selectCurrentUser, selectIsAuthenticated } from '../../slice/userSlice';
import { User } from '../../types/interface';

const CountComponent = () => {
  const language = useSelector((state: RootState) => state.language.language) as string;
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const currentUser = useSelector(selectCurrentUser) as User;  
  const navigate = useNavigate()
  const { colorMode } = useColorMode();
  const count = useMotionValue(0);
  const countAnimals = useMotionValue(0);
  const countBirds = useMotionValue(0);

  const redirectToCreatePost = () => {
    if (isAuthenticated) {
      const createPostUrl = `/user/${currentUser.id}/create-post`;
      navigate(createPostUrl);
    } else {
      navigate('/login'); 
    }
  };

  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const rounded = useTransform(count, (latest) => Math.round(latest));
  const roundedAnimals = useTransform(countAnimals, (latest) => Math.round(latest));
  const roundedBirds = useTransform(countBirds, (latest) => Math.round(latest));

  useEffect(() => {
    const controls = animate(count, 100);
    const controlsAnimals = animate(countAnimals, 50); // Adjust the count for animals
    const controlsBirds = animate(countBirds, 30); // Adjust the count for birds

    return () => {
      controls.stop();
      controlsAnimals.stop();
      controlsBirds.stop();
    };
  }, [count, countAnimals, countBirds]);

  useEffect(() => {
    if (inView) {
      // Trigger the animation when the component comes into view
      count.set(100);
      countAnimals.set(50);
      countBirds.set(30);
    }
  }, [inView, count, countAnimals, countBirds]);

  

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
      style={{
        backgroundColor: colorMode === 'light' ? '#E0EAE0' : '#E0EAE0',
        color: colorMode === 'light' ? '#000' : '#345430',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '20px',
        borderRadius: '20px',
        fontFamily: 'Inter',
        marginLeft: '20px',
        marginRight: '20px',
        flexWrap: 'wrap', // Allow items to wrap to the next row
      }}
    >
      <VStack
        display={'flex'}
        flexDirection={{ base: 'column', md: 'row' }}
        alignItems={'center'}
        justifyContent={'center'}
        textAlign={'center'}
        gap={10}
      >
        <HStack>
          <Text fontSize={20} textAlign={'start'} fontWeight={'bold'}>
            {' '}
            {language === "en" ? "Total No Of Plants: " : language === "yo" ? "Gbogbo Ewé tó wa ní website wa" : "Total de plantas en el sitio web: "}
            {' '}
          </Text>
          <Image
            src={leaf}
            alt="Plant"
            boxSize="40px"
          />
          <CountUp 
          end={rounded.get()}
          className='count' />
        </HStack>
        <HStack>
        <Text fontSize={20} textAlign={'start'} fontWeight={'bold'}>
          {' '}
          {language === "en" ? "Total No Of Animals: " : language === "yo" ? "Gbogbo Ẹranko tó wa ní website wa" : "Total de animales en el sitio web: "}
          {' '}
        </Text>
          <Image
            src={animal}
            alt="Animal"
            boxSize="40px"
          />
          <CountUp 
          end={roundedAnimals.get()}
          className='count'  />
        </HStack>
        <HStack>
        <Text fontSize={20} textAlign={'start'} fontWeight={'bold'}>
          {' '}
          {language === "en" ? "Total No Of Birds: " : language === "yo" ? "Gbogbo Ẹyẹ tó wa ní website wa" : "Total de pájaros en el sitio web: "}
          {' '}
        </Text>

          <Image
            src={bird}
            alt="Bird"
            boxSize="40px"
          />
          <CountUp 
          end={roundedBirds.get()}
          className='count'  />
        </HStack>
      </VStack>
      <VStack 
        display={ "flex"}
        flexDirection={"column"}
        alignItems={'center'}
        justifyContent={'center'}
        mt={4}
        mb={4}
        py={4}>
          <Text fontSize={15} textAlign={'start'} fontWeight={'400'}>
            {' '}
            {language === 'en' ? 'Join other users and add a new discovery' : language === 'yo' ? 'Ẹ darapọ̀ mọ́ àwọn olùmúlò wa, kí ẹ ṣe àwárí tun-tun mìíràn ' : 'Únete a otros usuarios y agrega un nuevo descubrimiento'}
             {' '}
          </Text>
          <Button
        onClick={redirectToCreatePost}
        mt={0}
        bg={colorMode === 'light' ? 'white' : '#345430'} 
        padding={'20px'}
        color={colorMode === 'light' ?'#345430' : 'white' } 
        variant="solid"
        borderRadius={"10px"}
        _hover={{
          color: "#345430",
          backgroundColor: "#F7F7F9"
        }}
        size="md"
      >
            {language === 'en' ? 'Create Post' : language === 'yo' ? 'Fún wa ní ìwádí kan' : 'Crear Publicación'}
      </Button>
        </VStack>
    </motion.div>
  );
};

export default CountComponent;
