import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import store from './store/store'; // Import your Redux store
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'; // Import PersistGate if you're using redux-persist
import { persistStore } from 'redux-persist'; // Import persistStore if you're using redux-persist

// Initialize the persistor if you're using redux-persist
let persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    {/* Wrap your App component with Provider */}
    <Provider store={store}>
      {/* If you're using redux-persist, wrap your App component with PersistGate */}
      <PersistGate loading={null} persistor={persistor}>
        {/* Provide ChakraProvider and CSSReset if you're using Chakra UI */}
        <ChakraProvider>
          <CSSReset />
          <App />
        </ChakraProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
