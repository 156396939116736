import React from "react";
import { Flex, Box, useColorMode, Text, Button, Skeleton } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { RootState, User } from "../../types/interface";
import { selectCurrentUser } from "../../slice/userSlice";
import { useSelector } from "react-redux";
import PostContent from "../Posts/PostContent";
import { useNavigate } from "react-router-dom";

interface UserPostsProps {
  posts: Array<{ _id: string }>;
  handlePostCardClick: (postId: string) => void;
  isLoading: any;
  updatedPostData: any;
}

const UserPosts: React.FC<UserPostsProps> = ({
  posts,
  handlePostCardClick,
  isLoading,
  updatedPostData,
}) => {
  const currentUser = useSelector(selectCurrentUser) as unknown as User;
  const isAuthenticated = useSelector(
    (state: RootState) => state.users.isLogin
  );
  const language = useSelector((state: RootState) => state.language.language);
  const { colorMode } = useColorMode();
  const navigate = useNavigate();

  const redirectToCreatePost = () => {
    if (isAuthenticated) {
      const createPostUrl = `/user/${currentUser.id}/create-post`;
      navigate(createPostUrl);
    } else {
      navigate("/login");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeIn" }}
      style={{
        backgroundColor: colorMode === "light" ? "white" : "#345430",
        color: colorMode === "light" ? "white" : "#345430",
      }}
    >
      <motion.div
        style={{
          backgroundColor: colorMode === "light" ? "white" : "#345430",
          color: colorMode === "light" ? "#000" : "white",
          paddingBottom: "20px",
        }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.5, ease: "easeIn" }}
      >
        <Box
          paddingTop="20px"
          paddingBottom="20px"
          marginRight={{ base: "15", md: "40" }}
          marginLeft={{ base: "15", md: "40" }}
        >
          <Flex
            justifyContent="center"
            alignItems="start"
            flexDirection={"column"}
            paddingBottom="20px"
          >
            {isLoading ? (
              <Box flex="1">
                <Skeleton height="500px" borderRadius={20} color={'transparent'}  />
              </Box>
            ) : posts.length > 0 ? (
              posts.map((post: any) => (
                <PostContent
                  key={post._id}
                  post={post}
                  currentUser={currentUser}
                  isAuthenticated={isAuthenticated}
                  handlePostCardClick={handlePostCardClick}
                  isLoading={isLoading}
                  updatedPostData={updatedPostData}
                />
              ))
            ) : (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={20}
                flexDir={"column"}
              >
                <Text
                  textAlign="center"
                  fontSize="xl"
                  fontWeight="bold"
                  color={colorMode === "light" ? "#345430" : "white"}
                >
                  {isLoading ? "Loading..." : "No posts to display"}
                </Text>
                {!isLoading && (
                  <Button
                    onClick={redirectToCreatePost}
                    mt={0}
                    bg={colorMode === "light" ? "#345430" : "white"}
                    padding={"20px"}
                    color={colorMode === "light" ? "white" : "#000"}
                    variant="solid"
                    borderRadius={"10px"}
                    _hover={{
                      color: "#345430",
                      backgroundColor: "#F7F7F9",
                    }}
                    size="md"
                  >
                    {language === "en" ? "Create Post" : "Fún wa ní ìwádí kan"}
                  </Button>
                )}
              </Box>
            )}
          </Flex>
        </Box>
      </motion.div>
    </motion.div>
  );
};

export default UserPosts;
