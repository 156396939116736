import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { Header } from '../components';
import {
  VStack,
  Heading,
  Text,
  Button,
  Flex,
  Image,
  Wrap,
  WrapItem,
  useMediaQuery,
  useColorMode,
  Box,
} from '@chakra-ui/react';
import { RootState } from '../types/interface';
import { projects } from '../data/project';
import { useEffect } from 'react';

interface HeaderTextProps {
    title: string | { [key: string]: string };
    content: string | { [key: string]: string };
    image: string; 
}


const Project:React.FC<HeaderTextProps>  = () => {
  const language = useSelector((state: RootState) => state.language.language);
  const projectData: HeaderTextProps [] = projects;
  const [isLargerScreen] = useMediaQuery('(min-width: 900px)');
  const { colorMode } = useColorMode();
  const emailAddress = 'contact@ewe.com.ng';
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
      style={{ backgroundColor:  colorMode === 'light' ? 'white' : '#345430'}}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Project Page</title>
      </Helmet>
      <Header />

      <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeIn" }}
      style={{ backgroundColor:  colorMode === 'light' ? 'white' : '#345430'}}
    >
      <Box  
    paddingTop="20px"
    paddingBottom="20px"
    marginRight={{ base: "15", md: "20"}}
    marginLeft={{ base: "15", md: "20"}}
      textAlign="justify"
      >
        <VStack spacing={8} fontFamily="Inter" align="center" mt={20} mb={20}>
                <Text
          fontSize="16px"
          letterSpacing="2px"
          lineHeight="2"
        >
          {language === 'en'
            ? 'Our vision is to make a positive impact on society by launching innovative projects that address pressing challenges and contribute to a better future.'
            : language === 'yo'
              ? 'Iyanu wa ni lati ṣe iṣẹdẹdẹ ti o ṣe ohun ti o dara lati gbe asa orile-ede wa de abala kan pọsẹ, ti a tọ lati pe ogbọn idaniloju ati maṣa ipinle-ọna ti o dara diẹ.'
              : 'Nuestra visión es tener un impacto positivo en la sociedad lanzando proyectos innovadores que aborden los desafíos urgentes y contribuyan a un futuro mejor.'}
        </Text>
        <Text
          fontSize="16px"
          letterSpacing="2px"
          lineHeight="2"
        >
          {language === 'en'
            ? 'These projects play a crucial role in advancing our society and shaping the future. We believe in the power of collective effort, and your support can make a significant difference.'
            : language === 'yo'
              ? 'Awọn iṣẹdẹ yii nikan ti o gba ohun kan ni lati ṣe ilera ipinle-ọna wa ati ṣe ifọwọlọya nla. Awa fi inu itan igbeyawo ya jẹ, ati ọrọ rẹ le gba ipinlẹ-ọna to dara.'
              : 'Estos proyectos juegan un papel crucial en el avance de nuestra sociedad y la conformación del futuro. Creemos en el poder del esfuerzo colectivo, y su apoyo puede hacer una diferencia significativa.'}
        </Text>

          <Heading 
            as="h4" 
            fontFamily="Inter"
            size="md"
            textAlign="justify"
            fontWeight={600}
          >
            {language === 'en'
              ? 'Upcoming Projects'
              : language === 'yo'
                ? 'Awọn Iṣẹ ti O Bọ Lọ'
                : 'Proyectos Próximos'}
          </Heading>

          <Wrap
            spacing={6}
            justify="center"
            alignItems="center"
            flexDirection={['column', 'row']} // Column on small screens, row on large screens
          >
            {projectData.map((project, index) => (
              <WrapItem key={index}>
                <Flex
                flexDirection={isLargerScreen ? 'row' : 'column'}
                  justifyContent="space-between"
                  alignItems="center"
                  w="100%"
                  p={4}
                  borderWidth={1}
                  borderColor="gray.200"
                  borderRadius="lg"
                  textAlign="center"
                  gap={10}
                  style={{ backgroundColor:  colorMode === 'light' ? '#E0EAE0' : '#588552', 
                  color: colorMode === 'light' ? '#345430' :  'white', padding: "20px", borderRadius: "20px"}}
                >
                  <Image
                    src={project.image}
                    alt="Ewe"
                    boxSize="250px"
                    objectFit="cover"
                    borderRadius={"20px"}
                  />
                  <VStack spacing={2} align="start" gap={"15px"} display={'flex'} flexDirection={'column'}>
                    <Heading as="h2" fontWeight={400} size="md">
                    {typeof project.title === "string"
                  ? project.title
                  : project.title[language]}
                    </Heading>
                    <Text
                      fontSize="sm"
                      letterSpacing="2px" lineHeight="2"
                      textAlign="justify"
                    >
                    {typeof project.content === "string"
                  ? project.content
                  : project.content[language]}
                    </Text>    
                    <Button
  as="a"
  href="/donate"
  mt={0}
  bg={colorMode === 'light' ? '#345430' : 'white'}
  color={colorMode === 'light' ? 'white' : '#345430'}
  padding={'20px'}
  variant="solid"
  borderRadius={'10px'}
  _hover={{
    color: '#345430',
    backgroundColor: '#F7F7F9',
  }}
  size="md"
>
  {language === 'en' ? 'Donate' : language === 'yo' ? 'Rànwálọ́wọ́' : 'Donar'}
                    </Button>
                    <Button
                      as="a"
                      href={`mailto:${emailAddress}`}
                      mt={0}
                      bg={colorMode === 'light' ? '#345430' : 'white'}
                      color={colorMode === 'light' ? 'white' : '#345430'}
                      padding={'20px'}
                      variant="solid"
                      borderRadius={'10px'}
                      _hover={{
                        color: '#345430',
                        backgroundColor: '#F7F7F9',
                      }}
                      size="md"
                    >
                      {language === 'en' ? 'Volunteer Now' : language === 'yo' ? 'Darapó' : 'Voluntariado Ahora'}
                    </Button>
                    </VStack>
                </Flex>
              </WrapItem>
            ))}
          </Wrap>
        </VStack>
      </Box>
      </motion.div>
    </motion.div>
  );
};

export default Project;
