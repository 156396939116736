export const about = {
    title: {
      yo: "Nípa wa",
      en: "About Us",
      es: "Acerca de Nosotros"
    },
    description: {
      yo: `Ewé jẹ́ ojú òpó orí ẹ̀rọ̀ ayélujára tí a gbé ka’lẹ̀ láti fi ṣe àkójọpọ̀ ìmọ̀ ìjìnlẹ̀ àwọn Yorùbá. 
          A ó ma kọ́wa ní ìmọ̀ ẹ̀kọ́ lóríi ohun ọ̀gbìn, ìmò ìwádìí ìjìnlẹ̀ lóríi ewé fún ìlera ara àti 
          àwọn ọ̀nà mìíràn lórísirísi tí a lè gbà lòó, fún àwọn akẹ́kọ̀ọ́, 
          àwọn oníwàádìí ìmọ̀ àti ní àwùjọ àwa onísẹ̀ṣe,fún àfikún,ojú òpó Ewé wà fún  `,
      en: `Ewe is online website for gathering the rich ecological knowledge of the Yoruba people. 
          We offer educational resources spanning agriculture, plant-based medical research, and ecology for 
          diverse fields and students, researchers and Isese community. Additionally, 
          Ewe serves as an online ecommerce marketplace for Isese products and all-natural, organic offerings.`,
      es: `Ewe es un sitio web en línea para recopilar el rico conocimiento ecológico del pueblo Yoruba. 
          Ofrecemos recursos educativos que abarcan agricultura, investigación médica basada en plantas y ecología para 
          diversos campos y estudiantes, investigadores y comunidad de Isese. Además, 
          Ewe sirve como un mercado electrónico en línea para productos de Isese y ofertas naturales y orgánicas.`
    }
  };
  

  export const curator = {
    title: {
      yo: "Ẹ darapọ̀ mọ́ àwọn olùmúlò wa, ati awọn ti wọn fun ni ìmọ̀ ati ẹkọ́",
      en: "Join us as contributor and content provider",
      es: "Únete a nosotros como colaborador y proveedor de contenido"
    },
    description: {
      yo: `Ewé fi tọ̀yàyàtọ̀yàyà ké sí ọ láti kó ipa ti olùkópa tí a yà sọ́tọ̀, olùpín ìmọ̀, olùpèsè àkóónú, tàbí orísun ìsọfúnni lágbègbè wa. Ti o ba ni oye ti o jinlẹ nipa aṣa Yorùbá, itara fun imọ imọ-jinlẹ, ti o nifẹ si ṣiṣafihan awọn okuta iyebiye ti o farapamọ, a gba ọ niyanju lati darapọ mọ awọn ipo wa. Gẹgẹbi ọmọ ẹgbẹ oluyọọda ti agbegbe alarinrin wa, iwọ yoo ni aye lati lọ jinna si ijinle ala-ilẹ oni-nọmba wa, ṣiṣayẹwo awọn oye, ṣiṣawaye alaye iyebiye, ati hun ọgbọn rẹ sinu itanna didan ti Ewé.
      Fi imeeli ranṣẹ si wa ni project@ewé.com pẹlu koko-ọrọ naa "Olupaṣe," "Olupinpin Imọ," "Olupese Akoonu," tabi "Orisun Alaye," ki o jẹ ki a mọ nipa itara rẹ lati jẹ apakan ti irin-ajo imole yii. Awọn akitiyan rẹ kii yoo ṣe itọsi sinu aṣọ ti oju opo wẹẹbu wa nikan ṣugbọn yoo tun fun ọ ni iraye si iyasọtọ si awọn iṣẹ inu agbegbe wa, gbigba ọ laaye lati kopa ninu ọpọlọpọ awọn iṣẹ ṣiṣe ati awọn iṣẹlẹ inu ile. Ewé fi ìháragàgà retí àwọn àfikún ṣíṣeyebíye tí ẹ ó ṣe láti tọ́jú òye àpapọ̀ wa nípa ogún ilẹ̀ Yorùbá àti ayé àdánidá.
      `,
      en: `Join Ewé as a dedicated contributor! If you have a deep understanding of Yorùbá culture, a passion for botanical knowledge, and love uncovering hidden gems, we invite you to be part of our vibrant community. Email us at contact@ewe.com.ng with the subject "Contributor," "Knowledge Sharer," "Content Provider," or "Information Source" to express your enthusiasm. Your efforts will shape our digital landscape, offering exclusive access to engaging in-house activities. Ewé looks forward to your valuable contributions in nurturing our understanding of Yorùbá heritage and the natural world.`,
      es: `¡Únete a Ewé como colaborador dedicado! Si tienes un profundo conocimiento de la cultura Yorùbá, una pasión por el conocimiento botánico y te encanta descubrir gemas ocultas, te invitamos a ser parte de nuestra comunidad vibrante. Envíanos un correo electrónico a contact@ewe.com.ng con el asunto "Colaborador", "Compartidor de Conocimiento", "Proveedor de Contenido" o "Fuente de Información" para expresar tu entusiasmo. Tus esfuerzos darán forma a nuestro panorama digital, ofreciendo acceso exclusivo a actividades internas interesantes. Ewé espera con ansias tus valiosas contribuciones para nutrir nuestra comprensión del patrimonio Yorùbá y el mundo natural.`
    }
  };
  
  export const craft = {
    title: {
      yo: `Darapọ̀ Mọ́ Wa Gẹ́gẹ́ Bíi Onímọ̀ Ìjìnlẹ̀ Nipa Ewé Nile Yorùbá`,
      en: `Join us in crafting this database!`,
      es: `¡Únete a nosotros en la creación de esta base de datos!`
    },
    description: {
      yo: `Ni Ewé, awọn titẹ sii iwe-itumọ wa ni agbara, ti n pe awọn olumulo mejeeji ati awọn onimọ-ede inu ile lati ṣe alabapin alaye ati ṣatunṣe awọn akiyesi eyikeyi. Ipilẹṣẹ onipọ eniyan n dagba lori imudara ilọsiwaju, ni idaniloju aṣoju deede ti itumọ orukọ kọọkan fun awọn olumulo lọwọlọwọ ati ọjọ iwaju. Lapapọ, a n ṣe agbekalẹ iwe-itumọ orukọ Yorùbá lori ayelujara, ti o jẹ ki ohun iṣura aṣa yii wa fun ẹnikẹni ti o ni asopọ intanẹẹti.`,
      en: `
      Be part of building this database! At Ewé, our dictionary entries are dynamic, welcoming contributions and corrections from users and our in-house linguists. This collaborative effort constantly improves, guaranteeing accurate meanings for all users. Together, we're creating a comprehensive online Yorùbá ecological database, making this cultural treasure accessible to everyone with an internet connection. Join us in shaping this authoritative resource!`,
      es: `¡Sé parte de la construcción de esta base de datos! En Ewé, nuestras entradas de diccionario son dinámicas, y damos la bienvenida a las contribuciones y correcciones de los usuarios y nuestros lingüistas internos. Este esfuerzo colaborativo mejora constantemente, garantizando significados precisos para todos los usuarios. Juntos, estamos creando una base de datos ecológica yoruba en línea completa, haciendo que este tesoro cultural esté accesible para todos con una conexión a internet. ¡Únete a nosotros en la formación de este recurso autorizado!`
    }
  };
  

  export const faq = [
    {
        id: '1',
        title: {
            yo: "Báwọ ni a ṣe ń gba àwọn orúkọ wọ̀nyí??",
            en: "How’s the data curated?",
            es: "¿Cómo se cura la información?"
        },
        description: {
            yo: `
            Àkójọpò ìmọ̀ yí kó oríṣiríṣi ìmọ̀ nípa ewé láti orísun ọ̀tọ̀tọ̀, lára wọn ni: ìwádìí, ọrọ ẹnu, ìwé, àwọn òun ti a ti fi hàn síta àti èyí tí a ó tíì fi hàn, ti orí rédíò amóùn-máwòrán, ayélujára àti ìmọ̀ ìbílẹ̀ ti Ìṣẹ̀ṣe, eléyìí ti ìmọ̀ ifá àti ti àwọn òrìṣà náà wá ńbẹ. Àwọn tí wọ́n yọ̀nda láti ràn wá lọ́wọ́ láti jẹ́ kí iṣẹ́ ìwádìí na kúná d'óko ṣe bẹ nípa pé wọ́n lọ kiri wọn sì bá àwọn tí wọ́n ni ìfẹ́ si ewé tí wọ́n sì ní ìmọ̀ nípa ewé. Ìpinu wá lori awon ewé náà ko dá lórí pé ká kan mọ ìtumò wọn lọ, a pinu láti mọ ìtàn tí ó rọ̀ mọ́ àwọn ewé náà.
            `,
            en: `
            This database compiles plant information from diverse sources: research, word of mouth, reference books, published and unpublished materials, radio, television, the internet, and Isese traditional knowledge, including Ifa and Orisa spirituality. Volunteer contributors enhance our research through field trips, collaborating with scholars and enthusiasts. Our goal extends beyond meanings, aiming to capture elements of local history associated with the plants.
            `,
            es: `
            Esta base de datos compila información sobre plantas de diversas fuentes: investigación, palabra de boca en boca, libros de referencia, materiales publicados e inéditos, radio, televisión, internet y conocimiento tradicional de Isese, incluyendo la espiritualidad de Ifa y Orisa. Los colaboradores voluntarios enriquecen nuestra investigación a través de viajes de campo, colaborando con académicos y entusiastas. Nuestro objetivo va más allá de los significados, buscando capturar elementos de la historia local asociados con las plantas.
            `
        }   
    },
    {
        id: '2',
        title: {
            yo: "Ṣé kì a fí ami ṣí tabi kí a ma fí ami ṣí?",
            en: "To Tone-Mark or Not to Tone-Mark?",
            es: "¿Poner o no poner acentos?"
        },
        description: {
            yo: `
            Ẹ ló apo ìwàdí wá lati lé té̩ ìwàdí yin ṣí, ẹ lé ló ami tabi kìí ẹ maá ló ami, ẹ tẹ́ òrúkó ọ̀rọ̀ tí ẹ́ wá ṣí. Tí ẹ́ bá ṣí té, ó maá gbé èyí tó ṣún mó̩ wá. Awọ́n ọ̀rọ̀ wá wa letó lọ́wọ́ awọn òlúkọ́ wa, ẹ wa nkan tí ẹ ba wa ni apo ìwàdí wá.
            `,
            en: `
            Use our search function worry-free! You can use both tonal markings and no tonal markings—just type the name and press "enter." Our dictionary adapts to variations in tone marks or spelling. If you misspell, it offers close matches. Rest assured, the accurate tone marks are verified by scholars and linguists. Find what you need confidently!
            `,
            es: `
            ¡Usa nuestra función de búsqueda sin preocupaciones! Puedes usar tanto los acentos tonales como no usarlos, solo escribe el nombre y presiona "enter". Nuestro diccionario se adapta a las variaciones en los acentos o la ortografía. Si cometes un error tipográfico, ofrece coincidencias cercanas. Ten la seguridad de que los acentos tonales precisos son verificados por académicos y lingüistas. ¡Encuentra lo que necesitas con confianza!
            `
        }   
    },
    {
        id: '3',
        title: {
            yo: "Bawoni lé ṣé lè ranwàló̩wọ́?",
            en: "I would like to support you in some way… but how?",
            es: "Me gustaría apoyarte de alguna manera... ¿pero cómo?"
        },
        description: {
            yo: `
            Adúpẹ́ lọwọ yín fún ifẹ́ yín lati ranwàló̩wọ́ fún iṣé Ewé! Ẹ lé darapọ́ mọ́ awújó̩ wa lati lè ranwàló̩wọ́ pèlú Owó tabi iṣé tí ẹ ba lè ṣé fún wa bí kìí é ranwàló̩wọ́ lọrí ìkọ́ ewé mobile application tí ẹ ba jé developer lọrí ẹ̀rọ̀ ayélujára, tabi òní ìwàdí ìmọ̀, tabi akèékọ́, oní ṣé tó Social media PR, a nilo ìrànlọ́wọ́ yín gidigan. Lati lé ṣé ètò ìrànlọ́wọ́ yí ló̩ ṣí ilé ranwàló̩wọ́ lọrí website wá www.ewe.com.ng/donate tabi contact@ewe.com.ng. Ìrànlọ́wọ́ yín ṣé koko 🙏🏾
            `,
            en: `
            Thank you for your interest in the Ewe project! Join our community by contributing financially or volunteering your skills. Whether you're a developer, researcher, student, or PR professional, we welcome your support. To volunteer, visit our contact page. For donations, use our Donate page. If you're in Nigeria and prefer to donate in naira or provide institutional support, contact us at contact@ewe.com.ng. Your support is vital for the project's progress.
            `,
            es: `
            ¡Gracias por tu interés en el proyecto Ewe! Únete a nuestra comunidad contribuyendo financieramente o ofreciendo tus habilidades como voluntario. Ya seas desarrollador, investigador, estudiante o profesional de relaciones públicas, agradecemos tu apoyo. Para ser voluntario, visita nuestra página de contacto. Para donaciones, utiliza nuestra página de Donaciones. Si estás en Nigeria y prefieres donar en naira o proporcionar apoyo institucional, contáctanos en contact@ewe.com.ng. Tu apoyo es vital para el progreso del proyecto.
            `
        }   
    }
];
