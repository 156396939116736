import React, { useEffect, useState } from "react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  useColorMode,
} from "@chakra-ui/react";
import { BiTrash, BiBookmark } from "react-icons/bi";
import { CiMenuKebab } from "react-icons/ci";
import { useSelector } from "react-redux";
import { User } from "../../types/interface";
import { selectCurrentUser } from "../../slice/userSlice";
import { useDeleteQuestionMutation, useEditQuestionMutation, useBookmarkAQuestionMutation, useDeleteBookmarkQuestionMutation } from "../../api/questions";
import ToastNotification from "../ToastNotification";

interface QuestionMenuProps {
  questionId: string;
  userId: string; // Owner of the question
}

const QuestionMenu: React.FC<QuestionMenuProps> = ({ questionId, userId }) => {
  const { colorMode } = useColorMode();
  const currentUser = useSelector(selectCurrentUser) as User;
  const [deleteQuestion] = useDeleteQuestionMutation();
  const [editQuestion] = useEditQuestionMutation(); // Add useEditQuestionMutation hook
  const [bookmarkAQuestion] = useBookmarkAQuestionMutation();
  const [deleteBookmarkQuestion] = useDeleteBookmarkQuestionMutation();
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: "",
    status: "",
  });

  useEffect(() => {
    // Check if the question is bookmarked
    const savedBookmarks = localStorage.getItem("bookmarkedQuestions");
    if (savedBookmarks) {
      const parsedBookmarks = JSON.parse(savedBookmarks);
      setIsBookmarked(parsedBookmarks[questionId] || false);
    }
  }, [questionId]);

  const handleDelete = async () => {
    try {
      await deleteQuestion({ userId: currentUser.id, questionId }).unwrap();
      setShowToast(true);
      setToastConfig({ description: "Question deleted successfully", status: "success" });

      window.location.reload();
    } catch (err) {
      setShowToast(true);
      setToastConfig({ description: "Error deleting question", status: "error" });
    }
  };

  const handleEdit = async () => {
    try {
      await editQuestion({ userId: currentUser.id, questionId }).unwrap(); // Call the mutation
      setShowToast(true);
      setToastConfig({ description: "Question edited successfully", status: "success" });
    } catch (err) {
      setShowToast(true);
      setToastConfig({ description: "Error editing question", status: "error" });
    }
  };

  const handleBookmark = async () => {
    try {
      await bookmarkAQuestion({ userId: currentUser.id, questionId }).unwrap();
      const savedBookmarks = localStorage.getItem("bookmarkedQuestions");
      const updatedBookmarks = savedBookmarks ? JSON.parse(savedBookmarks) : {};
      updatedBookmarks[questionId] = true;
      localStorage.setItem("bookmarkedQuestions", JSON.stringify(updatedBookmarks));
      setIsBookmarked(true);
      setToastConfig({
        description: "Question bookmarked",
        status: "info",
      });
      setShowToast(true);
    } catch (error) {
      setToastConfig({
        description: "Failed to bookmark question",
        status: "error",
      });
      setShowToast(true);
    }
  };

  const handleRemoveBookmark = async () => {
    try {
      await deleteBookmarkQuestion({ userId: currentUser.id, questionId }).unwrap();
      const savedBookmarks = localStorage.getItem("bookmarkedQuestions");
      const updatedBookmarks = savedBookmarks ? JSON.parse(savedBookmarks) : {};
      delete updatedBookmarks[questionId];
      localStorage.setItem("bookmarkedQuestions", JSON.stringify(updatedBookmarks));
      setIsBookmarked(false);
      setToastConfig({
        description: "Bookmark removed",
        status: "info",
      });
      setShowToast(true);
    } catch (error) {
      setToastConfig({
        description: "Failed to remove bookmark",
        status: "error",
      });
      setShowToast(true);
    }
  };

  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<CiMenuKebab fontSize={20} color={colorMode === "light" ? "#345430" : "white"} />}
        variant="ghost"
      />
      <MenuList bg={colorMode === "light" ? "#345430" : "white"}>
        {/* Conditionally render edit and delete for the owner of the question */}
        {currentUser.id === userId && (
          <>
            {/* <MenuItem
              bg={colorMode === "light" ? "#345430" : "white"}
              color={colorMode === "light" ? "#fff" : "#345430"}
              onClick={handleEdit}
            >
              Edit Question
            </MenuItem> */}
            <MenuItem
              bg={colorMode === "light" ? "#345430" : "white"}
              color={colorMode === "light" ? "#fff" : "#345430"}
              onClick={handleDelete}
            >
              <BiTrash fontSize={20} color="#ff0000" /> Delete Question
            </MenuItem>
          </>
        )}
        {!isBookmarked && (
          <MenuItem
            bg={colorMode === "light" ? "#345430" : "white"}
            color={colorMode === "light" ? "#fff" : "#345430"}
            onClick={handleBookmark}
          >
            <BiBookmark fontSize={20} color={colorMode === "light" ? "#fff" : "#345430"} /> Bookmark
          </MenuItem>
        )}
        {isBookmarked && (
          <MenuItem
            bg={colorMode === "light" ? "#345430" : "white"}
            color={colorMode === "light" ? "#fff" : "#345430"}
            onClick={handleRemoveBookmark}
          >
            <BiTrash fontSize={20} color="#ff0000" /> Remove Bookmark
          </MenuItem>
        )}
      </MenuList>
      {showToast && (
        <ToastNotification
          description={toastConfig.description}
          status={toastConfig.status}
          onClose={() => setShowToast(false)}
        />
      )}
    </Menu>
  );
};

export default QuestionMenu;
