import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Box, Button, Flex, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../slice/userSlice';
import { RootState, User } from '../types/interface';

const variants = {
  initial: (direction: number) => ({
    x: direction > 0 ? '100vw' : '-100vw',
    opacity: 0,
  }),
  animate: {
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => ({
    x: direction < 0 ? '100vw' : '-100vw',
    opacity: 0,
  }),
};

const PostAndQuestionSlider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const [direction, setDirection] = React.useState<number>(0);
  const [prevLocation, setPrevLocation] = React.useState(location);
  const { colorMode } = useColorMode();
  const currentUser = useSelector(selectCurrentUser) as User;
  const language = useSelector((state: RootState) => state.language.language);

  React.useEffect(() => {
    if (prevLocation.pathname !== location.pathname) {
      setDirection(location.pathname > prevLocation.pathname ? 1 : -1);
      setPrevLocation(location);
    }
  }, [location, prevLocation]);

  return (
    <>
      <Box as="nav" bg={colorMode === 'light' ? '#E0EAE0' : '#588552'} p={4} boxShadow="md">
        <Flex justify="center" align="center" direction={['column', 'row']} wrap="wrap">
          <Button
            as={Link}
            to={`/user/${currentUser.id}/posts`}
            bg={colorMode === 'light' ? '#38A169' : '#2F855A'} // Green for light mode, darker green for dark mode
            color="white"
            _hover={{
              bg: colorMode === 'light' ? '#2F855A' : '#276749',
            }}
            size="lg"
            m={2}
          >
            {language === 'en' ? 'Posts' : language === 'yo' ? 'Awon ìwádí wa' : 'Publicación'}
          </Button>
          <Button
            as={Link}
            to={`/user/${currentUser.id}/questions`}
            bg={colorMode === 'light' ? 'white' : '#1A202C'}
            color={colorMode === 'light' ? '#38A169' : 'white'} // Contrast with green text or white text based on mode
            border={`2px solid ${colorMode === 'light' ? '#38A169' : 'white'}`}
            _hover={{
              bg: colorMode === 'light' ? '#F7FAFC' : '#2D3748',
            }}
            size="lg"
            m={2}
          >
            {language === 'en' ? 'Questions' : language === 'yo' ? 'Ìbéèrè' : 'Pregunta'}
          </Button>
        </Flex>
      </Box>

      <AnimatePresence custom={direction}>
        <motion.div
          key={location.pathname}
          custom={direction}
          variants={variants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{
            type: 'tween',
            duration: 0.3, // Reduced duration for faster animation
            ease: 'easeInOut', // Optional: adjust easing for smoother transitions
          }}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default PostAndQuestionSlider;
