import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Question } from "../types/interface";

// Define the entity adapter for Questions, specifying `_id` as the ID field
export const questionsAdapter = createEntityAdapter<Question>({
  sortComparer: (a, b) => a?.question.localeCompare(b?.question),
});

// Define the type for the state managed by the slice
interface QuestionsState extends ReturnType<typeof questionsAdapter.getInitialState> {}

// Define the initial state using the entity adapter's initial state
const initialState: QuestionsState = questionsAdapter.getInitialState();

// Create the questions slice using createSlice
export const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    // Add a single question to the state
    addQuestion: (state, action: PayloadAction<Question>) => {
      questionsAdapter.addOne(state, action.payload);
    },
    // Update a question in the state
    updateQuestion: (state, action: PayloadAction<Question>) => {
      questionsAdapter.updateOne(state, { id: action.payload._id, changes: action.payload });
    },
    // Remove a question from the state by ID
    removeQuestion: (state, action: PayloadAction<string>) => {
      questionsAdapter.removeOne(state, action.payload);
    },
  },
});

// Export the action creators generated by createSlice
export const { addQuestion, updateQuestion, removeQuestion } = questionsSlice.actions;

// Export the reducer function generated by createSlice
export default questionsSlice.reducer;
