import logo from '../assets/Ewe.png';
import ope from '../assets/Ope.jpg';
import research from '../assets/research.jpg';

export const projects = [
  {
    title: {
      yo: 'Ewé Fún Mobile Application',
      en: 'Ewe Mobile Application',
      es: 'Aplicación Móvil Ewé',
    },
    content: {
      yo: `Iṣé yí dale lorí ìgbèkale mobile app ni ẹrọ ayelújara lati le gbé Àṣà ati ìṣẹ̀ṣẹ̀ wá gá pelu ìdàgbà ṣó ké ìmọ̀ ìjìnlé wa. A pe yin lati lé darapọ́ mọ́ wa lati lé jé kìí aṣéyọ̀rí ba ìgbìn yánjú wa lọrí idáṣí lé ẹrọ mobile app fún ìdàgbà ṣó ké ìmọ̀ ìjìnlé wa.`,
      en: `"Ewé Fún Mobile Application" is a project aimed at creating a mobile app to preserve Yoruba culture, traditions, and indigenous knowledge. We invite donations to support this initiative, emphasizing the app's value in connecting people with their roots and preserving Yoruba heritage. Join us in making a positive impact on our community through this innovative project.`,
      es: `La "Aplicación Móvil Ewé" es un proyecto destinado a crear una aplicación móvil para preservar la cultura, las tradiciones y el conocimiento indígena yoruba. Invitamos a realizar donaciones para apoyar esta iniciativa, destacando el valor de la aplicación para conectar a las personas con sus raíces y preservar el patrimonio yoruba. Únase a nosotros para tener un impacto positivo en nuestra comunidad a través de este proyecto innovador.`,
    },
    image: logo,
  },
  {
    title: {
      yo: 'Ògbìn àwọn ewé ìbílè ni àwùjo Yorùbá',
      en: 'Cultivation and Agricultural Development of Indigenous Medicinal Plants of Yoruba Community',
      es: 'Cultivo y Desarrollo Agrícola de Plantas Medicinales Indígenas de la Comunidad Yoruba',
    },
    content: {
      yo: `
      Ṣíṣe ògbìn àwọn ewé ìbílè ni àwùjo Yorùbá n'isẹ pèlú ìpinu láti rí wípé àwọn akitiyan ògbìn àwọn ewé yìí fi ìdí múlè. Èyí nílò àfiokànsí púpò. 
      
      Àwọn ewé ìbílè yìí ni ipá tó pò tí wọn n kó nínu ṣiṣe itọju ara eni tó péye pèlú síse t'ewé t'egbò ìbílè. Ìlàkàkà láti se ògbìn àwọn ewé yíò jé kí o se é se lati jé kí itẹsiwaju de bá gbogbo gbò àti pẹlú yíò jé kí ìlọsíwájú ba t'ewé t'egbò ni orílè èdè àti àwùjọ Yorùbá.`,
      en: `
      Cultivation and agricultural development of indigenous medicinal plants in the Yoruba community involve the intentional and systematic growth of various herbs and plants with medicinal properties traditionally used by the Yoruba people. These plants, in Yoruba, play a significant role in traditional medicine, addressing various health issues. Cultivation efforts aim to promote sustainable practices and ensure the availability of these valuable medicinal resources within the community.
      `,
      es: `
      El cultivo y desarrollo agrícola de plantas medicinales indígenas en la comunidad yoruba implican el crecimiento intencional y sistemático de varias hierbas y plantas con propiedades medicinales tradicionalmente utilizadas por los yorubas. Estas plantas juegan un papel significativo en la medicina tradicional yoruba, abordando varios problemas de salud. Los esfuerzos de cultivo buscan promover prácticas sostenibles y asegurar la disponibilidad de estos valiosos recursos medicinales dentro de la comunidad.
      `,
    },
    image: ope,
  },
  {
    title: {
      yo: 'Ìwádìí ìjìnlè sí inú lílò àwon ewé àti eranko ìbílè ni"lé Yorùbá ',
      en: 'Research and Usage of Indigenous Knowledge of Plants and Animals of the Yoruba Community',
      es: 'Investigación y Uso del Conocimiento Indígena de Plantas y Animales de la Comunidad Yoruba',
    },
    content: {
      yo: `Ìwádìí ìjìnlè sí inú lílò àwon ewé àti eranko ìbílè o n'isẹ pèlú síse Ìwádìí sinu àwon òye àti ogbón nípa lílo àwọn ewé àti eranko tí àwọn baba n'la wa se ìlànà rè sílè fún wa. Èròngbà wa ní láti tan ìmólè ṣí àwọn ìmò yìí paapajulo àwọn ìmò tó wá nínú Ìsèse Yorùbá. Ìgbìyànjú wa ni lati tan imole si àwọn ìmò àtayébáyé pelu àwon ìmò òde òní lati lẹ se igbe laruge àwọn isé ti o le mu ìdàgbàsókè tó ní ìtumò àti ìfòkànbalè bá wa ni orílè èdè wá àti ni àwùjo ọmọ Yorùbá.`,
      en: `Research and Usage of Indigenous Knowledge of Plants and Animals in the Yoruba Community involves studying and applying the traditional wisdom and understanding passed down through generations regarding the local flora and fauna. This research seeks to explore the valuable knowledge embedded in Yoruba culture about the uses, properties, and significance of plants and animals. By bridging traditional wisdom with modern research, it aims to contribute to sustainable practices, conservation, and the preservation of Yoruba heritage.`,
      es: `La Investigación y Uso del Conocimiento Indígena de Plantas y Animales en la Comunidad Yoruba implica estudiar y aplicar la sabiduría y comprensión tradicional transmitida a través de generaciones respecto a la flora y fauna local. Esta investigación busca explorar el valioso conocimiento incrustado en la cultura yoruba sobre los usos, propiedades y significancia de las plantas y animales. Al unir la sabiduría tradicional con la investigación moderna, se pretende contribuir a prácticas sostenibles, conservación y la preservación del patrimonio yoruba.`,
    },
    image: research,
  },
  // Add more projects as needed
];
