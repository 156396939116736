// api.tsx
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store/store';
import { selectAccessToken } from '../slice/userSlice';

const BASE_URL = 'https://ewe-api.herokuapp.com/api/';

const ApiCall = () => {
  const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
      baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState | undefined;
    //   const accessToken = state ? selectAccessToken(state) : '';
    //   if (accessToken) {
    //     headers.set('Authorization', `Bearer ${accessToken}`);
    //   }
    //   headers.set('Content-Type', 'application/json');
    //   headers.set('Access-Control-Allow-Origin', '*');
    },
    }),
    tagTypes: ['Posts', 'Orders', 'Post'],
    endpoints: (builder) => ({
      createPosts: builder.mutation({
        query: (body) => ({
          url: '/static/user-post',
          method: 'POST',
          body,
          providesTags: ['Posts'],
          defer: true,
        }),
      }),
      createOrder: builder.mutation({
        query: (body) => ({
          url: 'store/orders',
          method: 'POST',
          body,
          providesTags: ['Orders'],
          defer: true,
        }),
      }),
      getOrder: builder.query({
        query: () => ({
          url: 'store/orders',
          providesTags: ['Orders'],
          defer: true,
        }),
      }),
      getItems: builder.query({
        query: () => ({
          url: 'store/items',
          providesTags: ['Orders'],
          defer: true,
        }),
      }),
      getPostSearchQuery: builder.query({
        query: ({ limit, page, search }) =>
          `static/posts?limit=${limit}&page=${page}&search=${search}`,
      }),
      getStaticPosts: builder.query({
        query: () => ({
          url: 'static/posts',
          providesTags: ['Posts'],
        }),
      }),
      getStaticPostById: builder.query({
        query: (id) => ({
          url: `static/posts/${id}`,
          providesTags: ['Post'],
        }),
      })
    }),
  });

  // Export the created hooks/selectors from the apiSlice
  const {
    useCreatePostsMutation,
    useCreateOrderMutation,
    useGetOrderQuery,
    useGetItemsQuery,
    useGetPostSearchQueryQuery,
    useGetStaticPostsQuery,
    useGetStaticPostByIdQuery
  } = apiSlice;

  return {
    apiSlice,
    useCreatePostsMutation,
    useCreateOrderMutation,
    useGetOrderQuery,
    useGetItemsQuery,
    useGetPostSearchQueryQuery,
    useGetStaticPostsQuery,
    useGetStaticPostByIdQuery
  };
};

export default ApiCall;
