import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { Box, Heading } from '@chakra-ui/react';
import { ArticleForm, AutoBackButton } from '../components';

const CreateArticle = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, ease: 'easeIn' }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Create Publication</title>
        </Helmet>
  
        <Box
          paddingTop="20px"
          paddingBottom="20px"
          marginRight={{ base: '15', md: '20' }}
          marginLeft={{ base: '15', md: '20' }}
          fontFamily="Inter"
          height={'100%'}
        >
                      <AutoBackButton/>
          <Heading 
              fontFamily="Inter"
              as="h6"
              fontSize={{ base: '20px', md: "30px"}}
              textAlign="center">
            Create Publication
          </Heading>
          <ArticleForm />
        </Box>
      </motion.div>
    );
  };
  
  export default CreateArticle;
  