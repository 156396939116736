import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../types/interface";
import { selectAccessToken } from "../slice/userSlice";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const fileUploadApi = createApi({
  reducerPath: "fileUploadApi",
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState | undefined;
      const accessToken = state ? selectAccessToken(state) : '';
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
        headers.set('Content-Type', 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW');
        headers.set('Content-Type', 'application/json');
        headers.set('Access-Control-Allow-Origin', '*');
      }
    },
  }),
  endpoints: (builder) => ({
    uploadFile: builder.mutation<UploadResponse, FileUploadArgs>({ // Change to FormData
      query: ({ userId, file }) => {
        const formData = new FormData();
        formData.append('file', file as unknown as File); // Assuming 'file' is the actual File object

        return {
          url: `users/${userId}/upload`,
          method: 'POST',
          body: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        };
      },
    }),
  }),
});

export const { useUploadFileMutation } = fileUploadApi;
interface FileUploadArgs {
  userId: any;
  file: FormData;
}

interface UploadResponse {
  data: any;
  imageUrl: string;
  // other response properties if needed
}
