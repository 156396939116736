import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { ArticleList } from '../../../components';
import { Articles } from '../../../types/interface';

interface BookmarkedArticlesProps {
  bookmarkArticles: Articles[];
  handleArticleClick: (articleId: string) => void;
  isAuthenticated: boolean;
  updatedArticleData: (newData: Partial<any>) => void;
}

const BookmarkedArticles: React.FC<BookmarkedArticlesProps> = ({ 
  bookmarkArticles, 
  handleArticleClick, 
  isAuthenticated, 
  updatedArticleData 
}) => {
  return (
    <Box>
      {bookmarkArticles?.length === 0 ? (
        <Text textAlign="center" fontSize="18px">
             No bookmarks found.
        </Text>
      ) : (
        <ArticleList
        articles={bookmarkArticles}
        isAuthenticated={isAuthenticated}
        handleArticleClick={handleArticleClick}
        updateArticleData={updatedArticleData} 
          />
      )}
    </Box>
  );
};

export default BookmarkedArticles;
