import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import {
  Box,
  Image,
  Text,
  Button,
  Heading,
  Grid,
  Center,
  useColorMode,
  Skeleton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Input, // Import Input component for search
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { RootState } from '../types/interface';
import { AutoBackButton, Cart, ShopShareButton } from '../components';
import { Product } from '../data/productData';
import { useGetItemsQuery } from '../api/postApi';
import { FaChevronDown } from 'react-icons/fa';
import { BsFillShareFill } from 'react-icons/bs';
// import { ShopShareButton } from '../components/ShopShareButton';

enum ItemCategory {
  OIL = 'Oils',
  SOAP = 'Soaps',
  HERBAL_MEDICINE = 'Herbal Medicines',
  SPICE = 'Spices',
  HERBS = 'Herbs',
  SEED = 'Seeds',
  SPIRITUAL_ITEMS = 'Spiritual Items',
  HERBAL_TEA = 'Herbal Teas',
  CANDLES = 'Candles',
  BEADS = 'Beads',
}

const PRODUCTS_PER_PAGE = 20; // Define how many products per page

const Shop: React.FC = () => {
  const [cart, setCart] = useState<Product[]>([]);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState<'$' | '₦'>('₦');
  const [selectedCategory, setSelectedCategory] = useState<string>('All');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>(''); // State for search term
  const { colorMode } = useColorMode();
  const { data, isLoading } = useGetItemsQuery({});
  const products: Product[] = data?.data || [];
  const language = useSelector((state: RootState) => state.language.language);
  const [shareModalVisibility, setShareModalVisibility] = useState<{ [key: number]: boolean }>({});


  const toggleShareModal = (productId: number) => {
    setShareModalVisibility((prev) => ({
      ...prev,
      [productId]: !prev[productId],
    }));
  };
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addToCart = (product: Product) => {
    const existingProductIndex = cart.findIndex((p) => p._id === product._id);
    if (existingProductIndex !== -1) {
      const updatedCart = [...cart];
      updatedCart[existingProductIndex].quantity += 1;
      setCart(updatedCart);
    } else {
      setCart([...cart, { ...product, quantity: 1 }]);
    }
  };

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const toggleCurrency = () => {
    setCurrentCurrency((prevCurrency) => (prevCurrency === '$' ? '₦' : '$'));
  };

  const getPriceInCurrency = (product: Product) => {
    const { prices } = product;
    if (prices) {
      const selectedCurrency = currentCurrency;
      if (selectedCurrency === '₦') {
        return `${prices.naira} ${selectedCurrency}`;
      } else if (selectedCurrency === '$') {
        return `${prices.dollar} ${selectedCurrency}`;
      }
    }
    return 'Prices not available';
  };

  const handleCategorySelect = (category: string) => {
    setSelectedCategory(category);
    setCurrentPage(1); // Reset to first page when category changes
  };

  const filteredProducts = selectedCategory === 'All' 
    ? products 
    : products.filter((product) => product.category === selectedCategory);

  // Filter by search term (case-insensitive)
  const searchedProducts = filteredProducts.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate total pages
  const totalPages = Math.ceil(searchedProducts.length / PRODUCTS_PER_PAGE);

  // Get the current products for the current page
  const currentProducts = searchedProducts.slice(
    (currentPage - 1) * PRODUCTS_PER_PAGE,
    currentPage * PRODUCTS_PER_PAGE
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
      style={{ backgroundColor: colorMode === 'light' ? 'white' : '#345430' }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ewe Shop - Authentic Isese Products</title>
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, ease: 'easeIn' }}
        style={{ backgroundColor: colorMode === 'light' ? 'white' : '#345430' }}
      >
        <Box pt={[10, 20]} mx={{ base: 4, md: 20 }} pb={20}>
          <AutoBackButton />
          <Box mb={6}>
            <Heading fontFamily="Inter" as="h4" size="xxl" textAlign="justify" fontWeight={600}>
              {language === 'en' ? 'Welcome to Ewe Shop!' : language === 'yo' ? 'Káàbọ̀ sí Ewé Shop!' : '¡Bienvenido a la Tienda Ewe!'}
            </Heading>
            <Text letterSpacing="2px" lineHeight="2" fontSize="16px" fontFamily="Inter" mb={4}>
              {language === 'en'
                ? 'Discover the richness of Ewe culture through our authentic Isese products, traditional oils, black soap, and more. Explore our collection below and embrace the healing power of nature.'
                : language === 'yo'
                  ? 'Joko lọ si iṣẹsẹ kanalẹ Ewe lati ṣẹdarẹ Isese wa, awọn ọlụṣọ osan ati ọyọ ọlọgbo, ọṣụpọ dudu, ati ọpọlọpọ miiran. Kọ awọn ibi wa to joko lọ si wọ̣n ki o gba iwẹlú agbara ìlù ti ọna miiran.'
                  : 'Descubre la riqueza de la cultura Ewe a través de nuestros productos auténticos de Isese, aceites tradicionales, jabón negro y más. Explora nuestra colección a continuación y abraza el poder curativo de la naturaleza.'}
            </Text>
          </Box>

          <Box display="flex" justifyContent="space-between" alignItems="center" mb={6}>
            <Button ml={4} onClick={toggleCurrency}>
              {`Switch to ${currentCurrency === '$' ? '₦' : '$'}`}
            </Button>

            <Menu>
              <MenuButton as={Button} rightIcon={<FaChevronDown />}>
                {selectedCategory}
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => handleCategorySelect('All')}>All</MenuItem>
                <MenuItem onClick={() => handleCategorySelect(ItemCategory.OIL)}>{ItemCategory.OIL}</MenuItem>
                <MenuItem onClick={() => handleCategorySelect(ItemCategory.SOAP)}>{ItemCategory.SOAP}</MenuItem>
                <MenuItem onClick={() => handleCategorySelect(ItemCategory.HERBAL_MEDICINE)}>{ItemCategory.HERBAL_MEDICINE}</MenuItem>
                <MenuItem onClick={() => handleCategorySelect(ItemCategory.SPICE)}>{ItemCategory.SPICE}</MenuItem>
                <MenuItem onClick={() => handleCategorySelect(ItemCategory.HERBS)}>{ItemCategory.HERBS}</MenuItem>
                <MenuItem onClick={() => handleCategorySelect(ItemCategory.SEED)}>{ItemCategory.SEED}</MenuItem>
                <MenuItem onClick={() => handleCategorySelect(ItemCategory.SPIRITUAL_ITEMS)}>{ItemCategory.SPIRITUAL_ITEMS}</MenuItem>
                <MenuItem onClick={() => handleCategorySelect(ItemCategory.BEADS)}>{ItemCategory.BEADS}</MenuItem>
                <MenuItem onClick={() => handleCategorySelect(ItemCategory.CANDLES)}>{ItemCategory.CANDLES}</MenuItem>
                <MenuItem onClick={() => handleCategorySelect(ItemCategory.HERBAL_TEA)}>{ItemCategory.HERBAL_TEA}</MenuItem>
              </MenuList>
            </Menu>
          </Box>

          {/* Search Input */}
          <Box mb={6}>
            <Input
              placeholder="Search for products..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Box>

          {isLoading && <Skeleton height="500px" borderRadius={20} color={'transparent'} width="500px" />}

          <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']} gap={6}>
            {currentProducts && currentProducts.length > 0 ? (
              currentProducts.map((product: Product) => (
                <Box key={product?._id} p={4} borderWidth="1px" borderRadius="lg" overflow="visible">
                  <Box position="relative">
                    {product.images.length > 1 ? (
                      <ImageSlider images={product.images} />
                    ) : (
                      <Image
                        src={product?.images?.[0]}
                        alt={product?.name}
                        maxH="500px"
                        width={['100%', '500px']}
                        height={['300px', '500px']}
                        borderRadius="20px"
                        objectFit="cover"
                      />
                    )}
                  </Box>
                  <Text mt={2} fontWeight="bold">
                    {product?.name}
                  </Text>
                  <Text mt={2} fontSize="lg" color={colorMode === 'light' ? '#345430' : 'white'}>
                    {getPriceInCurrency(product)}
                  </Text>
                  <Text mt={2} whiteSpace="pre-wrap" fontSize={'12px'}>
                    {product?.description}
                  </Text>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mt={4}>
                    <Button
                      fontWeight="bold"
                      py={2}
                      px={4}
                      borderRadius={20}
                      onClick={() => addToCart(product)}
                    >
                      {language === 'en' ? 'Add to Cart' : language === 'yo' ? 'Fí Sí Cart' : 'Añadir al carrito'}
                    </Button>
                    <Box
                        mt={5}
                        mb={5}
                        flex={['1', '1', 'auto']}
                        position="relative"
                        display="flex"
                        justifyContent={'flex-end'}
                      >
                        <Button
                          variant="ghost"
                          color={colorMode === 'light' ? 'white' :  '#345430'}
                          backgroundColor={colorMode === 'light' ? '#345430' :  'white'}
                          onClick={() => toggleShareModal(product._id)}
                        >
                              <BsFillShareFill />
                        </Button>
                        {shareModalVisibility[product._id] &&  
                        <ShopShareButton
                        productUrl={`https://www.ewe.com.ng/shop/`}
                        onClose={() => toggleShareModal(product._id)}
                      />}
                      </Box>
                  </Box>
                </Box>
              ))
            ) : (
              <Text>No products found for the selected category and search term.</Text>
            )}
          </Grid>

          {/* Pagination Controls */}
          <Box mt={6} display="flex" justifyContent="center" alignItems="center">
            {Array.from({ length: totalPages }, (_, index) => (
              <Button
                key={index + 1}
                onClick={() => setCurrentPage(index + 1)}
                colorScheme={currentPage === index + 1 ? 'teal' : 'gray'}
                mx={1}
              >
                {index + 1}
              </Button>
            ))}
          </Box>

          <Center marginTop={20}>
            <Button onClick={toggleCart} bg={colorMode === 'light' ? '#345430' : 'white'} color={colorMode === 'light' ? 'white' : '#345430'} size="lg">
              {language === 'en' ? 'Open Cart' : language === 'yo' ? 'Sí Cart' : 'Abrir Carrito'}
            </Button>
          </Center>
          {isCartOpen && (
            <Cart cart={cart} isOpen={isCartOpen} toggleCart={toggleCart} language={language} />
          )}
        </Box>
      </motion.div>
    </motion.div>
  );
};

export default Shop;

// ImageSlider Component remains the same
const ImageSlider: React.FC<{ images: string[] }> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <Box position="relative" width="100%" height="100%">
      <Image
        src={images[currentIndex]}
        alt="Product Image"
        width="100%"
        height="500px"
        borderRadius="20px"
        objectFit="cover"
      />
      {images.length > 1 && (
        <>
          <Button
            position="absolute"
            top="50%"
            left="10px"
            transform="translateY(-50%)"
            onClick={handlePrev}
            bg="rgba(0, 0, 0, 0.5)"
            color="white"
            _hover={{ bg: 'rgba(0, 0, 0, 0.8)' }}
          >
            &#8249;
          </Button>
          <Button
            position="absolute"
            top="50%"
            right="10px"
            transform="translateY(-50%)"
            onClick={handleNext}
            bg="rgba(0, 0, 0, 0.5)"
            color="white"
            _hover={{ bg: 'rgba(0, 0, 0, 0.8)' }}
          >
            &#8250;
          </Button>
        </>
      )}
    </Box>
  );
};
