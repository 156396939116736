import React from 'react';
import { Box, useColorMode } from '@chakra-ui/react';
import { PostContent } from '../../../components';
import { Post } from '../../../types/interface';

interface BookmarkedPostsProps {
  bookmarks: any;
  handlePostCardClick: (postId: string) => void;
  currentUser: any; // Update this with the appropriate type
  isAuthenticated: boolean;
  isLoading: boolean;
  updatedPostData: (postId: string, newData: any) => void; // Update this with the appropriate type
}

const BookmarkedPosts: React.FC<BookmarkedPostsProps> = ({ bookmarks, handlePostCardClick, currentUser, isAuthenticated, isLoading, updatedPostData }) => {
    const { colorMode } = useColorMode();
  return (
    <Box
      paddingTop="20px"
      paddingBottom="20px"
      style={{
        backgroundColor: colorMode === 'light' ? '#E0EAE0' : '#588552',
        borderRadius: '20px',
      }}
    >
      <Box>
        {bookmarks?.length === 0 || bookmarks === undefined ? (
          <Box textAlign="center" fontSize="18px">
            No bookmarks found.
          </Box>
        ) : (
          <Box 
          padding={10}
          justifyContent="center"
          alignItems="start"
          flexDirection={'column'}>
            {bookmarks?.map((bookmark: Post) => ( // Use the BookmarkedPost type
              <PostContent
                    key={bookmark._id}
                    post={bookmark}
                    handlePostCardClick={handlePostCardClick}
                    currentUser={currentUser}
                    isAuthenticated={isAuthenticated}
                    isLoading={isLoading}
                    updatedPostData={updatedPostData} />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BookmarkedPosts;
