import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  useColorMode,
  Divider,
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { selectCurrentUser } from '../../slice/userSlice';
import {
  User,
  Articles,
  Post,
  RootState,
} from '../../types/interface';
import {
  useFollowUsersMutation,
  useLazyGetUserPostQuery,
  useLazyGetUserProfileQuery,
} from '../../api/userApi';
import {
  useLazyGetUserArticleQuery,
} from '../../api/articles';
import { AutoBackButton, ToastNotification } from '../../components';
import UserInfo from './UserInfo';
import SegmentSelector from './SegmentSelector';
import ContentDisplay from './ContentDisplay';

const UserProfile = () => {
  const { userId } = useParams();
  const currentUser = useSelector(selectCurrentUser) as User;
  const isAuthenticated = useSelector(
    (state: RootState) => state.users.isLogin
  );
  const navigate = useNavigate();
  const { colorMode } = useColorMode();
  const [triggerUserProfile, { data: userData, isLoading: userLoading, isError: userError }] = useLazyGetUserProfileQuery();
  const [triggerUserPosts, { data: posts, isLoading: postsLoading }]  = useLazyGetUserPostQuery();
  const [triggerUserArticles, { data: article, isLoading: articleLoading }] = useLazyGetUserArticleQuery();
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: '',
    status: '',
  });


  useEffect(() => {
    if (userId) {
      triggerUserProfile(userId, true); // Fetch user profile data, prefer cache if available
      triggerUserPosts(userId, true); // Fetch user post data, prefer cache if available'
      triggerUserArticles(userId, true)
    }
  }, [userId, triggerUserProfile, triggerUserPosts, triggerUserArticles]);

  // console.log("user Id: ", userId)
  const [allPosts, setAllPosts] = useState<Post[]>(posts?.data?.posts || []);
  const [articles, setArticles] = useState<Articles[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [selectedSegment, setSelectedSegment] = useState('Posts');

  const handleSegmentChange = (segment: string) => {
    setSelectedSegment(segment);
  };

  const updatePostData = (postId: string, newData: any) => {
    setAllPosts((prevPosts: any[]) =>
      prevPosts.map((post: any) =>
        post._id === postId ? { ...post, ...newData } : post
      )
    );
  };

  const updatedArticleData = (articleId: string, newData: Partial<Articles>) => {
    setArticles((prevPost) => ({
      ...prevPost,
      ...newData,
    }));
  };

  const [followUsersMutation] = useFollowUsersMutation();

  const handleFollowUser = async () => {
    if (userId) {
      try {
        const response = await followUsersMutation({ userId });

        if ('data' in response && response.data?.statusCode === 201) {
          setToastConfig({
            description: response.data.data.message,
            status: 'success',
          });
          setShowToast(true);
          console.log('User followed successfully:', response);
          window.location.reload();
        } else if ('error' in response) {
          const errorMessage = 'An error occurred while following the user.';

          setToastConfig({
            description: errorMessage,
            status: 'error',
          });
          setShowToast(true);
        }
      } catch (error) {
        setToastConfig({
          description: 'An error occurred while following the user.',
          status: 'error',
        });
        setShowToast(true);
      }
    } else {
      console.error('User ID is undefined');
    }
  };

  useEffect(() => {
    if (posts) {
      const post = posts.data.posts;
      setAllPosts(post);
    }
  }, [posts]);

  useEffect(() => {
    if (article) {
      const allArticles = article.data.articles;
      setArticles(allArticles);
    }
  }, [article]);

  // console.log("User's Profile: ", userData)  
  // console.log("User's Post: ", allPosts)

  // const locationName = useSelector(
  //   (state: RootState) => state.reverseGeocode.locationName
  // );

  const handlePostCardClick = (postId: string) => {
    if (isAuthenticated) {
      navigate(`/user/${userId}/posts/${postId}`);
    } else {
      navigate('/login');
    }
  };

  const handleArticleClick = (articleId: string) => {
    navigate(`/user/${userId}/articles/${articleId}`);
  };

  return (
    <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5, ease: 'easeIn' }}>
      <Helmet><title>User Profile Page</title></Helmet>
      <Box padding="20px" fontFamily="Inter" color={colorMode === 'light' ? '#345430' : 'white'}>
        <AutoBackButton />
        <UserInfo 
        userData={userData} 
        currentUser={currentUser} 
        userId={userId} 
        userLoading={userLoading}
        userError={userError}
        colorMode={colorMode} 
        handleFollowUser={handleFollowUser}
        navigate={navigate} />
        <SegmentSelector selectedSegment={selectedSegment} handleSegmentChange={handleSegmentChange} colorMode={colorMode} />
        <Divider my={8} />
        <ContentDisplay selectedSegment={selectedSegment} allPosts={allPosts} handlePostCardClick={handlePostCardClick}
         postsLoading={postsLoading} updatePostData={updatePostData} articles={articles} handleArticleClick={handleArticleClick} articleLoading={articleLoading} 
         updatedArticleData={updatedArticleData} userId={undefined} />
        {showToast && <ToastNotification description={toastConfig.description} status={toastConfig.status} onClose={() => setShowToast(false)} />}
      </Box>
    </motion.div>
  );
};

export default UserProfile;
