import bird from "../assets/bird.png"
import leaf from "../assets/leaf_icon.png";
import chalk from "../assets/chalk.png"
import feather from '../assets/feather.png'
import bark from '../assets/bark.png'
import animal from '../assets/elephant.png'
import fruits from '../assets/fruits.png'
import tree from '../assets/tree.png'
import insect from "../assets/ladybug.png";
import fish from '../assets/zander.png'
import oponIfa from '../assets/download-2.jpg'
import medicine from '../assets/mortar-pestle.png'
import { FilterOption } from "../types/interface";
import seeds from '../assets/nuts.png'

export const filterOptions: FilterOption[] = [
    {
        id: 1,
        icon: leaf,
        type: {
            yo: "Ewé",
            en: 'Leaf',
            es: 'Hoja'
        },
    },
    {
        id: 2,
        icon: bird,
        type: {
            yo: "ẹyẹ",
            en: 'Bird',
            es: 'Pájaro'
        },
    },
    {
        id: 3,
        icon: chalk,
        type: {
            yo: "efún",
            en: 'Chalk',
            es: 'Tiza'
        },
    },
    {
        id: 4,
        icon: feather,
        type: {
            yo: "Iyẹ́",
            en: 'Feather',
            es: 'Pluma'
        },
    },
    {
        id: 5,
        icon: bark,
        type: {
            yo: "eepo igi",
            en: 'Bark',
            es: 'Corteza'
        },
    },
    {
        id: 6,
        icon: animal,
        type: {
            yo: "ẹ́rankó",
            en: 'Animal',
            es: 'Animal'
        },
    },
    {
        id: 7,
        icon: fish,
        type: {
            yo: "ẹja",
            en: 'Fish',
            es: 'Pez'
        },
    },
    {
        id: 8,
        icon: tree,
        type: {
            yo: "igi",
            en: 'Tree',
            es: 'Árbol'
        },
    },
    {
        id: 9,
        icon: insect,
        type: {
            yo: "kokoro",
            en: 'Insect',
            es: 'Insecto'
        },
    },
    {
        id: 10,
        icon: fruits,
        type: {
            yo: "eso",
            en: 'Fruit',
            es: 'Fruta'
        },
    },
    {
        id: 11,
        icon: medicine,
        type: {
            yo: "Òogùn Ìbílé",
            en: 'Herbal Medicine',
            es: 'Medicina Herbal'
        },
    },
    {
        id: 12,
        icon: oponIfa,
        type: {
            yo: "Odù Ifá",
            en: 'Ifa Verses',
            es: 'Versos de Ifá'
        },
    },
    {
        id: 13,
        icon: seeds,
        type: {
            yo: "Irúgbìn",
            en: 'Seeds',
            es: 'Semillas'
        },
    }
];
