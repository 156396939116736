// ContentDisplay.tsx
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import UserPosts from '../../components/User/UserPosts';
import UserArticle from '../../components/User/UserArticle';
import UserStores from '../Stores/UserStores';


  
export interface UserInfoProps {
    selectedSegment: any;
    allPosts : any;
    handlePostCardClick: any;
    postsLoading: any;
    updatePostData: any;
    articles: any;
    handleArticleClick: any;
    articleLoading: any;
    updatedArticleData: any;
    userId: any;
  }

const ContentDisplay: React.FC<UserInfoProps> = ({
  selectedSegment,
  allPosts,
  handlePostCardClick,
  postsLoading,
  updatePostData,
  articles,
  handleArticleClick,
  articleLoading,
  updatedArticleData,
  userId,
}) => (
  <Swiper grabCursor centeredSlides pagination={{ clickable: true }}>
    <SwiperSlide>
      {selectedSegment === 'Posts' && (
        <UserPosts posts={allPosts} handlePostCardClick={handlePostCardClick} isLoading={postsLoading} updatedPostData={updatePostData} />
      )}
      {selectedSegment === 'Articles' && (
        <UserArticle
          articles={articles}
          handleArticleClick={handleArticleClick}
          isLoading={articleLoading}
          updatedArticleData={updatedArticleData}
          userId={userId}
        />
      )}
      {selectedSegment === 'Store' && <UserStores />}
    </SwiperSlide>
  </Swiper>
);

export default ContentDisplay;
