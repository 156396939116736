import { useEffect, useRef, useState } from "react";
import {
  Select,
  Button,
  Textarea,
  Input,
  Flex,
  Image,
  Heading,
  VStack,
  useColorMode,
  Box,
  Text,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler, Controller, useFieldArray } from "react-hook-form";
import { ToastNotification } from "../components";
import { FilterOption, RootState, User } from "../types/interface";
import { filterOptions } from "../data/filterType";
import { MdDelete } from "react-icons/md";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { selectAccessToken, selectCurrentUser } from "../slice/userSlice";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useUpdatePostMutation } from '../api/postApi';

interface FormData {
  location: { latitude: number; longitude: number; };
  lang: string;
  name: string;
  description: string;
  uses: string;
  otherNames: { value: string }[];
  images: File[];
  type: string;
}

interface OtherName {
  value: string;
}

const MAX_COUNT = 4;

const EditPost = () => {
  const { id } = useParams(); // Get the post ID from URL params
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser) as unknown as User;
  const language = useSelector((state: RootState) => state.language.language);
  const { colorMode } = useColorMode();
  const [loading, setLoading] = useState(false);
  const [previewImages, setPreviewImages] = useState<string[]>([]);
  const [fileLimit, setFileLimit] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: '',
    status: '',
  });
  const accessToken = useSelector(selectAccessToken); 
  const [otherNames, setOtherNames] = useState<OtherName[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const location = useSelector(
    (state: RootState) => state.reverseGeocode.locationName
  );

  const navigate = useNavigate(); // Add useNavigate hook
  const { handleSubmit, control, register, 
    formState: { isValid, isSubmitting, errors }, reset ,
     setValue,
     getValues } = useForm<FormData>({
    defaultValues: {
      otherNames: [],
      images: [] // initialized as an empty array
    },
    mode: 'onBlur',
    criteriaMode: 'all',
  });

  useEffect(() => {
    // Fetch post data to edit
    const fetchPost = async () => {
      try {
        const response = await axios.get(`https://ewe-api.herokuapp.com/api/users/${currentUser.id}/posts/${id}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        const post = response.data.data;

        // Set fetched data into form fields
        setValue('name', post.name);
        setValue('description', post.description);
        setValue('uses', post.uses);
        setValue('type', post.type);
        setValue('otherNames', post.otherNames.map((name: string) => ({ value: name })));
        setPreviewImages(post.images);
        setOtherNames(post.otherNames.map((name: string) => ({ value: name })));
      } catch (error) {
        console.error("Error fetching post data", error);
      }
    };

    fetchPost();
    window.scrollTo(0, 0);
  }, [id, accessToken, currentUser.id, setValue]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFiles(Array.from(e.target.files));
    }
  };

  const selectFiles = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setLoading(true);
      const selectedFiles = Array.from(event.target.files);
  
      if (previewImages.length + selectedFiles.length > MAX_COUNT) {
        setLoading(false);
        return;
      }
  
      // Convert File objects to their corresponding URLs
      const fileURLs = selectedFiles.map((file) => URL.createObjectURL(file));
      setPreviewImages((prevImages) => [...prevImages, ...fileURLs]);
  
      // Add selected files to selectedFiles state
      setSelectedFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  
      // Append selected files to form data
      const currentImages = getValues('images') || [];
      setValue('images', [...currentImages, ...selectedFiles]);
  
      // Append otherNames if needed
      setValue('otherNames', otherNames);
  
      checkFileLimit(selectedFiles);
      setLoading(false);
    }
  };

  const { fields, append, remove } = useFieldArray({
    name: 'otherNames',
    control,
  });

  const filter: FilterOption[] = filterOptions;
  const onSubmit: SubmitHandler<FormData> = async (data) => {
     // Include the location in the form data if it is available
     if (location) {
      data.location = location;
    }

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append('lang', language); // Language field
      formData.append('name', data.name); // Name field
      formData.append('description', data.description); // Description field
      formData.append('uses', data.uses); // Uses field
      formData.append('type', data.type); // Type field

       // Append location
      if (data.location) {
        formData.append('location', data.location.toString());
      }
      
      // Append otherNames array
      otherNames.forEach((otherName, index) => formData.append(`othernames[${index}]`, otherName.value));
      selectedFiles.forEach((file) => {
        formData.append('files', file);
      });
      
      const response = await axios.patch(
        `https://ewe-api.herokuapp.com/api/users/${currentUser.id}/posts/${id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${accessToken}`, 
            'Access-Control-Allow-Origin': '*/*',
          },
        }
      );

      if ('data' in response) {
        const responseData = response.data.data;
        const message = responseData.message || 'Success !!! Post was successfully updated!';
        setToastConfig({ description: message, status: 'success' });
        setShowToast(true);       
        // dispatch(updatePost({ userId: currentUser.id, id, updatedPost: responseData }));
        reset();
        navigate(`/user/${currentUser.id}/posts`);
      } else if ('error' in response) {
        setToastConfig({ description: 'Error in Updating Posts', status: 'error' });
        setShowToast(true);
        setLoading(false);
      }
    } catch (error: any) {
      setToastConfig({ description: error.message || 'An error occurred', status: 'error' });
      setShowToast(true);
      setLoading(false);
    }
  };

  const checkFileLimit = (files: File[]) => {
    if (files.length > MAX_COUNT) {
      setFileLimit(true);
      setToastConfig({ description: `You can only add a maximum of ${MAX_COUNT} files`, status: 'error' });
      setShowToast(true);
    }
  };
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger click event of the file input element
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeIn" }}
      style={{ backgroundColor: colorMode === 'light' ? 'white' : '#345430', color: colorMode === 'light' ? '#345430' : 'white' }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Edit Post Page</title>
      </Helmet>
      <motion.div
        style={{ backgroundColor: colorMode === 'light' ? 'white' : '#345430', paddingBottom: "20px" }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.5, ease: "easeIn" }}
      >
        <Box fontFamily="Inter" paddingTop={20} marginRight={{ base: "15", md: "20" }} marginLeft={{ base: "15", md: "20" }} pb={20}>
        <Heading fontFamily="Inter" as="h6" fontSize={{ base: '20px', md: "30px"

 }} textAlign="center" mb="8">
            Edit your Post
          </Heading>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex direction="column" gap="4">
              <VStack align="stretch" spacing="4">
                <Controller
                  control={control}
                  name="name"
                  rules={{ required: "Name is required", maxLength: 255 }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder="Name"
                      size="lg"
                      borderColor={errors.name ? "red.500" : "gray.300"}
                    />
                  )}
                />
                <Text color="red.500" fontSize="sm">{errors.name?.message}</Text>

                <Controller
                  control={control}
                  name="description"
                  rules={{ required: "Description is required", maxLength: 1024 }}
                  render={({ field }) => (
                    <Textarea
                      {...field}
                      placeholder="Description"
                      size="lg"
                      borderColor={errors.description ? "red.500" : "gray.300"}
                    />
                  )}
                />
                <Text color="red.500" fontSize="sm">{errors.description?.message}</Text>

                <Controller
                  control={control}
                  name="uses"
                  rules={{ required: "Uses is required", maxLength: 1024 }}
                  render={({ field }) => (
                    <Textarea
                      {...field}
                      placeholder="Uses"
                      size="lg"
                      borderColor={errors.uses ? "red.500" : "gray.300"}
                    />
                  )}
                />
                <Text color="red.500" fontSize="sm">{errors.uses?.message}</Text>

                <Controller
                  control={control}
                  name="type"
                  rules={{ required: "Type is required" }}
                  render={({ field }) => (
                    <Select
                    {...register("type")}
                    placeholder={language === "en" ? "Select Category..." : (language === 'yo' ? 'Iru Nkan ti e fẹ́ ko...' : 'Seleccionar Categoría...')}
                    {...field}
                    backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
                    borderColor={colorMode === 'light' ? '#345430' : 'white'}
                    fontFamily="Inter"
                    fontSize="20px"
                    _focus={{ borderColor: colorMode === 'light' ? '#345430' : 'white' }}
                    width={"100%"}
                    height={"60px"}
                  >
                    {filter.map((option) => (
                      <option key={option.id} value={typeof option.type === "string" ? option.type : option.type[language]}>
                        <Flex align="center" textTransform={'capitalize'} justifyContent={'space-between'}>
                          <Image src={option.icon} boxSize="100px" p={10} />
                          {typeof option.type === "string" ? option.type : option.type[language]}
                        </Flex>
                      </option>
                    ))}
                  </Select>
                  )}
                />
                <Text color="red.500" fontSize="sm">{errors.type?.message}</Text>

                <VStack align="stretch" spacing="4">
                  {fields.map((field, index) => (
                    <Flex key={field.id} alignItems="center">
                      <Controller
                        control={control}
                        name={`otherNames.${index}.value`}
                        render={({ field }) => (
                          <Input
                            {...field}
                            placeholder={`Other Name ${index + 1}`}
                            size="lg"
                            borderColor={errors.otherNames?.[index]?.value ? "red.500" : "gray.300"}
                          />
                        )}
                      />
                      <Button
                        ml={2}
                        colorScheme="red"
                        size="sm"
                        onClick={() => remove(index)}
                      >
                        <MdDelete />
                      </Button>
                    </Flex>
                  ))}
                  <Button size="sm" onClick={() => append({ value: "" })}>
                    Add Other Name
                  </Button>
                </VStack>

                <VStack align="stretch" spacing="4">
                  <input
                    type="file"
                    multiple
                    accept="image/*"
                    ref={fileInputRef}
                    onChange={selectFiles}
                    style={{ display: "none" }}
                  />
                  <Button onClick={handleButtonClick}>
                    <AiOutlineCloudUpload />
                    <Text ml={2}>Upload Images</Text>
                  </Button>
                  {previewImages.length > 0 && (
                    <Flex wrap="wrap" justifyContent="center">
                      {previewImages.map((image, index) => (
                        <Box
                          key={index}
                          position="relative"
                          width="100px"
                          height="100px"
                          mr={2}
                          mb={2}
                        >
                          <Image
                            src={image}
                            alt={`preview ${index}`}
                            objectFit="cover"
                            width="100px"
                            height="100px"
                          />
                          <Button
                            size="xs"
                            position="absolute"
                            top={1}
                            right={1}
                            colorScheme="red"
                            onClick={() => {
                              setPreviewImages(prevImages =>
                                prevImages.filter((_, i) => i !== index)
                              );
                              setSelectedFiles(prevFiles =>
                                prevFiles.filter((_, i) => i !== index)
                              );
                            }}
                          >
                            <MdDelete />
                          </Button>
                        </Box>
                      ))}
                    </Flex>
                  )}
                </VStack>
              </VStack>

              <Button
                type="submit"
                size="lg"
                colorScheme="teal"
                isLoading={loading || isSubmitting}
                disabled={!isValid || fileLimit}
                alignSelf="center"
                mt={6}
              >
                Update Post
              </Button>
            </Flex>
          </form>
        </Box>
      </motion.div>
      {showToast && (
                <ToastNotification
                  description={toastConfig.description}
                  status={toastConfig.status}
                  onClose={() => setShowToast(false)}
                />
              )}
    </motion.div>
  );
};

export default EditPost;