import { useState } from "react";
import {
  Input,
  Button,
  Textarea,
  Checkbox,
  CheckboxGroup,
  VStack,
  Box,
  Text,
  useColorMode,
  Heading,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { ToastNotification } from "../../components";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { selectAccessToken, selectCurrentUser } from "../../slice/userSlice";
import { addStore } from "../../slice/storeSlice";
import { RootState, User } from "../../types/interface";


enum ItemCategory {
  OIL = "Oils",
  SOAP = "Soaps",
  HERBAL_MEDICINE = "Herbal Medicines",
  SPICE = "Spices",
  HERBS = "Herbs",
  SEED = "Seeds",
  SPIRITUAL_ITEMS = "Spiritual Items",
  HERBAL_TEA = "Herbal Teas",
  CANDLES = "Candles",
  BEADS = "Beads",
}

interface StoreData {
  name: string;
  description: string;
  categories: ItemCategory[];
}

const CreateStores = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser) as unknown as User;
  const accessToken = useSelector(selectAccessToken);
  const { control, handleSubmit, formState: { isSubmitting }, reset } = useForm<StoreData>();
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({ description: '', status: '' });
  const language = useSelector((state: RootState) => state.language.language);
  const { colorMode } = useColorMode();

  const onSubmit = async (data: StoreData) => {
    setLoading(true);

    const body = {
      name : data.name,
      description: data.description,
      category : data.categories
    }

    try {
      const response = await axios.post(
        `https://ewe-api.herokuapp.com/api/stores`,
        body,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        }
      );

      // console.log("Response for Store : ", response);

      const responseData = response.data;
      setToastConfig({ description: 'Store successfully created!', status: 'success' });
      dispatch(addStore(responseData));
      setShowToast(true);
      reset();
      navigate(`/user/${currentUser.id}/stores`);
    } catch (error) {
      setToastConfig({ description: 'Failed to create store', status: 'error' });
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
      style={{
        backgroundColor: colorMode === 'light' ? 'white' : '#345430',
        color: colorMode === 'light' ? '#345430' : 'white',
      }}
    >
      <motion.div
        style={{
          backgroundColor: colorMode === 'light' ? 'white' : '#345430',
          paddingBottom: '20px',
        }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.5, ease: 'easeIn' }}
      >
        <Box
          fontFamily="Inter"
          paddingTop={20}
          mx={{ base: 4, md: 10 }}
          pb={10}
        >
          <Heading
            fontFamily="Inter"
            as="h1"
            fontSize={{ base: '24px', md: '32px' }}
            textAlign="center"
            mb={6}
          >
            {language === 'en' ? 'Create a new Store ' : language === 'yo' ? 'Sí Ọjà Tuntun ' : 'Crear Tienda'}
          </Heading>
          <form onSubmit={handleSubmit(onSubmit)}>
            <VStack spacing={6} align="stretch">
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input 
                    {...field} 
                    placeholder="Store Name" 
                    borderRadius="md"
                    borderWidth="1px"
                    borderColor="gray.300"
                    backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
                    required 
                  />
                )}
              />
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <Textarea 
                    {...field} 
                    placeholder="Store Description"
                    borderRadius="md"
                    borderWidth="1px"
                    borderColor="gray.300"
                    height={500}
                    backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#588552'}
                    required 
                  />
                )}
              />
              <Controller
                name="categories"
                control={control}
                render={({ field }) => (
                  <Box>
                    <Text fontSize="lg" mb={2}>Select Categories</Text>
                    <CheckboxGroup {...field} onChange={field.onChange}>
                      <Wrap spacing={4}>
                        {Object.values(ItemCategory).map((category) => (
                          <WrapItem key={category}>
                            <Checkbox value={category}>{category}</Checkbox>
                          </WrapItem>
                        ))}
                      </Wrap>
                    </CheckboxGroup>
                  </Box>
                )}
              />
              <Button
                type="submit"
                isLoading={loading || isSubmitting}
                backgroundColor={colorMode === 'light' ? "#345430" : "white"}
                color={colorMode === 'light' ? 'white' : '#345430'}
                size="lg"
                width="full"
              >
                Submit
              </Button>
              {showToast && (
                <ToastNotification description={toastConfig.description} status={toastConfig.status} onClose={() => setShowToast(false)} />
              )}
            </VStack>
          </form>
        </Box>
      </motion.div>
    </motion.div>
  );
};

export default CreateStores;
