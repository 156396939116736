import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  useColorMode,
  IconButton,
  Textarea,
  Spinner,
} from '@chakra-ui/react';
import { FaBold, FaItalic, FaUnderline, FaUndo, FaRedo, FaLink } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import axios from 'axios';
import ToastNotification from '../ToastNotification'; // Assuming this is your custom ToastNotification component
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useCreateArticlesMutation } from '../../api/articles';
import { User } from '../../types/interface';
import { selectAccessToken, selectCurrentUser } from '../../slice/userSlice';

export interface FormValues extends FieldValues {
  title: string;
  keywords: string;
  content: string;
  pdfFiles: File[];
}

const MAX_FILE_SIZE = 10 * 1024 * 1024;

const ArticleForm: React.FC = () => {
  const { colorMode } = useColorMode();
  const {    
  formState: { isValid, isSubmitting }, 
  reset ,
   register, handleSubmit, setValue } = useForm<FormValues>();
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({ description: '', status: '' });
  const fileInputRef = useRef<HTMLInputElement>(null);
  const pdfInputRef = useRef<HTMLInputElement>(null); // Added this line
  const [uploadArticles] = useCreateArticlesMutation();
  const currentUser = useSelector(selectCurrentUser) as User;
  const navigate = useNavigate();
  const accessToken = useSelector(selectAccessToken);
  const [loading, setLoading] = useState(false);
  const [previewContent, setPreviewContent] = useState<JSX.Element | null>(null);

  const handlePdfUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files; // Access the files from the event

    if (files) {
        const filesArray = Array.from(files);
        const fileUrls: string[] = [];

        setLoading(true);

        for (const file of filesArray) {
            if (file.type === 'application/pdf') {
                if (file.size > MAX_FILE_SIZE) {
                    setToastConfig({
                        description: 'File exceeds the maximum allowed size of 10 MB.',
                        status: 'warning',
                    });
                    setShowToast(true);
                    continue; // Skip this file
                }

                setToastConfig({
                    description: 'Uploading PDF file...',
                    status: 'info',
                });
                setShowToast(true);

                const formData = new FormData();
                formData.append('files', file);

                try {
                    const response = await axios.post(
                        `https://ewe-api.herokuapp.com/api/users/${currentUser.id}/upload`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                Authorization: `Bearer ${accessToken}`,
                                'Access-Control-Allow-Origin': '*/*',
                            },
                        }
                    );

                    if ('data' in response && response?.data?.data) {
                        const fileUrl = response.data.data;
                        fileUrls.push(fileUrl);
                    }
                } catch (error) {
                    console.error('An error occurred while uploading PDF files:', error);
                    setToastConfig({
                        description: 'An error occurred during the upload.',
                        status: 'error',
                    });
                    setShowToast(true);
                }
            }
        }

        // Here, you can handle the uploaded file URLs
        if (fileUrls.length > 0) {
            const textarea = document.querySelector('textarea') as HTMLTextAreaElement;
            const currentValue = textarea.value;
            const newValue = fileUrls.reduce((acc, fileUrl, index) => {
              return `${acc}\n<a href="${fileUrl}" target="_blank">Uploaded PDF ${index + 1}</a>`;
          }, currentValue);

            textarea.value = newValue;
            setValue('content', newValue);
            setPreviewContent(renderPreview(newValue));
        }

        setLoading(false);
    }
  };

  const handleContentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setValue('content', newValue);
    setPreviewContent(renderPreview(newValue)); // Update preview content
  };

  const renderPreview = (content: string) => {
    const imageRegex = /<img src="(.*?)" alt="uploaded image" \/>/g;
    const pdfRegex = /<a href="(.*?)" target="_blank">Uploaded PDF<\/a>/g;

    const elements: JSX.Element[] = [];
    let lastIndex = 0;

    // Handle images
    content.replace(imageRegex, (match, src, index) => {
      // Add text before image
      elements.push(<span key={`text-before-image-${index}`}>{content.substring(lastIndex, index)}</span>);
      elements.push(
        <img
          key={src}
          src={src}
          alt=""
          style={{ maxWidth: '100%', margin: '10px 0' }}
        />
      );
      lastIndex = index + match.length;
      return match;
    });

    // Handle PDFs
    content.replace(pdfRegex, (match, href, index) => {
      // Add text before PDF
      elements.push(<span key={`text-before-pdf-${index}`}>{content.substring(lastIndex, index)}</span>);
      elements.push(
        <Box key={href} border="1px solid #ddd" margin="10px 0">
          <iframe
            src={href}
            width="100%"
            height="500px"
            title={`PDF Preview - ${index}`}
            style={{ border: 'none' }}
          ></iframe>
        </Box>
      );
      lastIndex = index + match.length;
      return match;
    });

    // Add any remaining text after the last image or PDF
    elements.push(<span key="remaining-text">{content.substring(lastIndex)}</span>);

    return <Box>{elements}</Box>;
  };

  let history: any[] = [];
  let redoStack: any[] = [];

  const undo = () => {
    if (history.length > 0) {
      const lastAction = history.pop();
      redoStack.push(lastAction);
      console.log('Undo action:', lastAction);
      // Implement your logic to revert the last action
    }
  };

  const redo = () => {
    if (redoStack.length > 0) {
      const lastRedo = redoStack.pop();
      history.push(lastRedo);
      console.log('Redo action:', lastRedo);
      // Implement your logic to reapply the last undone action
    }
  };

  // const saveToHistory = () => {
  //   // Logic to save the current state to history
  //   history.push(/* current state or action */);
  // };
  const formatText = (command: string) => {
    const textarea = document.querySelector('textarea') as HTMLTextAreaElement;
    const { selectionStart, selectionEnd, value } = textarea;

    const before = value.substring(0, selectionStart);
    const selectedText = value.substring(selectionStart, selectionEnd);
    const after = value.substring(selectionEnd);

    let formattedText = '';

    switch (command) {
      case 'bold':
        formattedText = `<b>${selectedText}</b>`;
        break;
      case 'italic':
        formattedText = `<i>${selectedText}</i>`;
        break;
      case 'underline':
        formattedText = `<u>${selectedText}</u>`;
        break;
    }

    const newValue = `${before}${formattedText}${after}`;
    textarea.value = newValue.replace(/\n/g, '<br/>'); // Replace new lines
    setValue('content', newValue);
    setPreviewContent(renderPreview(newValue));
  };

  const insertLink = () => {
    const url = prompt('Enter the URL:');
    const text = prompt('Enter the text for the link:');
    const textarea = document.querySelector('textarea') as HTMLTextAreaElement;

    if (url && text) {
      const { selectionStart, selectionEnd, value } = textarea;

      const before = value.substring(0, selectionStart);
      const selectedText = value.substring(selectionStart, selectionEnd) || text;
      const after = value.substring(selectionEnd);

      const linkHTML = `<a href="${url}" target="_blank">${selectedText}</a>`;
      const newValue = `${before}${linkHTML}${after}`;

      textarea.value = newValue;
      setValue('content', newValue);
      setPreviewContent(renderPreview(newValue));
    }
  };

  // This is your main file upload handler
  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      const filesArray = Array.from(files);
      const fileUrls: string[] = [];

      setLoading(true);

      for (const file of filesArray) {
        if (file.type.startsWith('image/')) {
          if (file.size > MAX_FILE_SIZE) {
            setToastConfig({
              description: 'File exceeds the maximum allowed size of 10 MB.',
              status: 'warning',
            });
            setShowToast(true);
            continue; // Skip this file
          }

          setToastConfig({
            description: 'Uploading image...',
            status: 'info',
          });
          setShowToast(true);

          const formData = new FormData();
          formData.append('files', file);

          try {
            const response = await axios.post(
              `https://ewe-api.herokuapp.com/api/users/${currentUser.id}/upload`,
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${accessToken}`,
                  'Access-Control-Allow-Origin': '*/*',
                },
              }
            );

            if ('data' in response && response?.data?.data) {
              const fileUrl = response.data.data;
              fileUrls.push(fileUrl);
            }
          } catch (error) {
            console.error('An error occurred while uploading images:', error);
            setToastConfig({
              description: 'An error occurred during the upload.',
              status: 'error',
            });
            setShowToast(true);
          }
        }
      }

      // Here, you can handle the uploaded file URLs
      if (fileUrls.length > 0) {
        const textarea = document.querySelector('textarea') as HTMLTextAreaElement;
        const currentValue = textarea.value;

        const newValue = fileUrls.reduce((acc, fileUrl) => {
          return `${acc}\n<img src="${fileUrl}" alt="uploaded image" 
          style="max-width: 100%; margin: 10px 0px; width: 300px;
           height: 300px; object-fit: cover; border-radius: 10px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);" />`;
        }, currentValue);
        

        textarea.value = newValue;
        setValue('content', newValue);
        setPreviewContent(renderPreview(newValue));
      }

      setLoading(false);
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {

    const body = {
      'htmlContent': data.content,
      'keywords': data.keywords,
      'title': data.title
    }
    
  
    try {
      const response = await uploadArticles({
        userId: currentUser.id,
        body: body,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
          'Access-Control-Allow-Origin': '*/*',
        },
      });
  
      console.log("response: ", response);
  
      if ('data' in response) {
        const responseData = response.data;
        const message = responseData.message || 'Success! Article was successfully sent!';
        setToastConfig({ description: message, status: 'success' });
        setShowToast(true);
        reset();
        navigate(`/user/${currentUser.id}/articles`);
      } else if ('error' in response) {
        const errorResponseData = response as { error?: string; message?: string };
        const errorMessage = errorResponseData.message || 'Error in Creating Article';
        setToastConfig({ description: errorMessage, status: 'error' });
        setShowToast(true);
      }
    } catch (error: any) {
      console.error('An error occurred:', error);
      setToastConfig({ description: error.message || 'An error occurred', status: 'error' });
      setShowToast(true);
    }
  };
  

  return (
    <Box
      maxW="1400px"
      mx="auto"
      mt={4}
      p={4}
      borderWidth={1}
      borderRadius="md"
      boxShadow="md"
      // bg={colorMode === 'light' ? 'white' : 'gray.800'}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl id="title" isRequired>
          <FormLabel>Title</FormLabel>
          <Input {...register('title')} />
        </FormControl>

        <FormControl id="keywords" mt={4} isRequired>
          <FormLabel>Keywords</FormLabel>
          <Input {...register('keywords')} />
        </FormControl>

        <FormControl id="content" 
        mt={4} isRequired
        >
          <FormLabel>Content</FormLabel>
          <Textarea
            {...register('content')}
            rows={50}
            onChange={handleContentChange}
          />
        </FormControl>

        <Box
          mt={2}
          display="flex"
          flexWrap="wrap"
          flexDirection={{ base: 'column', md: 'row' }}
          justifyContent={{ base: 'center', md: 'space-between' }}
          alignItems={{ base: 'center', md: 'flex-start' }}
        >
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent={{ base: 'center', md: 'flex-start' }}
          mb={{ base: 2, md: 0 }}
        >
            <IconButton
              aria-label="Bold"
              icon={<FaBold />}
              onClick={() => formatText('bold')}
              backgroundColor={colorMode === 'light' ? '#fff' : '#E0EAE0'}
              color={colorMode === 'light' ? '#000' : '#345430'}
              fontFamily="Inter"
              fontSize={{ base: '14px', md: '16px' }}
              m={1}
            />
            <IconButton
              aria-label="Italic"
              icon={<FaItalic />}
              onClick={() => formatText('italic')}
              backgroundColor={colorMode === 'light' ? '#fff' : '#E0EAE0'}
              color={colorMode === 'light' ? '#000' : '#345430'}
              fontFamily="Inter"
              fontSize={{ base: '14px', md: '16px' }}
              m={1}
            />
            <IconButton
              aria-label="Underline"
              icon={<FaUnderline />}
              onClick={() => formatText('underline')}
              backgroundColor={colorMode === 'light' ? '#fff' : '#E0EAE0'}
          color={colorMode === 'light' ? '#000' : '#345430'}
          fontFamily="Inter"
          fontSize={{ base: '14px', md: '16px' }}
          m={1}
            />
            <IconButton
              aria-label="Insert Link"
              icon={<FaLink />}
              onClick={insertLink}
              backgroundColor={colorMode === 'light' ? '#fff' : '#E0EAE0'}
              color={colorMode === 'light' ? '#000' : '#345430'}
              fontFamily="Inter"
              fontSize={{ base: '14px', md: '16px' }}
              m={1}
            />
            <IconButton
              aria-label="Undo"
              icon={<FaUndo />}
              onClick={undo}
              backgroundColor={colorMode === 'light' ? '#fff' : '#E0EAE0'}
              color={colorMode === 'light' ? '#000' : '#345430'}
              fontFamily="Inter"
              fontSize={{ base: '14px', md: '16px' }}
              m={1}
            />
            <IconButton
              aria-label="Redo"
              icon={<FaRedo />}
              onClick={redo}
              mr={2}
              backgroundColor={colorMode === 'light' ? '#fff' : '#E0EAE0'}
              color={colorMode === 'light' ? '#000' : '#345430'}
              fontFamily="Inter"
              fontSize={{ base: '14px', md: '16px' }}
            />
          <IconButton
          icon={<Box as="span">H1</Box>}
          aria-label="Heading1"
          onClick={() => formatText('heading1')}
          backgroundColor={colorMode === 'light' ? '#fff' : '#E0EAE0'}
          color={colorMode === 'light' ? '#000' : '#345430'}
          fontFamily="Inter"
          fontSize={{ base: '14px', md: '16px' }}
          m={1}
        />
        <IconButton
          icon={<Box as="span">H2</Box>}
          aria-label="Heading2"
          onClick={() => formatText('heading2')}
          backgroundColor={colorMode === 'light' ? '#fff' : '#E0EAE0'}
          color={colorMode === 'light' ? '#000' : '#345430'}
          fontFamily="Inter"
          fontSize={{ base: '14px', md: '16px' }}
          m={1}
        />
        <IconButton
          icon={<Box as="span">H3</Box>}
          aria-label="Heading3"
          onClick={() => formatText('heading3')}
          backgroundColor={colorMode === 'light' ? '#fff' : '#E0EAE0'}
          color={colorMode === 'light' ? '#000' : '#345430'}
          fontFamily="Inter"
          fontSize={{ base: '14px', md: '16px' }}
          m={1}
        />
        <IconButton
          icon={<Box as="span">H4</Box>}
          aria-label="Heading4"
          onClick={() => formatText('heading4')}
          backgroundColor={colorMode === 'light' ? '#fff' : '#E0EAE0'}
          color={colorMode === 'light' ? '#000' : '#345430'}
          fontFamily="Inter"
          fontSize={{ base: '14px', md: '16px' }}
          m={1}
        />
        <IconButton
          icon={<Box as="span">H5</Box>}
          aria-label="Heading5"
          onClick={() => formatText('heading5')}
          backgroundColor={colorMode === 'light' ? '#fff' : '#E0EAE0'}
          color={colorMode === 'light' ? '#000' : '#345430'}
          fontFamily="Inter"
          fontSize={{ base: '14px', md: '16px' }}
          m={1}
        />
        <IconButton
          icon={<Box as="span">H6</Box>}
          aria-label="Heading6"
          onClick={() => formatText('heading6')}
          backgroundColor={colorMode === 'light' ? '#fff' : '#E0EAE0'}
          color={colorMode === 'light' ? '#000' : '#345430'}
          fontFamily="Inter"
          fontSize={{ base: '14px', md: '16px' }}
          m={1}
        />
        </Box>
        <Box
        display="flex"
        // justifyContent={{ base: 'center' }}
        flexWrap="wrap"

      >
             <Button
              onClick={() => fileInputRef.current?.click()}
              mr={2}
            >
              Upload Image
            </Button>
            <Input
              type="file"
              ref={fileInputRef}
              onChange={handleFileUpload}
              style={{ display: 'none' }}
              accept="image/*"
              multiple
            />
            <Button
              onClick={() => pdfInputRef.current?.click()}
              mr={2}
            >
              Upload PDF
            </Button>
            <Input
              type="file"
              ref={pdfInputRef} // Added this line
              onChange={handlePdfUpload}
              style={{ display: 'none' }}
              accept="application/pdf"
              multiple
            />
          </Box>
        </Box>

        {loading && <Spinner mt={4} />}

        <Box mt={4}>
        <Button 
                  type="submit" 
                   isLoading={loading || isSubmitting}
                   loadingText="Posting..."
                   backgroundColor={colorMode === 'light' ? "#345430" : "white"}
                   color={colorMode === 'light' ? 'white' : '#345430'}
                   size="lg"
                   width={'full'}
                   disabled={!isValid}
                   marginTop={'10px'}
                    mt={4}>Create Article</Button>
        </Box>

        <Box 
        mt={4}
        whiteSpace="pre-wrap"
        fontFamily="Inter"
        >
          <FormLabel>Preview:</FormLabel>
          {previewContent}
        </Box>
      </form>

      {showToast && (
                <ToastNotification
                  description={toastConfig.description}
                  status={toastConfig.status}
                  onClose={() => setShowToast(false)}
                />
              )}
    </Box>
  );
};

export default ArticleForm;