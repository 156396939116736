import { createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
// import { Post } from "../types/interface";

export type UserPost = {
  _id: string;
  username: string;
  name: string;
};

export interface Post {
  id: string;
  _id: string;
  likes: string;
  dislikes: string;
  comments: { user: UserPost; comment: string; }[];
  images: string[];
  name: string;
  otherNames: string[];
  uses: string;
  description: string;
  type: string;
  verify: boolean;
  lang: string;
  user: UserPost;
  createdAt: string;
}

interface PostsState extends EntityState<Post, string> {
    ids: string[];
    entities: Record<string, Post>;
  }


export const postsAdapter = createEntityAdapter<Post>({
  sortComparer: (a, b) => Number(a?._id) - Number(b?._id),
});
  
const initialState: PostsState = postsAdapter.getInitialState({
  ids: [],
  entities: {},
});

const postSlice = createSlice({
  name: "posts",
  initialState,
  reducers: {
    addPost: postsAdapter.addOne,
    addPosts: postsAdapter.addMany,
  },
});

export const { addPost, addPosts } = postSlice.actions;

export const loadPosts = () => async (dispatch: any) => {
  // dispatch(addPosts(posts));
};

// Update the type of state.posts
// export const selectPosts = (state: RootState) => postsAdapter.getSelectors().selectAll(state.posts) as Posts[];

export default postSlice.reducer;
