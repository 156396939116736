// UserProfileSettingsHook.ts
import { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useGetUserProfileQuery, useUpdateUserProfileMutation, useChangeRoleMutation, useUpdateUsernameMutation } from '../../api/userApi';
import { useNavigate } from "react-router-dom";
import { updateUser } from '../../slice/userSlice';
import { useDispatch } from 'react-redux';
import { User } from '../../types/interface';
import { Update } from '@reduxjs/toolkit';

const useUserProfileSettings = (userId: string) => {
  const { data, isLoading, isError } = useGetUserProfileQuery(userId);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: "",
    status: "",
  });
  
  const [updateUserProfile] = useUpdateUserProfileMutation();
  const [changeRole] = useChangeRoleMutation(); 
  const [updateUsername] = useUpdateUsernameMutation();


  const { handleSubmit, control, formState: { errors, isValid, isSubmitting }, register, trigger, getValues, setValue } = useForm<User>({
    defaultValues: { // Provide default values for your form fields here
      name: '',
      username: '',
      about: '',
      phoneNumber: '',
      field: '',
      role: ""
    },
  });

  useEffect(() => {
    if (data) {
      setValue('name', data.name);
      setValue('username', data.username);
      setValue('about', data.about || '');
      setValue('phoneNumber', data.phoneNumber || '');
      setValue('field', data.field || '');
      setValue('role', data.role || '');
    }
  }, [data, setValue]);

  const onSubmit: SubmitHandler<User> = async (formData) => {
    console.log("Form Data: ", formData);

    const body = {
      name: formData.name,
      username: formData.username,
      phoneNumber: formData.phoneNumber,
      field: formData.field,
      about: formData.about,
    };

    try {
      // First, update the general profile data (excluding role)
      const updatedProfile = await updateUserProfile({ userId, profileData: body });

      if ('data' in updatedProfile) {
        const responseData = updatedProfile.data;

        const user: Update<User, string> = {
          id: responseData.profile?.user,
          changes: {
            about: responseData?.profile?.about,
            field: responseData.profile?.field || formData.field,
            username: formData.username,
            phoneNumber: formData.phoneNumber,
            name: formData.name,
          },
        };

        // Dispatch update to Redux store
        dispatch(updateUser(user));

        // If role has changed, make a separate API call to update the role
        if (data?.role !== formData.role) {
          await handleRoleChange(formData.role);
        }

        // If username has changed, make a separate API call to update the username
        if (data?.username !== formData.username) {
          await handleUsernameChange(formData.username);
        }

        setToastConfig({ description: "Profile updated successfully", status: 'success' });
        setShowToast(true);
        navigate(`/user/${userId}`);
        
      } else if ('error' in updatedProfile) {
        const errorMessage = updatedProfile.error || 'Error updating profile';
        setToastConfig({ description: 'Error updating profile', status: 'error' });
        setShowToast(true);
      }
      
    } catch (error: any) {
      setToastConfig({ description: error.message, status: 'error' });
      setShowToast(true);
    }
  };

  // Separate function for handling role change
  const handleRoleChange = async (role: string) => {
    try {
      const response = await changeRole({ userId, role }).unwrap();
      setToastConfig({ description: "Role updated successfully", status: 'success' });
      setShowToast(true);
      dispatch(updateUser({ id: userId, changes: { role } }));
    } catch (error: any) {
      setToastConfig({ description: error.message || "Failed to update role", status: 'error' });
      setShowToast(true);
    }
  };


  // Function for handling username change
  const handleUsernameChange = async (username: string) => {
    try {
      const response = await updateUsername({ userId, body: { username } }).unwrap();
      setToastConfig({ description: "Username updated successfully", status: 'success' });
      setShowToast(true);
      dispatch(updateUser({ id: userId, changes: { username } }));
    } catch (error: any) {
      setToastConfig({ description: error.message || "Failed to update username", status: 'error' });
      setShowToast(true);
    }
  };



  return {
    data,
    isLoading,
    isError,
    handleSubmit,
    control,
    errors,
    isValid,
    isSubmitting,
    register,
    trigger,
    getValues,
    onSubmit,
    showToast,
    toastConfig,
    setToastConfig,
    setShowToast
  };
};

export default useUserProfileSettings