import React, { useEffect, useState } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useColorMode,
  Button,
} from '@chakra-ui/react';
import {
  BiTrash,
  BiBookmark,
} from 'react-icons/bi';
import { CiMenuKebab } from 'react-icons/ci';
import { selectCurrentUser } from '../../slice/userSlice';
import { User } from '../../types/interface';
import { useSelector } from 'react-redux';
import ToastNotification from '../ToastNotification';
import { useBookmarkArticleMutation, useDeleteBookmarkArticleMutation, useDeleteArticlesMutation } from '../../api/articles';

const ArticleMenu: React.FC<{ articleId: any; ownerId: string }> = ({ articleId, ownerId }) => {
  const { colorMode } = useColorMode();
  const currentUser = useSelector(selectCurrentUser) as User;
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [deleteBookmark] = useDeleteBookmarkArticleMutation(); 
  const [bookmarkAPost] = useBookmarkArticleMutation(); 
  const [deletePost] = useDeleteArticlesMutation(); 
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: "",
    status: "",
  });

  useEffect(() => {
    const savedBookmarks = localStorage.getItem('bookmarkedArticles');
    if (savedBookmarks) {
      const parsedBookmarks = JSON.parse(savedBookmarks);
      setIsBookmarked(parsedBookmarks[articleId] || false);
    }
  }, [articleId]);

  const handleDeletePost = (articleId: any) => {
    deletePost({ userId: currentUser.id, articleId })
      .then((response: any) => {
        if (!response.error && response.data) {
          setShowToast(true); 
          setToastConfig({ description: "Article deleted successfully", status: "success" }); 
        } else {
          setShowToast(true);
          setToastConfig({ description: "Error deleting Article", status: "error" });
        }
      })
      .catch(() => {
        setShowToast(true); 
        setToastConfig({ description: "Error deleting Article", status: "error" });
      });
  };

  const handleDeleteBookmark = (articleId: any) => {
    deleteBookmark({ userId: currentUser.id, articleId })
      .then((response: any) => {
        if ("data" in response) {
          setShowToast(true); 
          setToastConfig({ description: "Bookmark deleted successfully", status: "success" }); 
          updateBookmarkedStatus(articleId, false); 
        } else {
          setShowToast(true); 
          setToastConfig({ description: "Error deleting bookmark", status: "error" }); 
        }
      })
      .catch(() => {
        setShowToast(true); 
        setToastConfig({ description: "Error deleting bookmark", status: "error" }); 
      });
  };

  const handleBookmark = (articleId: any) => {
    bookmarkAPost({ userId: currentUser.id, articleId })
      .then((response: any) => {
        if ("data" in response) {
          setShowToast(true); 
          setToastConfig({ description: "Bookmark successful", status: "success" }); 
          updateBookmarkedStatus(articleId, true);
        } else {
          setShowToast(true); 
          setToastConfig({ description: "Error bookmarking post", status: "error" }); 
        }
      })
      .catch(() => {
        setShowToast(true); 
        setToastConfig({ description: "Error bookmarking post", status: "error" }); 
      });
  };

  const updateBookmarkedStatus = (articleId: any, isBookmarked: boolean) => {
    const savedBookmarks = localStorage.getItem('bookmarkedArticles');
    const updatedBookmarks = savedBookmarks ? JSON.parse(savedBookmarks) : {};
    updatedBookmarks[articleId] = isBookmarked;
    localStorage.setItem('bookmarkedArticles', JSON.stringify(updatedBookmarks));
    setIsBookmarked(isBookmarked);
  };

  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<CiMenuKebab fontSize={20} color={colorMode === 'light' ? '#345430' : 'white'} />}
        variant="ghost"
      />
      <MenuList bg={colorMode === 'light' ? '#345430' : 'white'}>
        {currentUser.id === ownerId && (
          <MenuItem>
            <Button
              leftIcon={<BiTrash fontSize={20} color="#ff0000" />}
              variant="ghost"
              onClick={() => handleDeletePost(articleId)}
              color={colorMode === 'light' ? '#345430' : '#345430'}
            >
              Delete Article
            </Button>
          </MenuItem>
        )}
        <MenuItem>
          {!isBookmarked ? (
            <Button
              leftIcon={<BiBookmark fontSize={20} color={colorMode === 'light' ? '#fff' : '#345430'} />}
              variant="ghost"
              onClick={() => handleBookmark(articleId)}
              color={colorMode === 'light' ? '#345430' : '#345430'}
            >
              Bookmark Article
            </Button>
          ) : (
            <Button
              leftIcon={<BiTrash fontSize={20} color="#ff0000" />}
              variant="ghost"
              onClick={() => handleDeleteBookmark(articleId)}
              color={colorMode === 'light' ?  '#345430' : '#345430'}
            >
              Remove Bookmark
            </Button>
          )}
        </MenuItem>
      </MenuList>
      {showToast && (
        <ToastNotification
          description={toastConfig.description}
          status={toastConfig.status}
          onClose={() => setShowToast(false)}
        />
      )}
    </Menu>
  );
};

export default ArticleMenu;
