import React from "react";
import { Box, Select } from "@chakra-ui/react";
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../slice/languageSlice';
import { RootState } from "../types/interface";

interface LanguageButtonProps {
  closeMenu: () => void; // Define closeMenu as a function prop
}

const languages = [
  { code: 'en', label: 'English' },
  { code: 'yo', label: 'Yoruba' },
  { code: 'es', label: 'Spanish' },
  // Add more languages as needed
];

const LanguageButton: React.FC<LanguageButtonProps> = ({ closeMenu }) => {
  const language = useSelector((state: RootState) => state.language.language);
  const dispatch = useDispatch();

  const changeLanguage = (newLanguage: string) => {
    dispatch(setLanguage(newLanguage));
    closeMenu();
  };

  return (
    <Box>
      <Select
        value={language}
        onChange={(e) => changeLanguage(e.target.value)}
        fontSize="20px"
        fontFamily="Inter"
        borderRadius="10px"
        backgroundColor="white"
        color="gray.800"
        borderColor="gray.300"
        _hover={{
          borderColor: "gray.400",
        }}
        _focus={{
          borderColor: "gray.500",
          boxShadow: "0 0 0 1px #4299e1",
        }}
        _active={{
          backgroundColor: "gray.100",
        }}
      >
        {languages.map((lang) => (
          <option key={lang.code} value={lang.code}>{lang.label}</option>
        ))}
      </Select>
    </Box>
  );
};

export default LanguageButton;

