import React from 'react';
import { Box, Skeleton } from '@chakra-ui/react';

const Loading = () => {
  return (
    <Box>
      {[1, 2, 3].map((item) => (
        <Box
          key={item}
          p={4}
          borderWidth="1px"
          borderRadius="lg"
          mb={4}
          padding={10}
          display="flex"
          flexDirection={{ base: 'column', sm: 'row' }}
          gap={10}
        >
          <Skeleton height="200px" width="30%" mr={4} />
          <Box flexBasis="70%">
            <Skeleton height="30px" mb={2} />
            <Skeleton height="20px" mb={2} />
            <Skeleton height="20px" mb={2} />
            <Skeleton height="20px" width="50%" />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Loading;
