import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Box, Image, Text, useColorMode } from '@chakra-ui/react';
import nature from '../assets/pantanmo/IMG_2622.jpeg';
import nature1 from '../assets/natureBeauty/IMG_2622.jpeg';
import nature2 from '../assets/natureBeauty/IMG_3048.jpeg';
import nature3 from '../assets/natureBeauty/IMG_3061.jpeg';
import nature4 from '../assets/natureBeauty/IMG_3062.jpeg';
import nature5 from '../assets/natureBeauty/IMG_3063.jpeg';
import nature6 from '../assets/natureBeauty/IMG_3064.jpeg';
import nature7 from '../assets/natureBeauty/9e1a1b7b-76eb-4575-b2e2-845194b86208.jpeg';
import nature8 from '../assets/natureBeauty/IMG_0033.jpeg';
import nature9 from '../assets/natureBeauty/IMG_0036.jpeg';
import nature10 from '../assets/natureBeauty/IMG_0122.jpeg';
import nature11 from '../assets/natureBeauty/IMG_0125.jpeg';
import nature12 from '../assets/natureBeauty/IMG_0169.jpeg';
import nature13 from '../assets/natureBeauty/IMG_0172.jpeg';
import nature14 from '../assets/natureBeauty/IMG_0173.jpeg';
import nature15 from '../assets/natureBeauty/IMG_0180.jpeg';
import nature16 from '../assets/natureBeauty/IMG_5483.jpeg';
import nature21 from '../assets/natureBeauty/IMG_5484.jpeg';
import nature22 from '../assets/natureBeauty/IMG_5485.jpeg';
import nature23 from '../assets/natureBeauty/IMG_5481.jpeg';
import nature24 from '../assets/natureBeauty/IMG_5480.jpeg';
import nature17 from '../assets/natureBeauty/IMG_0273.jpeg';
import nature18 from '../assets/natureBeauty/IMG_0274.jpeg';
import nature19 from '../assets/natureBeauty/IMG_0288.jpeg';
import nature20 from '../assets/natureBeauty/IMG_0291.jpeg';
import sampleImage12 from '../assets/ShopExplorer/IMG_0101.jpg';
import sampleImage13 from '../assets/ShopExplorer/aa2e3947-0b52-4de8-bc0c-c80bfdff6667.jpg';
import sampleImage14 from '../assets/ShopExplorer/36622f47-82d0-481c-9065-eb5a8b3e4649 2.jpg';
import sampleImage15 from '../assets/ShopExplorer/234cf05f-14a8-40a2-acb7-350ccd8ea80e.jpg';
import sampleImage16 from '../assets/ShopExplorer/47af3725-7b08-4318-a097-e108eac192ea.jpg';
import sampleImage17 from '../assets/ShopExplorer/38c46968-599b-49fb-b85c-8272409a3693.jpg';
import sampleImage18 from '../assets/ShopExplorer/33c5b6a8-f4f0-4871-a64c-14846c371dcc.jpg';
import sampleImage19 from '../assets/ShopExplorer/8bbf1678-3af3-4104-b310-30dcf74f5a09 2.jpg';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { useInView } from 'react-intersection-observer';

const NatureExplorer = () => {
    const language = useSelector((state: RootState) => state.language.language); 
    const { colorMode } = useColorMode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const photoUrls = [
        nature, 
        nature1, 
        nature2, 
        nature3, 
        nature4, 
        nature5, 
        nature6,
        nature10,
        nature11,
        nature12,
        nature13,
        nature15,
        nature16,
        nature14,
        nature17,
        nature18,
        nature19,
        nature20,
        nature7,
        nature8,
        nature9,
        nature21,
        nature22,
        nature23,
        nature24,
        sampleImage12,
        sampleImage13,
        sampleImage14,
        sampleImage15,
        sampleImage16,
        sampleImage17,
        sampleImage18,
        sampleImage19,];
        const [currentImageIndex, setCurrentImageIndex] = useState(0);

        const [ref, inView] = useInView({
          triggerOnce: true,
        });
      
        useEffect(() => {
          const intervalId = setInterval(() => {
            if (inView) {
              const randomIndex = Math.floor(Math.random() * photoUrls.length);
              setCurrentImageIndex(randomIndex);
            }
          }, 3000);
      
          return () => clearInterval(intervalId);
        }, [inView, photoUrls]);

    
    return (
      <Box textAlign="center" p="4">
        <Text 
          fontSize="25px"
          fontWeight="700"
          color={colorMode === 'light' ? '#000' :  'white'} 
          fontFamily='Inter'
          textAlign="center"
          padding='10px'
          m={5}
          >
          {language === 'en' ? 'Natures Exploration From Different Places In Yoruba Land' : language === 'yo' ? 'Isé ìwádìí sí inú àwọn ohun àbáláyé orísirísi ní Orílè èdè Yorùbá' : 'Exploración de la naturaleza desde diferentes lugares en la tierra Yoruba'}
        </Text>
        <Box display="flex" justifyContent="center">
          <motion.div
            key={currentImageIndex}
            // whileHover={{ scale: 1.1 }}
            // whileTap={{ scale: 0.9 }}
            transition={{ duration: 5 }}
          >
             <div ref={ref}>
            {inView && (
              <Image
                src={photoUrls[currentImageIndex]}
                alt={`Nature Photo ${currentImageIndex + 1}`}
                objectFit="cover"
                boxSize={{ base: '100%', md: '80%', lg: '100%' }}
                height={{ base: '500px', md: '400px', lg: '500px' }}
                width={'100vw'}
                borderRadius="md"
              />
            )}
          </div>
          </motion.div>
        </Box>
      </Box>
    );
}

export default NatureExplorer
