import { motion } from "framer-motion";
import { Heading, useColorMode, Box, Button, Flex, Image, Text, Skeleton } from "@chakra-ui/react";
import SwipeableViews from 'react-swipeable-views';
import { useEffect, useState } from "react";
import { CiSquareChevLeft, CiSquareChevRight } from "react-icons/ci";
import { useSelector } from "react-redux";
import { Articles, RootState } from "../types/interface";
import { useNavigate, useParams } from "react-router-dom";
import { useInView } from 'react-intersection-observer';
import { useLazyGetArticlesQuery } from "../api/articles";
import ArticlePreview from "./Articles/ArticlePreview";
import { Link } from "react-router-dom";
import extractFirstImage from "./ExtractedFirstImage";

interface DidYouKnowProps {}


interface PaginationProps {
  currentIndex: number;
  totalSlides: number;
  onChangeIndex: (index: number) => void;
  colorMode: string
}


type RedirectFunction = () => void;

const slideRenderer = (
  index: number,
  key: React.Key,
  redirectToCreatePost: RedirectFunction,
  article: Articles,
  colorMode: string,
  language: string,
  isAuthenticated: boolean,
) => {
  const firstImage = extractFirstImage(article?.htmlContent);
  return (
    <Flex 
    direction={{ base: 'column', md: 'row' }}
    fontFamily={"Inter"} 
    py={10} 
    key={key}>
      <Box flex="1" fontFamily='Inter'pr={{ base: '0', md: '4' }} lineHeight={6}>
        <Heading 
        textTransform={'uppercase'} 
        fontStyle={'italic'}
        fontSize={{ base: "20px", md: "30px"}} 
        mb={{ base: '6', md: '5' }}>
          {language === "en" ? "Did You Know?" : language === "yo" ? "Ṣé ẹ mọ̀" : "¿Sabías?"}
        </Heading>

        <Heading mb={{ base: '4', md: '5' }} 
        fontSize={{ base: "15px", md: "20px"}} 
        width={{ base: 'full', md: 'auto' }}>
          {article?.title}
        </Heading>
        <Text
                    textAlign="start"
                    paddingBottom="5px"
                    fontFamily="Inter"
                    paddingTop="5px"
                    fontWeight={600}
                    fontSize={16}
                              >
            {isAuthenticated ?  
                            <Link to={`/user/${article?.user?._id}`}>@{article?.user?.username}</Link>
                          : <Link to={'/login'}/>}
                  </Text>

        <ArticlePreview htmlContent={article.htmlContent} />
        <Button
            mt={10}
            padding={{ base: '10px', md: '30px' }}
            backgroundColor={colorMode === 'light' ? '#E0EAE0' :  '#345430'} 
            color={colorMode === 'light' ? '#345430' :  'white'} 
            variant="solid"
            borderRadius={"10px"}
            size="md"
            fontFamily='Inter'
            cursor={'pointer'}
            mb={10}
            fontSize={16}
            onClick={redirectToCreatePost}
          >
            {language === 'en' ? 'Learn More' : language === 'yo' ? 'Kọ̀ si ìsọ̀dẹ̀' : 'Saber más'}
          </Button>

      </Box>

      <Box flex="1" pl={{ base: '0', md: '4' }}>
      <Image
          src={firstImage || undefined} // Provide a fallback value of undefined if firstImage is null
          alt={`Article ${index + 1}`}
          style={{
            width: "500px",
            height: "500px",
            backgroundSize: "cover",
            borderRadius: "20px",
          }}
          objectFit={'cover'}
        />
      </Box>
    </Flex>
  );
};


const Pagination: React.FC<PaginationProps> = ({ currentIndex, totalSlides, onChangeIndex, colorMode }) => {
  const handlePrev = () => {
    onChangeIndex((currentIndex - 1 + totalSlides) % totalSlides);
  };

  const handleNext = () => {
    onChangeIndex((currentIndex + 1) % totalSlides);
  };

  return (
    <Flex align="center"
    justify="center">
      <Button onClick={handlePrev} mr={2} 
      leftIcon={
      <CiSquareChevLeft 
      fontSize={16}
      color={colorMode === 'light' ? '#fff' :  '#345430'}  />
    } 
      backgroundColor={colorMode === 'light' ? '#E0EAE0' :  '#345430'} 
      color={colorMode === 'light' ? '#345430' :  'white'} 
      variant="solid"
      borderRadius={"10px"}
      size="md"
      fontFamily='Inter'
      cursor={'pointer'}
      fontSize={16}
      >
        Prev
      </Button>
      <Text
        fontFamily='Inter'
        p={4}
        >{currentIndex + 1} of {totalSlides}</Text>
      <Button 
      onClick={handleNext} 
      ml={2} 
      rightIcon={<CiSquareChevRight 
         color={colorMode === 'light' ? '#E0EAE0' :  '#345430'}
         fontSize={16} />} 
      backgroundColor={colorMode === 'light' ? '#E0EAE0' :  '#345430'} 
      color={colorMode === 'light' ? '#345430' :  'white'} 
      variant="outline"
      borderRadius={"10px"}
      size="md"
      fontFamily='Inter'
      cursor={'pointer'}
      fontSize={16}
      >
        Next
      </Button>
    </Flex>
  );
};

const DidYouKnow: React.FC<DidYouKnowProps> = () => {
  const { userId } = useParams();
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const language = useSelector((state: RootState) => state.language.language) as string;
  const [inViewRef, inView] = useInView({ triggerOnce: true });
  const [trigger, { data, isLoading }] = useLazyGetArticlesQuery({});
  const [articles, setArticles] = useState<Articles[]>([]);
  const isAuthenticated = useSelector((state: RootState) => state.users.isLogin);

  useEffect(() => {
    trigger({}, true); // Prefer cached value if available
  }, [trigger]);


  useEffect(() => {
    if (data) {
      const articles = data?.data?.articles;
      setArticles(articles);
    }
  }, [data]);
  useEffect(() => {
    if (articles && articles.length > 0) {
      setActiveStep(0); // Reset index when articles data changes
    }
  }, [articles]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStep((prevStep: any) => (prevStep + 1) % articles.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [articles.length]);

  const handleChangeIndex = (index: number) => {
    if (index >= 0 && index < (articles?.length || 0)) {
    setActiveStep(index);
    }
  };

  const redirectToCreatePost: RedirectFunction = () => {
    if (articles.length > 0 && articles[activeStep]) {
      const createPostUrl = `/user/${userId}/articles/${articles[activeStep]._id}`;
      navigate(createPostUrl);
    } else {
      console.error("No articles available or activeStep out of range.");
    }
  };


  return (
    <motion.div
      ref={inViewRef}
      initial={{ opacity: 0 }}
       animate={inView ? { opacity: 1 } : { opacity: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
      style={{
        backgroundColor: colorMode === 'light' ? '#fff' : '#E0EAE0',
        color: colorMode === 'light' ? '#000' : '#345430',
        display: 'flex',
        flexDirection: 'column', // Adjusted for better responsiveness
        alignItems: 'center', // Center content vertically
        padding: '20px',
        borderRadius: '20px',
        fontFamily: 'Inter',
        margin: '20px', // Updated margin
      }}
    >
      <Box flex="1" width="100%" pr={{ base: 0, md: 4 }}>
      <div>
          {isLoading ? (
            <Skeleton height="100%" width="100%" />
          ) : (
            <SwipeableViews index={activeStep} onChangeIndex={handleChangeIndex} enableMouseEvents>
              {articles.map((article, index) => (
             <Box key={index} width="100%">
             {slideRenderer(index, index, redirectToCreatePost, article, colorMode, language, isAuthenticated)}
           </Box>           
              ))}
            </SwipeableViews>
          )}
        </div>
        <Pagination currentIndex={activeStep} totalSlides={articles.length} colorMode={colorMode} onChangeIndex={handleChangeIndex} />
      </Box>
    </motion.div>
  );
};

export default DidYouKnow;
