import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Text,
  Container,
  useColorMode,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { UserData } from "../types/interface";
import { Helmet } from "react-helmet";
import ToastNotification from "../components/ToastNotification";
import { motion } from "framer-motion";
import { AutoBackButton, FormInputConfirmPassword, FormInputPassword } from "../components";
import AuthCall from '../api/auth';
import { useParams } from "react-router";

const ResetPassword: React.FC = () => {
  const { control, handleSubmit, reset,
    formState: { errors, isValid, isSubmitting }, register, getValues, trigger } =
  useForm<UserData>({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({ description: "", status: "" });
  const { tokenId, userId } = useParams();
  const { colorMode } = useColorMode();
  const { useValidateResetTokenQuery, useChangePasswordMutation } = AuthCall();
  const { data: tokenValidationData, error: tokenValidationError } = useValidateResetTokenQuery({ tokenId, userId });
  
  const [showTokenErrorModal, setShowTokenErrorModal] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);

  useEffect(() => {
    if (tokenValidationData) {
      setIsTokenValid(true);
    } else if (tokenValidationError) {
      setShowTokenErrorModal(true);
    }
  }, [tokenValidationData, tokenValidationError]);

  const [changePassword] = useChangePasswordMutation();

  const resetPassword: SubmitHandler<UserData> = async (data) => {
    if (data.password !== data.confirmPassword) {
      setToastConfig({ description: "Passwords do not match", status: "error" });
      setShowToast(true);
      return;
    }

    try {
      const response = await changePassword({
        tokenId: tokenId,
        password: data.password
      });

      if ("data" in response) {
        setToastConfig({ description: "Password reset successfully!", status: "success" });
        setShowToast(true);
      } else if ('error' in response) {
        const errorMessage = response.error as { data?: { message?: string } };
        if (errorMessage.data && errorMessage.data.message) {
          setToastConfig({ description: errorMessage.data.message, status: "error" });
          setShowToast(true);
        }
      }
      reset();
    } catch (error) {
      console.error("Error resetting password:", error);
      setToastConfig({ description: "Error resetting password", status: "error" });
      setShowToast(true);
    }
  };

  return (
    <Container
      display={'flex'}
      justifyContent="center"
      height={'100vh'}
      alignItems="center"
      paddingBottom={20}>
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }}
      >
        <Box p="14" bg={colorMode === 'light' ? '#E0EAE0' : '#588552'} width={'100%'} m="14"  borderRadius={"20px"} minH="auto">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Reset Password Page</title>
          </Helmet>
          <Text textAlign="center" fontSize="xl" fontWeight="bold">
            Reset Password
          </Text>
          <AutoBackButton />
          {showTokenErrorModal && (
            <Modal
              isOpen={showTokenErrorModal}
              onClose={() => setShowTokenErrorModal(false)}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Error</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  Invalid or expired link. Please try again.
                </ModalBody>
                <ModalFooter>
                  <Button onClick={() => setShowTokenErrorModal(false)}>
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}
          {isTokenValid ? (
            <form onSubmit={handleSubmit(resetPassword)}>
              <FormInputPassword
                name="password"
                label="New Password"
                control={control}
                register={register}
                rules={{
                  required: 'Password is required',
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.!@#$%^&*])[A-Za-z\d.!@#$%^&*]{8,20}$/,
                    message: 'Password must have a capital letter, a small letter, a number, and a symbol (e.g., .!@#$%^&*)',
                  },
                  minLength: {
                    value: 8,
                    message: 'Password must be at least 8 characters',
                  },
                  maxLength: {
                    value: 20,
                    message: 'Password must be less than 20 characters',
                  },
                }}
                errors={errors}
                onKeyUp={() => {
                  trigger("password");
                }}
              />

              <FormInputConfirmPassword
                name="confirmPassword"
                label="Confirm Password"
                control={control}
                register={register}
                rules={{
                  required: 'Please confirm your password',
                  validate: (value: any) => value === getValues('password') || 'Passwords do not match',
                }}
                errors={errors}
                onKeyUp={() => {
                  trigger("confirmPassword");
                }}
              />
              <Button
                isLoading={isSubmitting}
                type='submit'
                loadingText="Creating a new password..."
                backgroundColor={colorMode === 'light' ? '#345430' : "white"}
                color={colorMode === 'light' ? "white" : '#345430'}
                size="lg"
                disabled={!isValid}
                marginTop={'10px'}
              >
                Reset Password
              </Button>
            </form>
          ) : (
            <Text textAlign="center" color="red.500">
              Validating token...
            </Text>
          )}
          {showToast && (
            <ToastNotification
              description={toastConfig.description}
              status={toastConfig.status as any}
              onClose={() => setShowToast(false)}
            />
          )}
        </Box>
      </motion.div>
    </Container>
  );
};

export default ResetPassword;
