import React, { useState, ChangeEvent } from "react";
import { Select, useColorMode } from "@chakra-ui/react";

interface SortComponentProps {
  onSortChange: (selectedSort: string) => void;
}

const SortComponent: React.FC<SortComponentProps> = ({ onSortChange }) => {
  const [selectedSort, setSelectedSort] = useState<string>("");

    const { colorMode } = useColorMode();
  const handleSortChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setSelectedSort(value);
    onSortChange(value);
  };

  return (
    <Select
      placeholder="Sort by..."
      value={selectedSort}
      onChange={handleSortChange}
         backgroundColor={colorMode === 'light' ? '#E0EAE0' :  '#588552'}
         borderColor={"#345430"}
         fontFamily="Inter"
         borderRadius={10}
         fontSize="20px"
         _focus={{ borderColor: colorMode === 'light' ? '#345430' :  'white' }}
         width={"100%"}
         height={"60px"}
    >
      <option value="All">All</option>
      <option value="A-Z">A-Z</option>
      <option value="Latest">Latest</option>
      {/* <option value="Popular">Popular</option> */}
      <option value={'verified'}>Verified</option>
      <option value={'unVerified'}>Unverified</option>
    </Select>
  );
};

export default SortComponent;