// SegmentSelector.tsx
import React from 'react';
import { VStack, Text, Box } from '@chakra-ui/react';

  
export interface UserInfoProps {
    selectedSegment: any;
    handleSegmentChange: any;
    colorMode: any;
  }

const SegmentSelector: React.FC<UserInfoProps> = ({ selectedSegment, handleSegmentChange, colorMode }) => (
  <VStack display="flex" flexDirection="row" justifyContent="center" gap={10} p={10}>
    {['Posts', 'Articles', ].map((segment) => (
      <Text
        key={segment}
        onClick={() => handleSegmentChange(segment)}
        color={colorMode === 'light' ? '#345430' : '#fff'}
        position="relative"
      >
        {segment}
        {selectedSegment === segment && (
          <Box
            position="absolute"
            bottom="-10px"
            left="8%"
            transform="translateX(-50%)"
            h="5px"
            w="5px"
            bg={colorMode === 'light' ? '#345430' : '#fff'}
            borderRadius="50%"
          />
        )}
      </Text>
    ))}
  </VStack>
);

export default SegmentSelector;
