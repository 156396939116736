import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const TRANSLATION_API_URL = process.env.REACT_APP_TRANSLATION_API_URL;

export const translationApiSlice = createApi({
    reducerPath: 'translationApi',
    baseQuery: fetchBaseQuery({
        baseUrl: TRANSLATION_API_URL,
        prepareHeaders: (headers) => {
            headers.set('Content-Type', 'application/json');
            headers.set('X-RapidAPI-Key', `${process.env.REACT_APP_X_RapidAPI_Key}`);
            headers.set('X-RapidAPI-Host', 'google-translator9.p.rapidapi.com');
        },
    }),
    tagTypes: ['Translation'],
    endpoints: (builder) => ({
        translateText: builder.query({
            query: ({ text, source, target }) => ({
                url: '',
                method: 'POST',
                body: { q: text, source, target, format: 'text' },
            }),
            providesTags: ['Translation'],
        }),
    }),
});

export const { useTranslateTextQuery, useLazyTranslateTextQuery } = translationApiSlice;

