import { createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { Store } from "../types/interface";

interface StoresState extends EntityState<Store, string> {
    ids: string[];
    entities: Record<string, Store>;
  }
  
export const storesAdapter = createEntityAdapter<Store>({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const initialState: StoresState = storesAdapter.getInitialState({
  ids: [],
  entities: {},
});

const storeSlice = createSlice({
  name: "stores",
  initialState,
  reducers: {
    addStore: storesAdapter.addOne,
    addStores: storesAdapter.addMany,
  },
});

export const { addStore, addStores } = storeSlice.actions;

export const loadStores = () => async (dispatch: any) => {
  // Assume we fetch data from an API or other source and dispatch addStores action
  const data = [
    {
      "id": "67058453a9f3ec8c8570c320",
      "_id": "67058453a9f3ec8c8570c320",
      "description": "A store for herbal mixtures",
      "name": "Ashabi's Store",
      "deactivated": true,
      "tokenAvailable": 5,
      "category": ["Oils", "Spices", "Herbs"],
      "createdAt": "2024-10-08T19:13:23.420Z",
    },
  ];
  dispatch(addStores(data));
};

// Update the type of state.stores
// export const selectStores = (state: RootState) => storesAdapter.getSelectors().selectAll(state.stores) as Store[];

export default storeSlice.reducer;
