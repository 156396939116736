import React, { useState, useEffect, useCallback } from "react";
import { Button, Menu, MenuButton, MenuList, MenuItem, Box } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { setLanguage } from "../../slice/languageSlice";
import { Question, RootState } from "../../types/interface";
import { useLazyTranslateTextQuery } from "../../api/translation";
import { FaChevronDown } from "react-icons/fa";

interface QuestionTranslationToggleProps {
  questionId: string;
  question: string;
  setTranslatedQuestion: (question: Partial<Question> | null) => void;
  questionLang: string;
}

const languages = [
  { code: "en", label: "English" },
  { code: "yo", label: "Yoruba" },
  { code: "es", label: "Spanish" },
  // Add more languages as needed
];

const QuestionTranslationToggle: React.FC<QuestionTranslationToggleProps> = ({
  questionId,
  question,
  setTranslatedQuestion,
  questionLang,
}) => {
  const [showTranslation, setShowTranslation] = useState(false);
  const language = useSelector((state: RootState) => state.language.language);
  const dispatch = useDispatch();

  const [triggerQuestionTranslation, { data: questionData, error: questionError }] = useLazyTranslateTextQuery();

  const updateTranslation = useCallback(() => {
    if (questionData?.data && questionData.data.translations.length > 0) {
      setTranslatedQuestion({
        question: questionData.data.translations[0].translatedText,
      });
    }
  }, [questionData, setTranslatedQuestion]);

  useEffect(() => {
    if (showTranslation) {
      triggerQuestionTranslation({ text: question, source: questionLang, target: language });
    }
  }, [showTranslation, triggerQuestionTranslation, question, language, questionLang]);

  useEffect(() => {
    updateTranslation();
    if (questionError) console.error("Question Translation Error:", questionError);
  }, [questionData, questionError, updateTranslation]);

  const toggleTranslation = () => {
    setShowTranslation((prevShowTranslation) => !prevShowTranslation);
    setTranslatedQuestion(null); // Reset translated question when toggling
  };

  const handleLanguageChange = (newLanguage: string) => {
    dispatch(setLanguage(newLanguage));
    setShowTranslation(true); // Trigger translation after language change
  };

  return (
    <Box display={"flex"} justifyContent={"start"} alignItems={"center"} gap={5}>
      <Menu>
        <MenuButton as={Button} rightIcon={<FaChevronDown />} fontSize={12} mt={2} mb={2}>
          {showTranslation ? "Change Language" : "Translate"}
        </MenuButton>
        <MenuList>
          {languages.map((lang) => (
            <MenuItem key={lang.code} onClick={() => handleLanguageChange(lang.code)}>
              {lang.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <Button variant="outline" fontSize={12} colorScheme="#345430" onClick={toggleTranslation} mt={2} mb={2}>
        {showTranslation ? "View Original" : "View Translation"}
      </Button>
    </Box>
  );
};

export default QuestionTranslationToggle;
