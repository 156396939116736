import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { Box, Image, Text, IconButton, Button, useColorMode } from '@chakra-ui/react';
import SwipeableViews from 'react-swipeable-views';
import { useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { RootState } from '../store/store';
import { HiArrowLeft, HiArrowRight } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import sampleImage1 from '../assets/ShopExplorer/IMG_8944 2.jpg';
import sampleImage2 from '../assets/ShopExplorer/e8d37259-47c9-4b85-b8b4-86559a987dc4.jpg';
import sampleImage3 from '../assets/ShopExplorer/794a7ddd-f7ba-4081-b3c5-ccdec476ba11.jpg';
import sampleImage4 from '../assets/ShopExplorer/918d9ddc-2fa7-40e2-a0cb-b20b060eac02 2.jpg';
import sampleImage5 from '../assets/ShopExplorer/2518668b-8ef3-4df6-a824-0f70a6158874 2.jpg';
import sampleImage6 from '../assets/ShopExplorer/2518668b-8ef3-4df6-a824-0f70a6158874 2.jpg';
import sampleImage7 from '../assets/ShopExplorer/b06c89df-26b1-4352-9dd5-982ef1a9b8b9.jpg';
import sampleImage8 from '../assets/ShopExplorer/IMG_8945.jpg';
import sampleImage9 from '../assets/ShopExplorer/IMG_8946.jpg';
import sampleImage10 from '../assets/ShopExplorer/IMG_8947.jpg';
import sampleImage11 from '../assets/ShopExplorer/IMG_9615.jpg';
import sampleImage12 from '../assets/ShopExplorer/IMG_9617.jpg';
import sampleImage13 from '../assets/ShopExplorer/WhatsApp Image 2024-08-05 at 16.28.46.jpeg';
import sampleImage14 from '../assets/ShopExplorer/WhatsApp Image 2024-08-05 at 16.28.58.jpeg';
import sampleImage15 from '../assets/ShopExplorer/WhatsApp Image 2024-08-05 at 16.29.03.jpeg';
import sampleImage16 from '../assets/ShopExplorer/WhatsApp Image 2024-08-05 at 16.29.04.jpeg';
import sampleImage17 from '../assets/ShopExplorer/WhatsApp Image 2024-08-05 at 16.29.18.jpeg';
import sampleImage18 from '../assets/ShopExplorer/WhatsApp Image 2024-08-05 at 16.29.21 (1).jpeg';
import sampleImage19 from '../assets/ShopExplorer/WhatsApp Image 2024-08-05 at 16.29.21.jpeg';
import sampleImage20 from '../assets/ShopExplorer/WhatsApp Image 2024-08-05 at 16.29.29.jpeg';
import sampleImage21 from '../assets/ShopExplorer/WhatsApp Image 2024-08-05 at 16.29.33.jpeg';
import sampleImage22 from '../assets/ShopExplorer/WhatsApp Image 2024-08-05 at 16.29.36.jpeg';
import sampleImage23 from '../assets/ShopExplorer/WhatsApp Image 2024-08-05 at 16.29.46.jpeg';
import sampleImage24 from '../assets/ShopExplorer/WhatsApp Image 2024-08-05 at 16.29.52.jpeg';
import sampleImage25 from '../assets/ShopExplorer/WhatsApp Image 2024-08-05 at 16.29.54.jpeg';
import sampleImage26 from '../assets/ShopExplorer/WhatsApp Image 2024-08-05 at 16.29.56.jpeg';
import sampleImage27 from '../assets/ShopExplorer/WhatsApp Image 2024-08-05 at 16.30.06.jpeg';
import sampleImage28 from '../assets/ShopExplorer/WhatsApp Image 2024-08-05 at 16.30.08.jpeg';
import sampleImage29 from '../assets/ShopExplorer/WhatsApp Image 2024-08-05 at 16.30.16.jpeg';
import sampleImage30 from '../assets/ShopExplorer/WhatsApp Image 2024-08-05 at 16.30.18.jpeg';
import sampleImage31 from '../assets/ShopExplorer/IMG_2538.jpg';
// import sampleImage32 from '../assets/ShopExplorer/IMG_2540.jpg';
import sampleImage33 from '../assets/ShopExplorer/IMG_2542.jpg';
import sampleImage34 from '../assets/ShopExplorer/IMG_2548.jpg';
import sampleImage35 from '../assets/ShopExplorer/IMG_2556.jpg';
import sampleImage36 from '../assets/ShopExplorer/IMG_2558.jpg';
import sampleImage37 from '../assets/ShopExplorer/f4efaca3-38ed-4de6-bccc-3b99596ac276.jpg';
import sampleImage38 from '../assets/ShopExplorer/e8ea323c-159d-4de2-b66f-657a1ad7fa88.jpg';
import sampleImage39 from '../assets/ShopExplorer/e8ac5355-8192-4ae1-89c3-40ad43ffd14c.jpg';
import sampleImage40 from '../assets/ShopExplorer/ca056083-7872-458c-ae69-beec01a09a63.jpg';
import sampleImage41 from '../assets/ShopExplorer/220cda1a-3493-4ecd-9fac-7ddf9571dffe.jpg';
import sampleImage42 from '../assets/ShopExplorer/60d5d12f-9814-4781-9f80-812cccc4129c.jpg';


const ShopExplorer = () => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const language = useSelector((state: RootState) => state.language.language);
    const { colorMode } = useColorMode();
    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const mediaUrls = [
        { type: 'image', src: sampleImage1 },
        { type: 'image', src: sampleImage2 },
        { type: 'image', src: sampleImage3 },
        { type: 'image', src: sampleImage4 },
        { type: 'image', src: sampleImage5 },
        { type: 'image', src: sampleImage6 },
        { type: 'image', src: sampleImage7 },
        { type: 'image', src: sampleImage8 },
        { type: 'image', src: sampleImage9 },
        { type: 'image', src: sampleImage10 },
        { type: 'image', src: sampleImage11 },
        { type: 'image', src: sampleImage12 },
        { type: 'image', src: sampleImage13 },
        { type: 'image', src: sampleImage14 },
        { type: 'image', src: sampleImage15 },
        { type: 'image', src: sampleImage16 },        
        { type: 'image', src: sampleImage22 },
        { type: 'image', src: sampleImage17 },
        { type: 'image', src: sampleImage18 },
        { type: 'image', src: sampleImage19 },
        { type: 'image', src: sampleImage20 },
        { type: 'image', src: sampleImage21 },
        { type: 'image', src: sampleImage22 },
        { type: 'image', src: sampleImage23 },
        { type: 'image', src: sampleImage24 },
        { type: 'image', src: sampleImage25 },
        { type: 'image', src: sampleImage26 },
        { type: 'image', src: sampleImage27 },
        { type: 'image', src: sampleImage28 },
        { type: 'image', src: sampleImage29 },
        { type: 'image', src: sampleImage30 },
        { type: 'image', src: sampleImage31 },
        // { type: 'image', src: sampleImage32 },
        { type: 'image', src: sampleImage33 },
        { type: 'image', src: sampleImage34 },
        { type: 'image', src: sampleImage35 },
        { type: 'image', src: sampleImage36 },        
        { type: 'image', src: sampleImage37 },        
        { type: 'image', src: sampleImage38 },        
        { type: 'image', src: sampleImage39 },
        { type: 'image', src: sampleImage40 },
        { type: 'image', src: sampleImage41 },
        { type: 'image', src: sampleImage42 },
    ];

    const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
    const [ref, inView] = useInView({ triggerOnce: true });

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        const setNextSlide = () => {
            setCurrentMediaIndex((prevIndex) => (prevIndex + 1) % mediaUrls.length);
        };

        const setSlideDuration = () => {
            if (mediaUrls[currentMediaIndex].type === 'video' && videoRef.current) {
                if (videoRef.current.readyState >= 1) {
                    const videoDuration = videoRef.current.duration;
                    intervalId = setInterval(setNextSlide, (videoDuration || 5) * 1000);
                } else {
                    videoRef.current.onloadedmetadata = () => {
                        const videoDuration = videoRef.current?.duration;
                        intervalId = setInterval(setNextSlide, (videoDuration || 5) * 1000);
                    };
                }
            } else {
                intervalId = setInterval(setNextSlide, 5000);
            }
        };

        if (inView) {
            setSlideDuration();
        }

        return () => clearInterval(intervalId);
    }, [inView, currentMediaIndex, mediaUrls]);

    const handlePrev = () => {
        setCurrentMediaIndex((prevIndex) => (prevIndex - 1 + mediaUrls.length) % mediaUrls.length);
    };

    const handleNext = () => {
        setCurrentMediaIndex((prevIndex) => (prevIndex + 1) % mediaUrls.length);
    };

    const handleShopNow = () => {
        navigate('/shop');
    };

    return (
        <Box textAlign="center" p="4">
            <Text 
                fontSize={{ base: '20px', md: '25px' }}
                fontWeight="700"
                color={colorMode === 'light' ? '#345430' : 'white'} 
                fontFamily='Inter'
                textAlign="center"
                padding='10px'
                m={5}
            >
                {language === 'en' ? 'Shop Explorer' : language === 'yo' ? 'E ba wa ra oja' : 'Explorador de Tiendas'}
            </Text>
            <Box display="flex" justifyContent="center" alignItems="center" position="relative">
                <IconButton
                    aria-label="Previous"
                    icon={<HiArrowLeft />}
                    onClick={handlePrev}
                    position="absolute"
                    left="10px"
                    top="50%"
                    transform="translateY(-50%)"
                    zIndex="1"
                    backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#345430'}
                    color={colorMode === 'light' ? '#345430' : 'white'}
                    _hover={{ backgroundColor: colorMode === 'light' ? '#d0dad0' : '#2c4d30' }}
                />
                <motion.div
                    key={currentMediaIndex}
                    transition={{ duration: 1 }}
                    style={{ width: '100%' }}
                >
                    <div ref={ref}>
                        {inView && (
                            <SwipeableViews index={currentMediaIndex} onChangeIndex={setCurrentMediaIndex} enableMouseEvents>
                                {mediaUrls.map((media, index) => (
                                          <Box 
                                            key={index}
                                            height={{ base: '500px', md: '400px', lg: '700px' }}
                                            display="flex" 
                                            justifyContent="center"
                                            alignItems="center"
                                            width="100%"
                                            overflow="hidden"
                                        >
                                            {media.type === 'image' ? (
                                                <Image
                                                    src={media.src}
                                                    alt={`Shop Media ${index + 1}`}
                                                    objectFit="cover"
                                                    width="100%"
                                                    height="100%"
                                                    borderRadius="10px"
                                                />
                                            ) : (
                                                <video
                                                    ref={videoRef}
                                                    src={media?.src}
                                                    controls
                                                    autoPlay
                                                    muted
                                                    style={{ 
                                                        width: '100%', 
                                                        height: 'auto', 
                                                        borderRadius: '10px',
                                                    }}
                                                />
                                            )}
                                        </Box>
                                ))}
                            </SwipeableViews>
                        )}
                    </div>
                </motion.div>
                <IconButton
                    aria-label="Next"
                    icon={<HiArrowRight />}
                    onClick={handleNext}
                    position="absolute"
                    right="10px"
                    top="50%"
                    transform="translateY(-50%)"
                    zIndex="1"
                    backgroundColor={colorMode === 'light' ? '#E0EAE0' : '#345430'}
                    color={colorMode === 'light' ? '#345430' : 'white'}
                    _hover={{ backgroundColor: colorMode === 'light' ? '#d0dad0' : '#2c4d30' }}
                />
            </Box>
            <Button
                mt={2}
                backgroundColor={colorMode === 'light' ? '#E0EAE0' : 'white'}
                color={colorMode === 'light' ? '#345430' : '#345430'}
                onClick={handleShopNow}
                fontSize={{ base: '14px', md: '16px' }}
                px={{ base: '10px', md: '20px' }}
                py={{ base: '6px', md: '8px' }}
            >
                {language === 'en' ? 'Shop Now' : language === 'yo' ? 'E ba wa ra oja' : 'Compra Ahora'}
            </Button>
        </Box>
    );
}

export default ShopExplorer;
