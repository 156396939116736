import { useEffect, useState } from 'react';
import { Box, Flex, Image, Text, Skeleton, useColorMode, Button } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Answer, Question, User } from '../types/interface';
import { useGetQuestionByIdQuery } from '../api/questions'; 
import { AutoBackButton, ShareButton, formatCreatedAtDate } from '../components';
import Answers from '../components/Answers/Answers'; 
import QuestionTranslationToggle from '../components/Questions/QuestionTranslationToggle';
import { BsFillShareFill } from 'react-icons/bs';
import { selectCurrentUser, selectIsAuthenticated } from '../slice/userSlice';
import QuestionMenu from '../components/Questions/QuestionMenu';

const QuestionById: React.FC = () => {
  const { questionId } = useParams<{ questionId?: string }>();
  const { colorMode } = useColorMode();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { userId } = useParams();
  const currentUser = useSelector(selectCurrentUser) as unknown as User;
  const validUserId = userId || currentUser?.id;

  const { data: questionData, isLoading: isLoadingQuestion, isError: isErrorQuestion } = useGetQuestionByIdQuery(questionId, {
    refetchOnMountOrArgChange: false,
  });

  const [question, setQuestion] = useState<Question | null>(null);
  const [translatedQuestion, setTranslatedQuestion] = useState<Partial<Question> | null>(null);

  useEffect(() => {
    if (questionData && questionData.data) {
      setQuestion(questionData.data);
    }
  }, [questionData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updatedAnswersData = (questionId: string, updatedAnswers: Answer[]) => {
    setQuestion(prevQuestion =>
      prevQuestion && prevQuestion._id === questionId
        ? { ...prevQuestion, answers: updatedAnswers }
        : prevQuestion
    );
  };

  // Reset translated question when question changes
  useEffect(() => {
    setTranslatedQuestion(null); 
  }, [question])    

  const toggleShareModal = () => {
    setIsShareModalOpen(prev => !prev);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeIn" }}
      style={{
        backgroundColor: colorMode === 'light' ? '#E0EAE0' : '#588552',
        color: colorMode === 'light' ? "white" : '#345430'
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{question?.question || 'Question'} page</title>
      </Helmet>

      {isLoadingQuestion && !isErrorQuestion && (
        <Box textAlign="center" p={10}>
          <Skeleton height="500px" borderRadius={20} color={"transparent"} />
        </Box>
      )}
      {!isLoadingQuestion && isErrorQuestion && (
        <div>Error occurred while fetching data.</div>
      )}
      {!isLoadingQuestion && question && (
        <Box
          paddingTop={20}
          pb={10}
          marginRight={{ base: "15", md: "20" }}
          marginLeft={{ base: "15", md: "20" }}
        >
          <AutoBackButton />
          <Flex justifyContent="center" color={colorMode === 'light' ? '#345430' : 'white'}
            alignItems="start" flexDirection={"column"} paddingBottom="20px">
            <Box
              key={question._id}
              width={{ base: "100%" }}
              mx="auto"
              mb="4"
              p="5"
              borderRadius="0"
              bg="transparent"
              border="none"
              flexDirection={{ base: "column", md: "row" }}
            >
              <Flex justify="space-between" alignItems="center">
                <Box display={'block'} alignItems={'start'}>
                  <Text
                    textAlign="start"
                    paddingBottom="5px"
                    fontFamily="Inter"
                    paddingTop="5px"
                    fontWeight={600}
                    fontSize={{ base: "16px", md: "18px", lg: "20px" }}
                  >
                    {isAuthenticated ? (
                      <Link to={`/user/${question.user?._id}`}>
                        @{question.user?.username}
                      </Link>
                    ) : <Link to={'/login'} />}
                  </Text>
                  <Text
                    paddingBottom="5px"
                    textAlign="start"
                    fontFamily="Inter"
                    fontWeight={600}
                    fontSize={{ base: "12px", md: "16px", lg: "18px" }}
                  >
                    {formatCreatedAtDate(question.createdAt)}
                  </Text>
                </Box>
                {isAuthenticated && question.user && (
                  <QuestionMenu questionId={question._id} userId={question.user._id || ''} />
                )}
              </Flex>

              {question.images && question.images.length > 0 && (
                <Box mt={2} display="flex" flexWrap="wrap">
                  {question.images.map((image: string, imageIndex: number) => (
                    <Image
                      key={imageIndex}
                      src={image}
                      alt={question.question}
                      borderRadius="10px"
                      style={{
                        marginRight: '8px',
                        marginBottom: '8px',
                        maxWidth: '100%',
                        flex: '1 0 300px',
                      }}
                      width="500px"
                      height="500px"
                      objectFit="cover"
                    />
                  ))}
                </Box>
              )}

              <Box flex="1" textAlign="start" fontFamily="Inter">
                <Text
                  textAlign="start"
                  paddingBottom="10px"
                  fontFamily="Inter"
                  paddingTop="10px"
                  fontSize={{ base: "20px", md: "20px", lg: "25px" }}
                  textTransform="capitalize"
                  display={"flex"}
                  justifyContent={"start"}
                  alignItems={"center"}
                  gap={"20px"}
                  mb={4}
                  className="no-select"
                >
                  {translatedQuestion ? translatedQuestion.question : question.question}
                </Text>
              </Box>
            </Box>
          </Flex>

          <Box mt={4} >
            <QuestionTranslationToggle
              questionId={question._id}
              question={question.question}
              setTranslatedQuestion={setTranslatedQuestion}
              questionLang={question.lang ?? 'en'}
            />
          </Box>

          <Box
            textAlign={'start'}
            mt={5}
            mb={5}
            flex={['1', '1', 'auto']}
            position="relative"
            display="flex"
            justifyContent={'flex-start'}
          >
            <Button
              variant="ghost"
              color={colorMode === 'light' ? 'white' :  '#345430'}
              backgroundColor={colorMode === 'light' ? '#345430' :  'white'}
              leftIcon={<BsFillShareFill fontSize={20} color={colorMode === 'light' ? 'white' :  '#345430'} />}
              onClick={toggleShareModal}
            >
              Share
            </Button>
            {isShareModalOpen && <ShareButton 
              postUrl={`https://www.ewe.com.ng/user/${validUserId}/posts/${question._id}`} 
              onClose={toggleShareModal}
            />}
          </Box>

          <Answers questionId={question._id} answers={question.answers} updatedAnswersData={updatedAnswersData} />
        </Box>
      )}
    </motion.div>
  );
};

export default QuestionById;
