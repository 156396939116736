import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  language: 'yo',
  translatedText: '', // Add a field to store the translated text
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setTranslatedText: (state, action) => {
      state.translatedText = action.payload;
    },
  },
});

export const { setLanguage, setTranslatedText } = languageSlice.actions;

export default languageSlice.reducer;
