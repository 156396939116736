import { Button, Box, Flex, Icon } from '@chakra-ui/react';
import { FaTwitter, FaFacebook, FaWhatsapp, FaLink } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import React from 'react';
import ToastNotification from './ToastNotification'; // Ensure this is correctly imported

interface ShareButtonProps {
  productUrl: string;
  onClose: () => void;
}

type Platform = 'Twitter' | 'Facebook' | 'WhatsApp';

const ShopShareButton: React.FC<ShareButtonProps> = ({ productUrl, onClose }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: "",
    status: "",
  });

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => setShowToast(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [showToast]);

  const sharePost = (platform: Platform) => {
    const encodedUrl = encodeURIComponent(productUrl);
    const urlMap: Record<Platform, string> = {
      Twitter: `https://twitter.com/intent/tweet?url=${encodedUrl}&hashtags=Ewé,YorubaPlants`,
      Facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
      WhatsApp: /Android|iPhone|iPad|iPod/i.test(navigator.userAgent)
        ? `whatsapp://send?text=${encodedUrl}`
        : `https://web.whatsapp.com/send?text=${encodedUrl}`,
    };
    window.open(urlMap[platform], '_blank');
  };

  const copyLink = () => {
    navigator.clipboard.writeText(productUrl)
      .then(() => {
        setToastConfig({ description: 'Link copied to clipboard', status: 'success' });
        setShowToast(true);
        onClose();
      })
      .catch(() => {
        setToastConfig({ description: 'Failed to copy link', status: 'error' });
        setShowToast(true);
      });
  };

  return (
    <Box
      position="absolute"
      top="100%"
      zIndex="999"
      backgroundColor="white"
      border="1px solid #ccc"
      borderRadius="4px"
      boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
    >
      <Flex flexDirection="column" p="2">
        <Button
          variant="ghost"
          color="#345430"
          leftIcon={<Icon as={FaTwitter} />}
          onClick={() => sharePost('Twitter')}
        >
          Twitter
        </Button>
        <Button
          variant="ghost"
          color="#345430"
          leftIcon={<Icon as={FaFacebook} />}
          onClick={() => sharePost('Facebook')}
        >
          Facebook
        </Button>
        <Button
          variant="ghost"
          color="#345430"
          leftIcon={<Icon as={FaWhatsapp} />}
          onClick={() => sharePost('WhatsApp')}
        >
          WhatsApp
        </Button>
        <Button
          variant="ghost"
          color="#345430"
          leftIcon={<Icon as={FaLink} />}
          onClick={copyLink}
        >
          Copy Link
        </Button>
      </Flex>
      {showToast && (
        <ToastNotification
          description={toastConfig.description}
          status={toastConfig.status}
          onClose={() => setShowToast(false)}
        />
      )}
    </Box>
  );
};

export default ShopShareButton;
