import React, { useState, useEffect } from 'react';
import { Box, Button, Text, useColorMode, Textarea} from '@chakra-ui/react';
// import { useCommentOnPostMutation } from '../../api/postApi';
import { useSelector } from 'react-redux';
import { selectAccessToken, selectCurrentUser, selectIsAuthenticated } from '../../slice/userSlice';
import { useParams } from 'react-router-dom';
import { Post, User } from '../../types/interface';
// import FormInput from '../Form/FormInput';
import { SubmitHandler, useForm } from 'react-hook-form';
import ToastNotification from '../ToastNotification';
import axios from 'axios';
import { Link } from 'react-router-dom';

interface CommentComponentProps {
  post?: Post;
  updatedPostData: any;
}

interface CommentFormData {
  comment: string;
}

const Comment: React.FC<CommentComponentProps> = ({ post, updatedPostData }) => {
  const [comments, setComments] = useState<{ user: any; username: string; comment: string }[]>([]);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const currentUser = useSelector(selectCurrentUser) as User;
  const { userId } = useParams();
  const validUserId = userId || currentUser?.id;
  // const [commentMutation] = useCommentOnPostMutation();
  const { colorMode } = useColorMode();
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: "",
    status: "",
  });
  const accessToken = useSelector(selectAccessToken); 
  // console.log("Comments: ", comments); 

  const {  register, handleSubmit, formState: { errors, isSubmitting, isValid }, reset } = useForm<CommentFormData>({
    criteriaMode: 'all',
  });

const onSubmit: SubmitHandler<CommentFormData> = async (data) => {
  if (!isAuthenticated || !post?._id || data.comment.trim() === '') return;

  const newCommentData = { user: currentUser, username: currentUser.username, comment: data.comment };

  const comment = {
    comment: data.comment
  };

  try {
    const response = await axios.patch(
      `https://ewe-api.herokuapp.com/api/users/${validUserId}/posts/${post._id}/comment`,
      comment,
      {
        headers: {
          'Content-Type': 'application/json', // Change content type to application/json
          'Authorization': `Bearer ${accessToken}`, 
          'Access-Control-Allow-Origin': '*',
        },
      }
    );
    // Handle success response
    const responseData = response.data;
    const message = responseData.message || 'Comment added successfully';

    const updatedComments = [...comments, newCommentData];
    setComments(updatedComments);

    const updatedPost = { ...post, comments: (comments.length + 1).toString() };
    updatedPostData(post._id, updatedPost);
    setToastConfig({
      description: message,
      status: 'success',
    });
    setShowToast(true);
    reset();
    window.location.reload(); 
  } catch (error) {
    // Handle error
    setToastConfig({
      description: `Failed to add comment`,
      status: 'error',
    });
    setShowToast(true);
  }
};

  useEffect(() => {
    // Fetch existing comments for the post and set them in the state
    if (post && post.comments) {
      const transformedComments = post.comments.map(comment => ({
        user: comment.user,
        username: comment.user.username,
        comment: comment.comment
      }));
      setComments(transformedComments);
    }
  }, [post]);
  

  return (
    <Box>
      {comments.map((comment, index) => (
        <Box key={index} mt={5}
        marginRight={{ base: "15" }}
        marginLeft={{ base: "15" }}>
           {isAuthenticated ?  
                  <Link to={`/user/${comment?.user?._id}`}>
                    <Text fontSize={'16px'} fontWeight="bold"
                    color={colorMode === 'light' ? '#345430' : "white"}>@{comment?.user?.username}</Text> 
                    </Link>
                : <Link to={'/login'}/>}
          <Text fontSize={'16px'} 
           color={colorMode === 'light' ? '#345430' : "white"}>{comment.comment}</Text>
        </Box>
      ))}
      <Box mt={4}>
        {isAuthenticated && (
          <form onSubmit={handleSubmit(onSubmit)}>
           <Textarea
              {...register("comment", {
                required: 'Comment is required',
                maxLength: { value: 200, message: 'Maximum character limit exceeded' }
              })}
              placeholder="Add a comment..."
              resize="vertical"
              h={300}
              color={colorMode === 'light' ? '#345430' : 'white'}
            />
            {errors.comment && (
              <Text color="red" fontSize="sm">{errors.comment.message}</Text>
            )}
            <Button
              type="submit"
              isLoading={isSubmitting}
              loadingText="Adding Comments in..."
              backgroundColor={colorMode === 'light' ? '#345430' : "white"}
              color={colorMode === 'light' ? "white" : '#345430'}
              size="lg"
              width={'full'}
              disabled={!isValid}
              mt={2}
            >
              Submit
            </Button>
          </form>
        )}
      </Box>
      {showToast && (
              <ToastNotification
                description={toastConfig.description}
                status={toastConfig.status}
                onClose={() => setShowToast(false)}
              />
            )}
    </Box>
  );
};

export default Comment;
