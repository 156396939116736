import React, { useState } from 'react';
import {
  Box,
  Button,
  VStack,
  useColorMode,
  Text,
} from '@chakra-ui/react';
import {
  BiLike,
} from 'react-icons/bi';
import { FaComment } from 'react-icons/fa';
import ShareButton from '../ShareButton';
import { BsFillShareFill } from 'react-icons/bs';
import { useLikeArticleMutation } from '../../api/articles';
import { useParams, useNavigate } from 'react-router-dom';
import { selectCurrentUser, selectIsAuthenticated } from '../../slice/userSlice';
import { Articles, User } from '../../types/interface';
import { useSelector } from 'react-redux';
import ToastNotification from '../ToastNotification';

interface ArticleButtonsProps {
  article?: Articles;
  updatedArticleData: any;
}

const ArticleButtons: React.FC<ArticleButtonsProps> = ({ article, updatedArticleData }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const { colorMode } = useColorMode();
  const { userId } = useParams();
  const currentUser = useSelector(selectCurrentUser) as unknown as User;
  const validUserId = userId || currentUser?.id;
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: "",
    status: "",
  });

  const toggleShareModal = () => {
    setIsShareModalOpen(prev => !prev);
  };

  const [likeArticleMutation] = useLikeArticleMutation();

  const handleLike = () => {
    if (!isAuthenticated) {
      // Redirect to login page if not authenticated
      return navigate("/login");
    }
  
    if (!article?._id) return;

    const currentLikes = article?.likes?.length || 0;
    likeArticleMutation({ userId: validUserId, articleId: article._id })
      .then((response: any) => {
        if ('data' in response) {
          const updatedArticle = { ...article, likes: (currentLikes + 1).toString() };
          updatedArticleData(article._id, updatedArticle);
          // Show success toast
          setToastConfig({ description: 'Article liked successfully', status: 'success' });
          setShowToast(true);
        } else {
          // Show error toast
          setToastConfig({ description: 'Failed to like article', status: 'error' });
          setShowToast(true);
        }
      })
      .catch((error: any) => {
        console.error('Error liking article:', error);
        // Show error toast
        setToastConfig({ description: 'Failed to like article', status: 'error' });
        setShowToast(true);
      });
  };


  const viewComments = () => {
    if (!isAuthenticated) {
      // Redirect to login page if not authenticated
      return navigate("/login");
    }
    
    navigate(`/user/${validUserId}/articles/${article?._id}`);
  };
  
  return (
    <VStack
      display={'flex'}
      flexDirection={'row'}
      paddingTop={['5px', '10px']}
      paddingBottom={['5px', '10px']}
      gap={10}
    >
      <>
        <Button
          variant="ghost"
          color={colorMode === 'light' ? '#345430' :  'white'}
          leftIcon={<BiLike fontSize={20} color={colorMode === 'light' ? '#345430' :  'white'} />}
          onClick={handleLike}
        >
          <Text fontSize={16} mr={2}>{article?.likes?.length}</Text>
        </Button>
        <Button
          variant="ghost"
          color={colorMode === 'light' ? '#345430' :  'white'}
          leftIcon={<FaComment fontSize={20} color={colorMode === 'light' ? '#345430' :  'white'} />}
          onClick={viewComments}
        >
          <Text fontSize={16}   mr={2}>{article?.comments?.length}</Text>
        </Button>
      </>
      <Box
        textAlign={'start'}
        mt={5}
        mb={5}
        flex={['1', '1', 'auto']}
        position="relative"
        display="flex"
        justifyContent={'flex-start'}
      >
        <Button
          variant="ghost"
          color={colorMode === 'light' ? 'white' :  '#345430'}
          backgroundColor={colorMode === 'light' ? '#345430' :  'white'}
          leftIcon={<BsFillShareFill fontSize={20} color={colorMode === 'light' ? 'white' :  '#345430'} />}
          onClick={toggleShareModal}
        >
          Share
        </Button>
        {isShareModalOpen && <ShareButton
                onClose={toggleShareModal}
                 postUrl={`https://www.ewe.com.ng/user/${validUserId}/articles/${article?._id}`} />}
      </Box>
      {showToast && (
        <ToastNotification
          description={toastConfig.description}
          status={toastConfig.status}
          onClose={() => setShowToast(false)}
        />
      )}
    </VStack>
  );
};

export default ArticleButtons;
