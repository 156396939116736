import { useEffect, useState } from 'react';
import { VStack, HStack, Text, useMediaQuery, useColorMode } from '@chakra-ui/react';
import { useGetAllPostsQuery } from '../../api/postApi';
import { useNavigate, useParams } from 'react-router-dom';
import { selectCurrentUser } from '../../slice/userSlice';
import { User } from '../../types/interface';
import { useSelector } from 'react-redux';


interface Post {
  _id: string;
  name: string;
  verify: boolean;
  createdAt: Date; 
}
const FooterBox = () => {
  const [isMobile] = useMediaQuery('(max-width: 600px)');
  const { colorMode } = useColorMode();
  const { data: postsData } = useGetAllPostsQuery({});
  const [latestAdditionPosts, setLatestAdditionPosts] = useState<Post[]>([]);
  const [latestVerifiedAdditions, setLatestVerifiedAdditions] = useState<Post[]>([]);
  const [latestUnverifiedAdditions, setLatestUnverifiedAdditions] = useState<Post[]>([]);
  const [latestAZPosts, setLatestAZPosts] = useState<Post[]>([]);
  const navigate = useNavigate();
  const { userId } = useParams();
  const currentUser = useSelector(selectCurrentUser) as unknown as User
  const validUserId = userId || currentUser?.id;

  useEffect(() => {
    if (postsData) {
      const posts = postsData?.data?.posts;

      const verifiedPosts = posts.filter((post: Post) => post.verify === true);
      const sortedVerifiedPosts = [...verifiedPosts].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      setLatestVerifiedAdditions(sortedVerifiedPosts.slice(0, 5));

      const unverifiedPosts = posts.filter((post: Post) => post.verify === false);
      const sortedUnverifiedPosts = [...unverifiedPosts].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      setLatestUnverifiedAdditions(sortedUnverifiedPosts.slice(0, 5));

      const sortedPosts = [...posts].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      setLatestAdditionPosts(sortedPosts.slice(0, 5));

      const sortedAZPosts = [...posts]
      .filter(post => post.name) // Filter out posts with undefined names
      .sort((a, b) => (a.name || '').localeCompare(b.name || ''));
    setLatestAZPosts(sortedAZPosts.slice(0, 5));
    
    }
  }, [postsData]);

  const handlePostCardClick = (postId: string) => {
    navigate(`/user/${validUserId}/posts/${postId}`);
  };

  if (isMobile) {
    return (
      <VStack
        spacing={4}
        backgroundColor={ colorMode === 'light' ? '#E0EAE0' : '#588552'}
        borderRadius="20px"
        padding="20px"
        fontFamily="Inter"
        alignItems=""
        width={'100%'}
      >
        <HStack spacing={2}>
          <VStack align="start" fontSize={{ base: "16px", md: "20px"}}  spacing={4} flex={1}>
            <Text fontWeight="bold" color={colorMode === 'light' ? '#345430' :  'white'} >
              A-Z
            </Text>
              {latestAZPosts.map(post => (
              <Text key={post._id}
              onClick={() => handlePostCardClick(post._id)} 
               fontSize={'12px'}>{post.name}</Text>
            ))}
          </VStack>
          <VStack align="start" fontSize={{ base: "16px", md: "20px"}}  spacing={4} flex={1}>
            <Text fontWeight="bold" color={colorMode === 'light' ? '#345430' :  'white'} >
              Latest Addition
            </Text>
            {latestAdditionPosts.map(post => (
              <Text key={post._id} 
              onClick={() => handlePostCardClick(post._id)} 
               fontSize={'12px'}>{post?.name}</Text>
            ))}
          </VStack>
        </HStack>
        <HStack spacing={2}>
          <VStack align="start" fontSize={{ base: "16px", md: "20px"}} spacing={4} flex={1}>
            <Text fontWeight="bold"  color={colorMode === 'light' ? '#345430' :  'white'} >
              Verified
            </Text>
            {latestVerifiedAdditions.map(post => (
              <Text key={post._id}
              onClick={() => handlePostCardClick(post._id)} 
              fontSize={'12px'}>{post?.name}</Text>
            ))}
          </VStack>
          <VStack align="start" fontSize={{ base: "16px", md: "20px"}}  spacing={4} flex={1}>
            <Text fontWeight="bold" color={colorMode === 'light' ? '#345430' :  'white'} >
              Unverified
            </Text>
            {latestUnverifiedAdditions.map(post => (
              <Text key={post._id} 
              onClick={() => handlePostCardClick(post._id)} 
              fontSize={'12px'}>{post?.name}</Text>
            ))}
          </VStack>
        </HStack>
      </VStack>
    );
  }

  // Render the original two-column layout for larger screens
  return (
    <HStack
      spacing={6}
      flexDirection="row"
      backgroundColor={ colorMode === 'light' ? '#E0EAE0' : '#588552'}
      borderRadius="20px"
      padding="20px"
      fontFamily="Inter"
      alignItems="center"
      width="100%"
    >
      {/* Left column */}
      <VStack align="start" fontSize={{ base: "16px", md: "20px"}} spacing={6} flex={1}>
        <Text fontWeight="bold" color={colorMode === 'light' ? '#345430' :  'white'} >
        A-Z
        </Text>
        {latestAZPosts.map(post => (
              <Text key={post._id}
              onClick={() => handlePostCardClick(post._id)} 
               fontSize={'12px'}>{post.name}</Text>
            ))}
      </VStack>

      {/* Right column */}
      <VStack align="start" fontSize={{ base: "16px", md: "20px"}}  spacing={6} flex={1}>
            <Text fontWeight="bold" color={colorMode === 'light' ? '#345430' : 'white'} >
            Latest Addition
            </Text>
            {latestAdditionPosts.map(post => (
            <Text key={post._id}
            onClick={() => handlePostCardClick(post._id)} 
             fontSize={'12px'}>{post.name}</Text>
            ))}
            </VStack>  {/* Left column */}
        <VStack align="start" fontSize={{ base: "16px", md: "20px"}}  spacing={6} flex={1}>
          <Text fontWeight="bold" color={colorMode === 'light' ? '#345430' :  'white'} >
          Verified
          </Text>
          {latestVerifiedAdditions.map(post => (
                <Text key={post._id}
                onClick={() => handlePostCardClick(post._id)} 
                 fontSize={'12px'}>{post?.name}</Text>
              ))}
        </VStack>

        {/* Right column */}
        <VStack align="start" fontSize={{ base: "16px", md: "20px"}}  spacing={6} flex={1}>
          <Text fontWeight="bold" color={colorMode === 'light' ? '#345430' :  'white'} >
          Unverified
          </Text>
          {latestUnverifiedAdditions.map(post => (
                <Text key={post._id}   
                onClick={() => handlePostCardClick(post._id)} 
                fontSize={'12px'}>{post?.name}</Text>
              ))}
        </VStack>
      </HStack>
);
};

export default FooterBox;

