import React from 'react';
import { Box, Heading, Text, VStack, useColorMode } from '@chakra-ui/react';

const BankDetails: React.FC = () => {
  return (
    <VStack
      spacing={4}
      mt={8}
      p={4}
      border="1px solid"
      borderColor={useColorMode().colorMode === 'light' ? '#345430' : 'white'}
      borderRadius="md"
      width="100%"
    >
      <Heading size="md">Bank Account Details</Heading>
      <Text>NAME: IFANIWURA LIMITED</Text>
      <Box>
        <Text>ACCOUNT NOS:</Text>
        <Text>0884935813 (NGN)</Text>
        <Text>0884935820 (USD)</Text>
      </Box>
      <Text>GTBANK</Text>
    </VStack>
  );
};

export default BankDetails;
