import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Articles } from "../types/interface";

// Define the entity adapter for Articles
export const articlesAdapter = createEntityAdapter<Articles>({
  // Define a sort comparer to sort Articles by title
  sortComparer: (a, b) => a.title.localeCompare(b.title),
});

// Define the type for the state managed by the slice
interface ArticlesState extends ReturnType<typeof articlesAdapter.getInitialState> {}

// Define the initial state using the entity adapter's initial state
const initialState: ArticlesState = articlesAdapter.getInitialState();

// Create the articles slice using createSlice
export const articleSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    // Define the addArticle reducer to add a single article to the state
    addArticle: (state, action: PayloadAction<Articles>) => {
      articlesAdapter.addOne(state, action.payload);
    },
  },
});

// Export the action creators generated by createSlice
export const { addArticle } = articleSlice.actions;

// Export the reducer function generated by createSlice
export default articleSlice.reducer;
