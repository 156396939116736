import React, { useState } from 'react';
import {
  Box,
  Button,
  VStack,
  Heading,
  Text,
  Collapse,
  useColorMode,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { RootState } from '../../types/interface';

const BankTransfer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const language = useSelector((state: RootState) => state.language.language);
  const { colorMode } = useColorMode();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <VStack spacing={4} align="start" mt={4}
    style={{ backgroundColor:  colorMode === 'light' ? '#E0EAE0' : '#588552', 
    color: colorMode === 'light' ? '#345430' :  'white', padding: "20px", borderRadius: "20px"}}
    >
      <Heading
        fontFamily="Inter"
        as="h4"
        size="xxl"
        textAlign="center"
        fontWeight={600}
      >
        {language === 'en' ? 'Bank Transfer' : language === 'es' ? 'Transferencia Bancaria' : 'Ọna Iparun Iwekọ'}
      </Heading>
      <Text fontSize="16px" letterSpacing="2px" lineHeight="2">
        {language === 'en'
          ? 'Choose the bank transfer option to make a donation via a bank transfer.'
          : language === 'es' ? 'Elige la opción de transferencia bancaria para hacer una donación a través de una transferencia bancaria.' : 'Yọ ìparún ọna iparun iwekọ lati ṣe iṣẹdẹ ayika nipasẹ ọna iparun iwekọ.'}
      </Text>
      <Text fontSize="16px" letterSpacing="2px" lineHeight="2">
        {language === 'en'
          ? 'Follow the instructions below to complete the transfer manually.'
          : language === 'es' ? 'Siga las instrucciones a continuación para completar la transferencia manualmente.' : 'Fọwọsìn awọn iṣẹ lati pari iwekọ nipasẹ ọna iparun iwekọ.'}
      </Text>
      <Text fontSize="16px" letterSpacing="2px" lineHeight="2">
        {language === 'en'
          ? 'Your donation will support our cause, and we appreciate your contribution.'
          : language === 'es' ? 'Su donación apoyará nuestra causa y apreciamos su contribución.' : 'Iṣẹdẹ rẹ yoo sọna iṣẹ wa, ati a ni yoo fẹ̣ràn rẹ pélú iṣẹdẹ rẹ.'}
      </Text>
      <Button
        onClick={handleToggle}
        color={colorMode === 'light' ? 'white' :  '#345430'} 
        bg={colorMode === 'light' ? '#345430' :  'white'}
        size="sm"
      >
        {isOpen ? (language === 'en' ? 'Hide Details' : language === 'es' ? 'Ocultar Detalles' : 'Ṣe Iwọsọdọ Ẹka') : (language === 'en' ? 'Show Details' : language === 'es' ? 'Mostrar Detalles' : 'Fa Wọle Ẹka')}
      </Button>
      <Collapse in={isOpen}>
        <Box
          p={4}
          border="1px solid #345430"
          borderRadius="md"
          mt={4}
          width="100%"
          gap={10}
          fontFamily="Inter"
        >
          <Text 
            fontSize="16px"
            mb={5}
          >
            {language === 'en' ? 'Amount: Your preferred amount' : language === 'es' ? 'Cantidad: La cantidad que prefieras' : 'Owo: ọmọ rẹ ti pọn'},
          </Text>
          <Text fontSize="16px" mb={5}>
            {language === 'en' ? 'Account Number (NGN): 0884935813' : language === 'es' ? 'Número de cuenta (NGN): 0884935813' : 'Number Ileyi fi owo pa mo(NGN): 0884935813 '}
          </Text>
          <Text fontSize="16px" mb={5}>
            {language === 'en' ? 'Account Number (USD): 0884935820' : language === 'es' ? 'Número de cuenta (USD): 0884935820' : 'Nọmba aṣọ (USD): 0884935820 '}
          </Text>
          <Text fontSize="16px" mb={5}>
            {language === 'en' ? 'Account Name:  IFANIWURA LIMITED' : language === 'es' ? 'Nombre de la cuenta: IFANIWURA LIMITED' : ' Oruko Account wa:  IFANIWURA LIMITED'}
          </Text>
          <Text fontSize="16px" mb={5}>
            {language === 'en' ? 'Bank Name: Guranty Trust Bank' : language === 'es' ? 'Nombre del Banco: Guranty Trust Bank' : ' Ileyi fi owo pa mo: Guranty Trust Bank'}
          </Text>
          <Text fontSize="16px" mb={5}>
            {language === 'en' ? "Beneiciary's Bank Swift Code: GTBINGLA" : language === 'es' ? "Beneiciary's Bank Swift Code: GTBINGLA" : "Beneiciary's Bank Swift Code: GTBINGLA" }
          </Text>
          <Text fontSize="16px" mb={5}>
            {language === 'en' ? "Beneiciary's Bank Branch Address: Lekki Peninsula Epe Expy Chevron Branch" : language === 'es' ? "Beneiciary's Bank Branch Address: Lekki Peninsula Epe Expy Chevron Branch" 
            : "Beneiciary's Bank Branch Address: Lekki Peninsula Epe Expy Chevron Branch"}
          </Text>
          <Text fontSize="16px" mb={5}>
            {language === 'en' ? 'For: Ewe' : language === 'es' ? 'Para: Ewe' : 'Fún: Ewe'}
          </Text>
          <Text fontSize="16px" mt={4}>
            {language === 'en' ? 'Description: [Your Donation Description]' : language === 'es' ? 'Descripción: [Su descripción de donación]' : 'Apejuwe: [Ìwọsọdọ Iye rẹ]'}
          </Text>
          <Text fontSize="16px" mt={4}>
            {language === 'en'
              ? 'Proceed to your bank app to complete this transfer.'
              : language === 'es' ? 'Proceda a su aplicación bancaria para completar esta transferencia.' : 'Lọ sile si ọna agbaniro fun rẹ lati ṣe ọna iparun iwekọ yi.'}
          </Text>
        </Box>
      </Collapse>
    </VStack>
  );
};

export default BankTransfer;
