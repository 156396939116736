import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import languageSlice from '../slice/languageSlice';
// import postSlice from '../slice/postSlice';
import usersReducer from '../slice/userSlice';
import { userApiSlice } from '../api/userApi'; // Importing userApiSlice from userApi file
import { translationApiSlice } from '../api/translation'
import reverseGeocodeReducer from '../slice/locationSlice';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist/es/constants';
import { questionsApiSlice } from '../api/questions';
import { storeApiSlice } from '../api/stores';

// Importing slices from api
import ApiCall from '../api/api';
import AuthCall from '../api/auth';
import { postApiSlice } from '../api/postApi';
import { articleApiSlice } from '../api/articles';
import { fileUploadApi } from '../api/file-upload.service';

const { apiSlice } = ApiCall();
const { authSlice } = AuthCall();

// Define persist configuration
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['users', 'language', 'posts'], // Add the slices you want to persist here
};

// Combine reducers
const rootReducer = combineReducers({
  language: languageSlice,
  users: usersReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  [authSlice.reducerPath]: authSlice.reducer,
  [userApiSlice.reducerPath]: userApiSlice.reducer,
  [postApiSlice.reducerPath] : postApiSlice.reducer,
  reverseGeocode: reverseGeocodeReducer,
  [translationApiSlice.reducerPath] : translationApiSlice.reducer,
  [articleApiSlice.reducerPath]: articleApiSlice.reducer, 
  [fileUploadApi.reducerPath]: fileUploadApi.reducer, 
    [questionsApiSlice.reducerPath]: questionsApiSlice.reducer,
    [storeApiSlice.reducerPath] : storeApiSlice.reducer
});

// Wrap rootReducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store with persistedReducer
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware, authSlice.middleware, userApiSlice.middleware,  
      postApiSlice.middleware, translationApiSlice.middleware, articleApiSlice.middleware, 
      fileUploadApi.middleware,
    questionsApiSlice.middleware,
  storeApiSlice.middleware ), // Adding middleware here
    devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;