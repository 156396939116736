import { format, parseISO } from 'date-fns';


const formatCreatedAtDate = (createdAt: string | undefined | null) => {
    if (!createdAt) {
      return ""; // or handle the case as needed
    }
  
    const parsedDate = parseISO(createdAt);
    const dayOfMonth = parsedDate.getDate();
  
    const addSuffix = (day: any) => {
      if (day >= 11 && day <= 13) {
        return `${day}th`;
      }
      switch (day % 10) {
        case 1:
          return `${day}st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    };
  
    const formattedDate = format(parsedDate, `dd MMMM yyyy`);
    const formattedWithSuffix = formattedDate.replace(`${dayOfMonth}`, addSuffix(dayOfMonth));
    return formattedWithSuffix;
  };
  
  export default formatCreatedAtDate;
  