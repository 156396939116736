// SearchResults.tsx
import React from "react";
import { Box, Image, Text, useColorMode } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { SimpleGrid } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useInView } from 'react-intersection-observer';
import extractFirstImage from '../ExtractedFirstImage'; // Import the helper function
import { SearchResult, User } from "../../types/interface";

interface SearchResultsProps {
  results: SearchResult[];
  loading: boolean;
  currentUser: User;
}

const SearchResults: React.FC<SearchResultsProps> = ({ results, loading, currentUser }) => {
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const handleCardClick = (result: SearchResult) => {
    if (result.type === "post") {
      navigate(`/user/${currentUser.id}/posts/${result._id}`);
    } else if (result.type === "article") {
      navigate(`/user/${currentUser.id}/articles/${result._id}`);
    }
  };
  // console.log("results: ", results);
  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0 }}
      animate={inView ? { opacity: 1 } : { opacity: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeIn" }}
    >
      {loading ? (
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3 }}
          gap={4}
          style={{
            background: colorMode === "light" ? "#E0EAE0" : "#588552",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          {[...Array(6)].map((_, index) => (
            <Box
              key={index}
              p={4}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Box
                bg="#f0f0f0"
                w="100%"
                h="500px"
                borderRadius="10px"
              />
              <Box
                bg="#f0f0f0"
                w="100%"
                h="40px"
                mt={4}
                borderRadius="10px"
              />
            </Box>
          ))}
        </SimpleGrid>
      ) : results.length === 0 ? (
        <Text textAlign="center" fontSize="24px" marginTop="20px">
          No search results found.
        </Text>
      ) : (
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3 }}
          gap={4}
          style={{
            background: colorMode === "light" ? "#E0EAE0" : "#588552",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          {results.map((result) => {
            if (result?.type === 'post') {              
              return (
                <Box
                  key={result?._id}
                  p={4}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  onClick={() => handleCardClick(result)}
                >
                  <Image
                    src={result?.images && result.images.length > 0 ? result?.images[0] : ""}
                    alt={result?.name || "Unnamed Post"}
                    objectFit="cover"
                    width="500px"
                    height="500px"
                    borderRadius="10px"
                  />
                  <Text
                    textAlign="start"
                    paddingBottom="10px"
                    fontFamily="Inter"
                    paddingTop="10px"
                    fontSize={{ base: "20px", md: "20px", lg: "25px" }}
                    textTransform="capitalize"
                    display="flex"
                    justifyContent="start"
                    alignItems="center"
                    gap={4}
                    mb={4}
                    ml={4}
                  >
                    {result?.name}
                  </Text>
                </Box>
              );
            }
             else if (result?.type === "article") {
              const firstImageUrl = extractFirstImage(result?.htmlContent!) || ""; // Non-null assertion operator !
              return (
                <Box
                  key={result?._id}
                  p={4}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  onClick={() => handleCardClick(result)}
                >
                  <Image
                    src={firstImageUrl}
                    alt={result?.title || "Untitled Article"}
                    objectFit="cover"
                    width="500px"
                    height="500px"
                    borderRadius="10px"
                  />
                  <Text
                    textAlign="start"
                    paddingBottom="10px"
                    fontFamily="Inter"
                    paddingTop="10px"
                    fontSize={{ base: "20px", md: "20px", lg: "25px" }}
                    textTransform="capitalize"
                    display="flex"
                    justifyContent="start"
                    alignItems="center"
                    gap={4}
                    mb={4}
                    ml={4}
                  >
                    {result?.title || "Untitled Article"}
                  </Text>
                </Box>
              );
            }
            return null;
}
)}
        </SimpleGrid>
      )}
    </motion.div>
  );
};

export default SearchResults;
