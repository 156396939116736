import React from 'react';
import {
  VStack,
  Heading,
  Text,
  Button,
  useColorMode,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { RootState } from '../../types/interface';

const GoFundMe = () => {
  const language = useSelector((state: RootState) => state.language.language);
  const { colorMode } = useColorMode();

  const goFundMeLink = 'https://gofund.me/3b1197d0';

  return (
    <VStack
      spacing={4}
      align="start"
      mt={4}
      style={{
        backgroundColor: colorMode === 'light' ? '#E0EAE0' : '#588552',
        color: colorMode === 'light' ? '#345430' : 'white',
        padding: '20px',
        borderRadius: '20px',
      }}
    >
      <Heading
        fontFamily="Inter"
        as="h4"
        size="xxl"
        textAlign="center"
        fontWeight={600}
      >
        {language === 'en' ? 'GoFundMe' : language === 'es' ? 'GoFundMe' : 'GoFundMe'}
      </Heading>
      <Text fontSize="16px" letterSpacing="2px" lineHeight="2">
        {language === 'en'
          ? 'Support our cause by donating through GoFundMe.'
          : language === 'es'
          ? 'Apoye nuestra causa donando a través de GoFundMe.'
          : 'Ṣe atilẹyin iṣẹ wa nipa fifunni nipasẹ GoFundMe.'}
      </Text>
      <Button
        onClick={() => window.open(goFundMeLink, '_blank')}
        color={colorMode === 'light' ? 'white' : '#345430'}
        bg={colorMode === 'light' ? '#345430' : 'white'}
        size="sm"
      >
        {language === 'en' ? 'Donate Now' : language === 'es' ? 'Donar Ahora' : 'Fẹ Ṣẹ Ẹ Da Ẹwẹ'}
      </Button>
    </VStack>
  );
};

export default GoFundMe;
