import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { selectAccessToken } from '../slice/userSlice';
import { RootState } from '../types/interface';

const USER_PROFILE_BASE_URL = process.env.REACT_APP_BASE_URL;
export const userApiSlice = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: USER_PROFILE_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState | undefined;
      const accessToken = state ? selectAccessToken(state) : '';
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      headers.set('Content-Type', 'application/json');
      headers.set('Access-Control-Allow-Origin', '*');
    },
  }),
  tagTypes: ['UserProfile', "Posts", "Users"],
  endpoints: (builder) => ({
    getUserProfile: builder.query({
      query: (userId) => ({
        url: `users/${userId}`,
        providesTags: ['UserProfile'],
      })
    }),
    updateUserProfile: builder.mutation({
      query: ({ userId, profileData }) => ({
        url: `users/${userId}/profile`,
        method: 'PATCH',
        body: profileData,
      }),
      invalidatesTags: ['UserProfile'],
    }),
    createPost: builder.mutation({
      query: ({ userId, body }) => ({
        url: `users/${userId}/posts`,
        method: 'POST',
        body: body,
        providesTags: ['Posts'],
      }),
    }),
    getUserPost: builder.query({
      query: (userId) => ({        
        url: `users/${userId}/posts`,
        invalidatesTags: [{ type: "Posts", id: "LIST" }],
      }),
    }),
    getUserPostById: builder.query({
      query: ({userId, postId}) => ({
        url: `users/${userId}/posts/${postId}`,
        invalidatesTags: [{ type: "Posts", id: "LIST" }],
      }),
    }),
    getAllPosts: builder.query({
      query: () => `posts`, // Define the query URL
      providesTags: ['Posts'],
    }),
    followUsers:  builder.mutation({
      query: ({ userId, body }) => ({
        url: `users/${userId}/follow`,
        method: 'POST',
        body: body,
        providesTags: ['Users'],
      }),
    }),
    getUserFollowers: builder.query({
      query: (userId) => ({
        url: `users/${userId}/followers`,
        invalidatesTags: [{ type: "Users", id: "LIST" }],
      }),
    }),
    getUserFollowing: builder.query({
      query: (userId) => ({
        url: `users/${userId}/following`,
        invalidatesTags: [{ type: "Users", id: "LIST" }],
      }),
    }),
    changeRole:  builder.mutation({
      query: ({ userId, body }) => ({
        url: `users/${userId}/role`,
        method: 'PATCH',
        providesTags: ['Users'],
      }),
    }),
    updateUsername:  builder.mutation({
      query: ({ userId, body }) => ({
        url: `users/${userId}/username`,
        method: 'PATCH',
        providesTags: ['Users'],
      }),
    }),
  }),
});

export const { 
  useGetUserProfileQuery, 
  useLazyGetUserProfileQuery,
  useLazyGetUserPostQuery,
  useUpdateUserProfileMutation, 
  useCreatePostMutation, 
  useGetUserPostQuery,
  useGetAllPostsQuery,
useGetUserPostByIdQuery,
useFollowUsersMutation, 
useGetUserFollowersQuery, 
useGetUserFollowingQuery,
useChangeRoleMutation,
useUpdateUsernameMutation,
} = userApiSlice;
export const { endpoints: { getUserProfile, 
  updateUserProfile, 
  createPost, 
  getUserPost,
  getAllPosts, 
  getUserPostById, 
  getUserFollowers, 
  followUsers, 
  getUserFollowing, 
  changeRole, updateUsername } } = userApiSlice;

