import React from 'react';
import { Box } from '@chakra-ui/react';

const Error = () => {
  return (
    <Box textAlign="center" fontSize="18px" fontWeight="bold">
      Error loading bookmarks. Please try again.
    </Box>
  );
};

export default Error;
