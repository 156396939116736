import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { motion } from 'framer-motion';
import { Box, Skeleton, Text, useColorMode } from '@chakra-ui/react';
import { RootState, User } from '../../types/interface';
import { useInView } from 'react-intersection-observer';
import { useGetAllPostsQuery } from '../../api/postApi'; 
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { selectCurrentUser } from '../../slice/userSlice';

interface LazyLoadImageProps {
  src: string;
  alt: string;
}

const LazyLoadImage: React.FC<LazyLoadImageProps> = ({ src, alt}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <div ref={ref}>
      {inView && <img src={src} alt={alt}  style={{ width: "500px", height: '500px', borderRadius: "20px", objectFit: "cover"}} />}
    </div>
  );
};

const Banner = () => {
  const language = useSelector((state: RootState) => state.language.language) as string;
  const { colorMode } = useColorMode();;
  const { data: allPostsData, isLoading: allPostsLoading, isError: allPostsError } = useGetAllPostsQuery({});
  const allPosts = allPostsData?.data?.posts || [];
  const navigate = useNavigate();
  const { userId } = useParams();
  const currentUser = useSelector(selectCurrentUser) as unknown as User
  const validUserId = userId || currentUser?.id;

  const sliderSettings = {
    infinite: true,
    speed: 500,
  };

  const breakpoints = {
    992: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    320: {
      slidesPerView: 1,
      spaceBetween: 10,
    },
  };

  const handlePostCardClick = (postId: string) => {
    navigate(`/user/${validUserId}/posts/${postId}`);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
      style={{
        color: colorMode === 'light' ? '#345430' : '#fff',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '20px',
        borderRadius: '20px',
        fontFamily: 'Inter',
        marginLeft: '20px',
        marginRight: '20px',
        flexWrap: 'wrap',
      }}
    >
      <Text fontSize={32} textAlign={'center'} fontWeight="bold" style={{ whiteSpace: 'nowrap' }}>
      {language === 'en' ? "What's New" : language === 'es' ? '¿Qué hay de nuevo?' : 'Kini Tuntun'}
      </Text>
      <Swiper
        grabCursor={true}
        centeredSlides={true}
        pagination={{ clickable: true }}
        navigation={true}
        {...sliderSettings}
        modules={[Navigation]}
        breakpoints={breakpoints}
      >
             {allPostsLoading ? (
              <Box flex="1">
                <Skeleton height="500px" borderRadius={20} color={'transparent'} width="500px" />
              </Box>
            ) : allPostsError ? (
              <Box textAlign="center" paddingTop="20px" fontSize="18px" fontWeight="bold">
                Error loading posts. Please try again.
              </Box>
            ) : (
              <>
                {allPosts.slice(0, 40).map((plant: any, index: any) => (
                  <SwiperSlide key={index}>
                    <Box p={3} width={'100%'}>
                      <LazyLoadImage src={plant.images[0]} alt={plant.name} />
                      <Text 
                      fontWeight={700} 
                      fontFamily="Inter" 
                      marginTop="2" 
                      textTransform={'capitalize'}
                      fontSize={{ base: "20px", md: "20px", lg: "25px" }}
                      onClick={() => handlePostCardClick(plant._id)} 
                      cursor={'pointer'}
                      >
                        {plant.name}
                      </Text>
                    </Box>
                  </SwiperSlide>
                ))}
              </>
            )}
      </Swiper>
    </motion.div>
  );
};

export default Banner;
