import { useColorMode } from "@chakra-ui/react";
import { Box, Flex, Text, VStack, Heading, HStack, UnorderedList, ListItem } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { about, craft, curator, faq } from "../data/about";
import { useSelector } from "react-redux";
import { RootState } from "../types/interface";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { AutoBackButton, Header } from "../components";
import React from "react";

type AboutData = {
  title: string | { [key: string]: string };
  description: string | { [key: string]: string };
};

type FaqData = {
  id: string;
  title: string | { [key: string]: string };
  description: string | { [key: string]: string };
}
const About: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const language = useSelector((state: RootState) => state.language.language);
  const { colorMode } = useColorMode();

  const aboutData: AboutData = about;
  const curatorData: AboutData = curator;
  const craftData: AboutData = craft;
  const faqData: FaqData[] = faq
  

  const articleVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { delay: 0.2, duration: 0.5 } },
  };

  const teamVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { delay: 0.2, duration: 0.5 } },
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeIn" }}
    >
    <Helmet>
        <meta charSet="utf-8" />
        <title>About Us Page</title>
      </Helmet>
      <Header />
      <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeIn" }}
      style={{ backgroundColor:  colorMode === 'light' ? 'white' : '#345430'}}
    >
     <AutoBackButton/>
      <Box
      paddingTop="20px"
      paddingBottom="20px"
      marginRight={{ base: "15", md: "20"}}
      marginLeft={{ base: "15", md: "20"}}
       style={{ backgroundColor:  colorMode === 'light' ? 'white' : '#345430', 
       color: colorMode === 'light' ? '#345430' :  'white'}} >
        <Box p={5}
        >
          <VStack spacing={8} fontFamily="Inter" align="center">
          <HStack
              spacing={8}
              align="flex-start" // Adjust alignment as needed
              justify="center"
              flexDirection={{ base: "column" }} // Adjust direction based on screen size
            >
              <div>
            <motion.section
              variants={articleVariants}
              initial="hidden"
              animate="visible"
            >
              <Heading
                fontFamily="Inter"
                as="h4"
                textAlign="justify"
                fontWeight={600}
              >
                {typeof aboutData.title === "string"
                  ? aboutData.title
                  : aboutData.title[language]}
              </Heading>
              <Text 
              fontSize="lg"
              fontFamily="Inter"
              mb={4}>
                {typeof aboutData.description === "string"
                  ? aboutData.description
                  : aboutData.description[language]}
              </Text>
            </motion.section>

            <motion.section
              variants={articleVariants}
              initial="hidden"
              animate="visible"
            >
          <Box py={5}>
            <Text
              fontFamily="Inter"
              fontSize="25px"
              textAlign="justify"
              fontWeight={400}
            >
              {language === 'en' ? "Key Features: " : (language === 'yo' ? "Awọn Ìkíyèṣí Pàtàkì: " : "Características Clave: ")}
            </Text>
            <UnorderedList styleType="none" textAlign='start'>
              <ListItem fontSize="xl" mt={4} mb={4}>
                <strong>{language === 'en' ? "Ecological Knowledge: " : (language === 'yo' ? "Ìmọ̀ Ìjìnlè: " : "Conocimiento Ecológico: ")}</strong> 
                {language === 'en' ? "Explore Yoruba wisdom on ecology and agriculture. " : (language === 'yo' ? "Ìmọ̀ nípa ewé àti àwọn ohun ọ̀gbìn: títọ ọ̀nà ìmọ̀ àti òye Yorùbá nípa ohun ọ̀gbìn, ewé oko àti gbogbo ohun tí Adẹ́dàá Asẹ̀dá ẹ̀ sílé Ayé" : "Explora la sabiduría yoruba sobre ecología y agricultura. ")}
              </ListItem>
              <ListItem fontSize="xl" mb={4}>
                <strong>
                  {language === 'en' ? "Educational Resources:" : (language === 'yo' ? "Fún ìwàdì ati ẹkọ́: " : "Recursos Educativos: ")}
                </strong> 
                {language === 'en' ? "Access valuable insights for agriculture and medical research. " : (language === 'yo' ? "Ẹ̀kọ́ nípa àwọn ohun tó wà ní agbègbe wa àti bí a ṣe lè lòó wọ́n: ìmọ̀ ìjìnlẹ̀ àti ìwòye lóríi ọ̀gbìn àti ìwádìí nípa ètò ìlera. " : "Accede a información valiosa para la agricultura e investigación médica. ")}
              </ListItem>
              <ListItem fontSize="xl" mb={4}>
                <strong> 
                  {language === 'en' ? "Marketplace:" : (language === 'yo' ? "Ilé Ìtajà :" : "Mercado: ")}
                </strong>    
                {language === 'en' ? "Buy and sell Isese products and natural, organic items. " : (language === 'yo' ? "Ẹ wá ná, ẹ wá ta àwọn ohun èlò Ìṣẹ̀se àti àwọn ohun èlò lórísirísi tí a fi ewé àti àwọn ohun ọ̀gbìn ṣe. " : "Compra y vende productos de Isese y artículos naturales y orgánicos. ")}
              </ListItem>
            </UnorderedList>
          </Box>
          </motion.section>

            <motion.section
              variants={articleVariants}
              initial="hidden"
              animate="visible"
            >
              <Heading
                fontFamily="Inter"
                as="h4"
                size="xl"
                textAlign="justify"
                fontWeight={600}
              >
                {typeof curatorData.title === "string"
                  ? curatorData.title
                  : curatorData.title[language]}
              </Heading>
              <Text 
              fontSize="lg"
              fontFamily="Inter"
              mb={4}>
                {typeof curatorData.description === "string"
                  ? curatorData.description
                  : curatorData.description[language]}
              </Text>
            </motion.section>

            <motion.section
              variants={articleVariants}
              initial="hidden"
              animate="visible"
            >
              <Heading
                fontFamily="Inter"
                as="h4"
                size="xl"
                textAlign="justify"
                fontWeight={600}
              >
                {typeof craftData.title === "string"
                  ? craftData.title
                  : craftData.title[language]}
              </Heading>
              <Text 
              fontSize="lg"
              fontFamily="Inter"
              mb={4}>
                {typeof craftData.description === "string"
                  ? craftData.description
                  : craftData.description[language]}
              </Text>
            </motion.section>

            <motion.section
              variants={articleVariants}
              initial="hidden"
              animate="visible"
            >
               <Heading
                fontFamily="Inter"
                as="h4"
                textAlign="justify"
                fontWeight={600}
                mt={10}
                mb={10}
              >FAQ
              </Heading>
         {faqData.map((faq) => (
          <Box
          key={faq.id}>
                 <Heading
                fontFamily="Inter"
                as="h4"
                textAlign="justify"
                fontWeight={600}
              >
                {typeof faq.title === "string"
                  ? faq.title
                  : faq.title[language]}
              </Heading>
              <Text 
              fontSize="lg"
              fontFamily="Inter"
              mb={4}>
                {typeof faq.description === "string"
                  ? faq.description
                  : faq.description[language]}
              </Text>
          </Box>
         ))}
            </motion.section>
            </div>
            <motion.section
              variants={teamVariants}
              initial="hidden"
              animate="visible"
            >
              <Heading 
                  fontFamily="Inter"
                  as="h4"
                  fontWeight={600}
                  textAlign="justify"
                  paddingBottom={10}
                  >
                Our Team
              </Heading>
              <Flex
                flexWrap="wrap"
                justify="center"
                flexDirection={{ base: "row" }}
              >
              <Box
                  bg={ colorMode === 'light' ? '#E0EAE0' : '#588552'}
                  p={4}
                  m={2}
                  borderRadius="md"
                  boxShadow="md"
                  minWidth="200px"
                  textAlign="center"
                >
                  <Text fontWeight="bold">Olorì Àṣàbí Ifáníwurá</Text>
                  <Text>
                  Administration 
                  </Text>
                </Box>
                <Box
                  bg={ colorMode === 'light' ? '#E0EAE0' : '#588552'}
                  p={4}
                  m={2}
                  borderRadius="md"
                  boxShadow="md"
                  minWidth="200px"
                  textAlign="center"
                >
                  <Text fontWeight="bold">Olorì Àṣàbí Ifáníwurá</Text>
                  <Text>Frontend Developer</Text>
                </Box>
                <Box
                  bg={ colorMode === 'light' ? '#E0EAE0' : '#588552'}
                  p={4}
                  m={2}
                  borderRadius="md"
                  boxShadow="md"
                  minWidth="200px"
                  textAlign="center"
                >
                  <Text fontWeight="bold">John Adeniran</Text>
                  <Text>
                  Backend Developer 
                  </Text>
                </Box>
                <Box
                  bg={ colorMode === 'light' ? '#E0EAE0' : '#588552'}
                  p={4}
                  m={2}
                  borderRadius="md"
                  boxShadow="md"
                  minWidth="200px"
                  textAlign="center"
                >
                  <Text fontWeight="bold">Olorì Àṣàbí Ifáníwurá</Text>
                  <Text>
                  Research
                  </Text>
                </Box>
                <Box
                  bg={ colorMode === 'light' ? '#E0EAE0' : '#588552'}
                  p={4}
                  m={2}
                  borderRadius="md"
                  boxShadow="md"
                  minWidth="200px"
                  textAlign="center"
                >
                  <Text fontWeight="bold">Abayomi Ifapero Shobowale </Text>
                  <Text>
                  Chief Diversification Officer
                  </Text>
                </Box>
                <Box
                  bg={ colorMode === 'light' ? '#E0EAE0' : '#588552'}
                  p={4}
                  m={2}
                  borderRadius="md"
                  boxShadow="md"
                  minWidth="200px"
                  textAlign="center"
                >
                  <Text fontWeight="bold">Olorì Àṣàbí Ifáníwurá</Text>
                  <Text>
                  Design and UX
                  </Text>
                </Box>
                <Box
                  bg={ colorMode === 'light' ? '#E0EAE0' : '#588552'}
                  p={4}
                  m={2}
                  borderRadius="md"
                  boxShadow="md"
                  minWidth="200px"
                  textAlign="center"
                >
                  <Text fontWeight="bold">Miss Mary Agbo</Text>
                  <Text>
                  Design and UX
                  </Text>
                </Box>
                <Box
                  bg={ colorMode === 'light' ? '#E0EAE0' : '#588552'}
                  p={4}
                  m={2}
                  borderRadius="md"
                  boxShadow="md"
                  minWidth="200px"
                  textAlign="center"
                >
                  <Text fontWeight="bold">MR. Suberu Michael Adefolarin</Text>
                  <Text>
                  Mobile Developer
                  </Text>
                </Box>
                <Box
                  bg={ colorMode === 'light' ? '#E0EAE0' : '#588552'}
                  p={4}
                  m={2}
                  borderRadius="md"
                  boxShadow="md"
                  minWidth="200px"
                  textAlign="center"
                >
                  <Text fontWeight="bold">Ìyánífá Ifáfúnmikẹ́ Ifámáyàmí(Àṣàkẹ́)</Text>
                  <Text>
                  Linguist / Curator
                  </Text>
                </Box>
                <Box
                  bg={ colorMode === 'light' ? '#E0EAE0' : '#588552'}
                  p={4}
                  m={2}
                  borderRadius="md"
                  boxShadow="md"
                  minWidth="200px"
                  textAlign="center"
                >
                  <Text fontWeight="bold">Alájọkẹ́</Text>
                  <Text>
                  Linguist / Curator
                  </Text>
                </Box>
                <Box
                  bg={ colorMode === 'light' ? '#E0EAE0' : '#588552'}
                  p={4}
                  m={2}
                  borderRadius="md"
                  boxShadow="md"
                  minWidth="200px"
                  textAlign="center"
                >
                  <Text fontWeight="bold">Ayobami</Text>
                  <Text>
                  Graphic Design
                  </Text>
                </Box>
              </Flex>
            </motion.section>
            </HStack>
          </VStack>
        </Box>
      </Box>
      </motion.div>
    </motion.div>
  );
};

export default About;
