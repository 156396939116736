import React from 'react';
import { Text } from '@chakra-ui/react';

const ArticlePreview: React.FC<{ htmlContent: string }> = ({ htmlContent }) => {
  // Extract the text from the HTML content
  const extractText = (html: string, maxLength: number) => {
    // Create a temporary element to parse the HTML content
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;

    // Get the text content
    let text = tempElement.textContent || '';

    // Split the text into words
    const words = text.trim().split(/\s+/);

    // Limit the words to the specified maximum length
    const truncatedText = words.slice(0, maxLength).join(' ');

    return truncatedText;
  };

  // Define the maximum number of words to display
  const maxLength = 30; // Adjust as needed

  // Extract the truncated text
  const truncatedText = extractText(htmlContent, maxLength);

  return (
    <Text fontSize="md" letterSpacing="2px"  className="preview-content" lineHeight="2" mt={4}>
      {truncatedText}...
    </Text>
  );
};

export default ArticlePreview;
