import React from 'react';
import { IconButton, useColorMode } from '@chakra-ui/react';
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate, NavigateFunction } from 'react-router-dom'; // Import NavigateFunction

const AutoBackButton: React.FC = () => {
  const history = useNavigate() as NavigateFunction; // Cast useNavigate to NavigateFunction
  const { colorMode } = useColorMode();

  const goBack = () => {
    history(-1); // Use the history function with a negative value to go back
  };

  return (
    <IconButton
      aria-label="Go back"
      icon={<IoMdArrowBack />}
      onClick={goBack}
      backgroundColor={colorMode === 'light' ? '#345430' :  'white'}
      color={colorMode === 'light' ? 'white' :  '#345430'}
      position="fixed"
      bottom={4}
      right={4}
      borderRadius="full"
      boxShadow="md"
      zIndex="999"
    />
  );
};

export default AutoBackButton;
