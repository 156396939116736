import htmlParser from 'html-react-parser';

const extractPdfUrls = (htmlContent: string): string[] => {
  const parsedContent = htmlParser(htmlContent);
  let pdfUrls: string[] = [];

  const traverse = (node: any) => {
    if (!node) return;

    // Check for <a> tags with .pdf links
    if (node.type === 'a' && node.props && node.props.href && node.props.href.endsWith('.pdf')) {
      pdfUrls.push(node.props.href);
      return;
    }

    // Check for <embed> tags with .pdf links
    if (node.type === 'embed' && node.props && node.props.src && node.props.src.endsWith('.pdf')) {
      pdfUrls.push(node.props.src);
      return;
    }

    if (node.props && node.props.children && Array.isArray(node.props.children)) {
      for (const child of node.props.children) {
        traverse(child);
      }
    }
  };

  if (Array.isArray(parsedContent)) {
    for (const node of parsedContent) {
      traverse(node);
    }
  } else {
    traverse(parsedContent);
  }

  return pdfUrls;
};

export default extractPdfUrls;
