import React from 'react';
import {
  Box,
  VStack,
  Text,
  useDisclosure,
  useColorMode,
  Image,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import logo from '../../assets/Ewe.png';
import LanguageButton from '../LanguageButton';
import './index.css'
import { Link } from 'react-router-dom';
import ColorModeToggle from '../ColorModeToggle';
import UserMenu from './UserMenu';

interface FullScreenMenuProps {
  navigationItems: string[];
    activePage: string; 
    links: string[];
}

const FullScreenMenu: React.FC<FullScreenMenuProps> = ({ navigationItems, activePage, links }) => {
  const { onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  // Define closeMenu function
  const closeMenu = () => {
    onClose();
  };
  
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Box 
      bg={colorMode === 'light' ? 'white' : '#345430'} 
      boxShadow="none" 
      paddingRight={20} 
      paddingBottom={10} 
      paddingTop={10} 
      paddingLeft={20} 
      display="flex" 
      justifyContent="space-between" 
      alignItems="center"
      // className="sticky-navbar" 
      height={200}
      >
        <Box textAlign="start"
        borderRadius={20}
        color={colorMode === 'light' ? '#345430' : 'white'} 
        >
        <Link to='/'
        >
          <Image
            src={logo}
            alt="Ewé Logo"
           className='logo'
           borderRadius={20}
           bg={colorMode === 'light' ?  'white' : '#E0EAE0' } 
          />
           </Link>
        </Box>
        <Box p={4} paddingLeft="20px" paddingRight="20px" textAlign="center">
        <VStack spacing={4} align="stretch" flexDirection={'row'}>
        {navigationItems.map((item, index) => ( // Added "index" here
          <Link to={`/${links[index]}`} key={item}> 
            <Text
              key={item}
              fontSize="20px"
              fontWeight="600"
              color={colorMode === 'light' ? '#345430' : 'white'} 
              cursor="pointer"
              fontFamily='Inter'
              style={{
                borderBottom: activePage ===  `/${links[index]}`
                ? `2px solid ${colorMode === "light" ? "#345430" : "white"} #` 
                : 'transparent',
              }}
              onClick={onClose}
            >
              {item}
            </Text>
            </Link>
          ))}
        </VStack>
      </Box>
      <UserMenu closeMenu={closeMenu} />
      <LanguageButton closeMenu={closeMenu} />
        <ColorModeToggle/>
      </Box>
    </motion.div>
  );
};

export default FullScreenMenu;
