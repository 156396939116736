import { filterOptions } from "../data/filterType";
import bird from "../assets/bird.png";
import leaf from "../assets/leaf_icon.png";
import chalk from "../assets/chalk.png";
import feather from '../assets/feather.png';
import bark from '../assets/bark.png';
import animal from '../assets/elephant.png';
import fruits from '../assets/fruits.png';
import tree from '../assets/tree.png';
import insect from "../assets/ladybug.png";
import fish from '../assets/zander.png';
import oponIfa from '../assets/download-2.jpg';
import medicine from '../assets/mortar-pestle.png';
import seeds from '../assets/nuts.png';

export const getCategoryIcon = (postType: string | undefined, language: string) => {
  let defaultIcon = leaf;

  if (typeof postType !== 'string') {
    console.warn('postType is not a string:', postType);
    return defaultIcon;
  }

  switch (postType.toLowerCase()) {
    case 'ewé':
    case 'leaf':
    case 'hoja':
      defaultIcon = leaf;
      break;
    case 'ẹyẹ':
    case 'bird':
    case 'pájaro':
      defaultIcon = bird;
      break;
    case 'efún':
    case 'chalk':
    case 'tiza':
      defaultIcon = chalk;
      break;
    case 'iyẹ́':
    case 'feather':
    case 'pluma':
      defaultIcon = feather;
      break;
    case 'eepo igi':
    case 'bark':
    case 'corteza':
      defaultIcon = bark;
      break;
    case 'ẹ́rankó':
    case 'animal':
    // eslint-disable-next-line no-duplicate-case, no-fallthrough
    case 'animal':
      defaultIcon = animal;
      break;
    case 'ẹja':
    case 'fish':
    case 'pez':
      defaultIcon = fish;
      break;
    case 'igi':
    case 'tree':
    case 'árbol':
      defaultIcon = tree;
      break;
    case 'kokoro':
    case 'insect':
    case 'insecto':
      defaultIcon = insect;
      break;
    case 'eso':
    case 'fruit':
    case 'fruta':
      defaultIcon = fruits;
      break;
    case 'Òogùn Ìbílé':
    case 'herbal medicine':
    case 'medicina herbal':
      defaultIcon = medicine;
      break;
    case 'odù ifá':
    case 'ifa verses':
    case 'versos de ifá':
      defaultIcon = oponIfa;
      break;
    case 'irúgbìn':
    case 'seeds':
    case 'semillas':
      defaultIcon = seeds;
      break;
    default:
      defaultIcon = leaf;
  }

  const category = filterOptions.find((option) => {
    if (typeof option.type === 'object') {
      const typeInLanguage = option.type[language];
      return typeInLanguage && typeInLanguage.toLowerCase() === postType.toLowerCase();
    }
    return false;
  });

  return category?.icon || defaultIcon;
};