import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from "react-router-dom";
import { motion } from 'framer-motion';
import { Box, Heading, Text, Flex, Tag, Skeleton, Alert, AlertIcon, useColorMode, Icon, Button } from '@chakra-ui/react';
import { Articles, RootState } from '../types/interface';
import { useSelector } from 'react-redux';
import { ArticleMenu, ArticlesButtons, AutoBackButton, formatCreatedAtDate } from '../components';
import { useLazyGetArticleByIdQuery } from "../api/articles";
import { Link } from 'react-router-dom';
import ArticleComment from '../components/Articles/ArticleComments';
import { FaCheckCircle, FaFilePdf, FaTimesCircle } from 'react-icons/fa';
// import extractPdfUrls from '../components/ExtractPdfFiles'; // Import the extraction function

const ArticleById = () => {
  const { articleId } = useParams<{ articleId?: string }>();
  const [trigger, { data, isLoading, isError }] = useLazyGetArticleByIdQuery();
  const [article, setArticle] = useState<Articles>(data?.data || {});
  const isAuthenticated = useSelector((state: RootState) => state.users.isLogin);
  const { colorMode } = useColorMode();

  useEffect(() => {
    if (articleId) {
      trigger(articleId, true);
    }
  }, [articleId, trigger]);

  useEffect(() => {
    if (data) {
      const articles = data?.data;
      setArticle(articles);
    }
  }, [data]);

  // const pdfUrls = extractPdfUrls(article?.htmlContent || ""); 

  const updateArticleData = (newData: Partial<Articles>) => {
    setArticle(prevPost => ({
      ...prevPost,
      ...newData
    }));
  };

// Function to extract PDF URLs and clean HTML content
const extractPdfAndCleanHtml = (html: string) => {
  if (!html) return { cleanedHtml: '', pdfUrls: [] };

  const pdfUrls = [];
  // Use regex to find all <embed> or <pdf> tags and extract the URLs
  const regex = /<embed\s+src="([^"]+)"[^>]*>/g;
  let match;

  while ((match = regex.exec(html)) !== null) {
    pdfUrls.push(match[1]);
  }

  // Remove all <embed> or <pdf> tags from the HTML content
  const cleanedHtml = html.replace(/<embed\s+[^>]*>|<pdf>.*?<\/pdf>/g, '');

  return { cleanedHtml, pdfUrls };
};

// Get cleaned HTML and PDF URLs
const { cleanedHtml, pdfUrls } = extractPdfAndCleanHtml(article?.htmlContent || ""); 

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>
         {article.title}
        </title>
      </Helmet>

      <Box
        paddingTop="20px"
        paddingBottom="20px"
        marginRight={{ base: '15', md: '20' }}
        marginLeft={{ base: '15', md: '20' }}
        fontFamily="Inter"
      >
        {isLoading ? (
          <Skeleton height="20px" mb="10px" />
        ) : isError ? (
          <Alert status="error">
            <AlertIcon />
            Error loading article. Please try again later.
          </Alert>
        ) : (
          <>
            <Flex justify="space-between" alignItems="center">
              <AutoBackButton />
              <Box display={'block'} alignItems={'start'}>
                <Flex alignItems="center">
                  <Heading as="h2" size="xl" mb={4}>
                    {article.title}
                  </Heading>
                  <Icon
                    as={article.verify ? FaCheckCircle : FaTimesCircle}
                    color={article.verify ? 'green.500' : 'red.500'}
                    fontSize={20}
                    cursor="pointer"
                    ml={2}
                  />
                </Flex>
                <Text
                  textAlign="start"
                  paddingBottom="5px"
                  fontFamily="Inter"
                  paddingTop="5px"
                  fontWeight={600}
                  fontSize={16}
                >
                  {isAuthenticated ?
                    <Link to={`/user/${article?.user?._id}`}>@{article?.user?.username}</Link>
                    : <Link to={'/login'} />}
                </Text>
                <Text
                  paddingBottom="5px"
                  textAlign="start"
                  fontFamily="Inter"
                  fontWeight={600}
                  fontSize={16}
                >{formatCreatedAtDate(article?.createdAt)}</Text>
              </Box>
              {isAuthenticated && article && article.user ?
                <ArticleMenu articleId={article._id} ownerId={article.user._id} />
                : <Link to={'/login'} />}
            </Flex>
            <Text fontSize="lg" letterSpacing="2px" color={colorMode === 'light' ? '#345430' : 'white'} lineHeight="2" mt={4}>
            <Box
                as="div"
                className={colorMode === 'light' ? 'article-content-light preview-content' : 'article-content-dark preview-content'}
                dangerouslySetInnerHTML={{ __html: cleanedHtml }} // Use cleaned HTML content
                style={{ fontFamily: "Inter", fontSize: "16px" }}
              />
            </Text>
            {/* Tags */}
            <Flex mt={4}>
              {article?.keywords?.map((keyword, index) => (
                <Tag key={index} mr={2}>
                  {keyword}
                </Tag>
              ))}
            </Flex>
            <ArticlesButtons
              article={article}
              updatedArticleData={updateArticleData}
            />

              {/* PDF Files Section */}
          {pdfUrls.length > 0 && (
            <Box mt={4} p={4} borderRadius="md" borderWidth="1px" borderColor={colorMode === 'light' ? 'gray.200' : 'gray.600'} backgroundColor={colorMode === 'light' ? 'white' : 'gray.700'}>
              <Heading as="h3" size="lg" mb={4}>
                PDF Files
              </Heading>
              <Flex flexDirection="column" mt={2}>
                {pdfUrls.map((pdf: string, index: number) => (
                  <Box key={index} mb={4} p={3} borderWidth="1px" borderColor={colorMode === 'light' ? 'gray.200' : 'gray.600'} borderRadius="md" _hover={{ bg: colorMode === 'light' ? 'gray.100' : 'gray.600' }}>
                    <Flex alignItems="center">
                      <Icon as={FaFilePdf} color="red.500" boxSize={6} mr={3} />
                      <Box flex="1">
                        <Text fontWeight="bold">
                          {pdf.substring(pdf.lastIndexOf('/') + 1)} {/* Extract the PDF file name */}
                        </Text>
                        <Text fontSize="sm" color={colorMode === 'light' ? 'gray.600' : 'gray.400'}>
                          Click the button below to download or preview the file.
                        </Text>
                      </Box>
                      <Button
                        as="a"
                        href={pdf}
                        download={`Document_${index + 1}.pdf`}
                        colorScheme="teal"
                        variant="outline"
                        size="sm"
                      >
                        Download
                      </Button>
                    </Flex>
                    <Box mt={2}>
                      <iframe
                        title={`PDF Preview ${index + 1}`}
                        src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdf)}&embedded=true`}
                        width="100%"
                        height="500px" // Reduced height for better UI
                        style={{ border: 'none', borderRadius: 'md' }}
                      />
                    </Box>
                  </Box>
                ))}
              </Flex>
            </Box>
          )}

            <ArticleComment article={article} updatedArticleData={updateArticleData} />
          </>
        )}
      </Box>
    </motion.div>
  );
};

export default ArticleById;
