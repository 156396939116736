import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  VStack,
  Text,
  useColorMode,
  Image,
} from '@chakra-ui/react';
import { FiMenu } from 'react-icons/fi';
import { motion } from 'framer-motion';
import logo from '../../assets/Ewe.png';
import './index.css'
import LanguageButton from '../LanguageButton';
import { Link } from 'react-router-dom';
import ColorModeToggle from '../ColorModeToggle';
import UserMenu from './UserMenu';

interface MobileMenuProps {
  navigationItems: string[];
  activePage: string; 
  links: string[];
}

const MobileMenu: React.FC<MobileMenuProps> = ({ navigationItems, activePage, links}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { colorMode } = useColorMode();
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  const closeDrawer = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Box
        // className="sticky-navbar" 
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={4}
        height={150}
        bg={colorMode === 'light' ? 'white' : '#345430'} 
      >
       <Link to='/'
        style={{ width: "100px", height: "100px"}}>
          <Image
            src={logo}
            alt="Ewé Logo"
           className='logo'
           borderRadius={20}
          />
           </Link>
        <IconButton
          aria-label="menu"
          icon={<FiMenu 
          fontSize={35}
          color={colorMode === "light" ? "#345450" : "white"}
          />}
          fontSize={35}
          backgroundColor="transperant"
          onClick={toggleDrawer}
        />
      </Box>
      <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: "easeIn" }}
      style={{ backgroundColor:  colorMode === 'light' ? 'white' : '#345430',
      color: colorMode === 'light' ? '#345430' :  'white'
    }}
    >
      <Drawer
        placement="left"
        isOpen={isOpen}
        onClose={toggleDrawer}
        size="full"
      >
        <DrawerOverlay>
          <DrawerContent
                style={{ backgroundColor:  colorMode === 'light' ? 'white' : '#345430',
                color: colorMode === 'light' ? '#345430' :  'white'}}
                >
            <DrawerHeader display={'flex'} justifyContent={"space-between"} paddingTop={16}>
            <UserMenu closeMenu={closeDrawer} />
            <ColorModeToggle/>
            </DrawerHeader>
            <Box
              textAlign="right"
              padding={4}
            >
              <DrawerCloseButton
                color="red"
                fontSize="25px"
              />
            </Box>
            <DrawerBody>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
                transition={{ duration: 0.5 }}
                style={{ display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center', 
                justifyContent: 'center',
                gap: "20px",
                height: '100%' }}
              >
                <VStack spacing={8} align="stretch" flexDirection={'column'}>
                {navigationItems.map((item, index) => ( // Added "index" here
               <Link to={`${links[index]}`} key={item}> 
                    <Text
                      key={item}
                      fontSize="20px"
                      fontWeight="600"
                      cursor="pointer"
                      fontFamily='Inter'
                      style={{
                        borderBottom: activePage === `/${links[index]}`
                        ? `2px solid ${colorMode === "light" ? "#345430" : "white"}`
                        : 'transparent',                    
                      }}
                      onClick={closeDrawer} 
                    >
                      {item}
                    </Text>
                    </Link>
                  ))}
                </VStack>
                <LanguageButton closeMenu={closeDrawer}/>
              </motion.div>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      </motion.div>
    </>
  );
};

export default MobileMenu;