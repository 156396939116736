import React, { useEffect, useState } from 'react';
import { Box, Text, useColorMode, HStack, VStack, Badge, Button } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useGetStoreByUserIdQuery } from '../../api/stores';
import { Store, User } from '../../types/interface';
import { selectCurrentUser } from '../../slice/userSlice';
import PlanUpgrade from './PlanUpgrade';

const StoreProducts = () => {
  const { colorMode } = useColorMode();
  const currentUser = useSelector(selectCurrentUser) as User;

  // Get store data directly using the hook
  const { data: store, isLoading, isError } = useGetStoreByUserIdQuery(currentUser.id);

  // State for managing store data
  const [storeData, setStoreData] = useState<Store[]>([]);
  const [showUpgradeForm, setShowUpgradeForm] = useState(false);

  useEffect(() => {
    if (store) {
      setStoreData(store.data); // Assuming store.data is an array of stores
    }
  }, [store]);

  // Function to check if the user can create an article
  const canCreateArticle = () => {
    return currentUser?.role === 'SPECIALIST';
  };

  const tokenAvailable = storeData.length > 0 ? storeData[0].tokenAvailable : 0; // Get token from store data

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, ease: 'easeIn' }}
      style={{
        backgroundColor: colorMode === 'light' ? 'white' : '#345430',
        color: colorMode === 'light' ? '#345430' : 'white',
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{storeData.length > 0 ? 'Store Details' : 'Store Listings'}</title>
      </Helmet>
      <Box padding="20px" margin={{ base: '15', md: '20' }}>
        {isLoading ? (
          <Box textAlign="center" fontSize="18px" fontWeight="bold">
            Loading store details...
          </Box>
        ) : isError || storeData.length === 0 ? (
          <Box textAlign="center" fontSize="18px" fontWeight="bold">
            Error loading store details.
          </Box>
        ) : (
          storeData.map((storeItem, index) => {
            const maxProducts = typeof storeItem.tokenAvailable === 'number' ? storeItem.tokenAvailable : 0;
            return (
              <Box
                key={index}
                padding="20px"
                margin="10px"
                borderRadius="10px"
              >
                <Text
                  fontSize={{ base: '20px', md: '30px', lg: '40px' }}
                  fontWeight="600"
                  fontFamily="Inter"
                  letterSpacing={3}
                  textAlign="center"
                  color={colorMode === 'light' ? '#345430' : 'white'}
                  paddingTop="40px"
                  paddingBottom="20px"
                >
                  {storeItem.name}
                </Text>
    
                <Text fontSize="lg" color={colorMode === 'light' ? '#345430' : 'white'} mb={4}>
                  {storeItem.description}
                </Text>

                {/* Categories */}
                <VStack align="start" spacing={1} mb={4}>
                  <Text fontWeight="bold" color={colorMode === 'light' ? '#345430' : 'white'}>
                    Categories:
                  </Text>
                  {storeItem.category && storeItem.category.length > 0 ? (
                    <HStack wrap="wrap">
                      {storeItem.category.map((cat: string, catIndex: number) => (
                        <Badge key={catIndex} colorScheme="green" borderRadius="md" p={1}>
                          {cat}
                        </Badge>
                      ))}
                    </HStack>
                  ) : (
                    <Text color="gray.500">No categories available</Text>
                  )}
                </VStack>

                {/* Additional Information */}
                <VStack align="start" spacing={1}>
                  <Text>
                    <strong>Tokens Available:</strong> {storeItem.tokenAvailable ?? 'N/A'}
                  </Text>
                  <Text>
                    <strong>Status:</strong>{' '}
                    <Badge colorScheme={storeItem.deactivated ? 'red' : 'green'}>
                      {storeItem.deactivated ? 'Deactivated' : 'Active'}
                    </Badge>
                  </Text>
                </VStack>

                {/* Show products only if store is activated */}
                {!storeItem.deactivated && (
                  <>
                    <Text fontSize="2xl" mt={4} mb={4}>
                      Products:
                    </Text>
                    {/* Render products here */}
                  </>
                )}

                {canCreateArticle() && store.tokenAvailable > 0 && !storeItem.deactivated && (
                  <Link to={`stores/${storeItem._id}/create-products?maxProducts=${tokenAvailable}`}>
                    <Button 
                        type='button'
                        backgroundColor={colorMode === 'light' ? "#345430" : "white"}
                        color={colorMode === 'light' ? 'white' : '#345430'}
                        size="lg"
                        width={'sm'}
                        marginTop={'10px'}>
                      Create Product
                    </Button>
                  </Link>
                )}

                {canCreateArticle() && (storeItem.deactivated || store.tokenAvailable === 0) && (
                  <>
                    <Text color="red.500" mt={4}>
                      {storeItem.deactivated
                        ? 'Your store is deactivated. Please send an email to contact@ewe.com.ng to activate your store.'
                        : "You've exhausted your tokens. Upgrade your plan to create more products."}
                    </Text>
                    {!storeItem.deactivated && (
                      <Button
                        onClick={() => setShowUpgradeForm(true)}
                        backgroundColor={colorMode === 'light' ? "#345430" : "white"}
                        color={colorMode === 'light' ? 'white' : '#345430'}
                        size="lg"
                        width={'sm'}
                        marginTop={'10px'}
                      >
                        Upgrade Plan
                      </Button>
                    )}
                  </>
                )}
              </Box>
            );
          })
        )}
      </Box>
      {showUpgradeForm && <PlanUpgrade />}
    </motion.div>
  );
};

export default StoreProducts;
