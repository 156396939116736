import React, { useState } from 'react';
import { Button } from '@chakra-ui/react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import AuthCall from '../../api/auth';
import ToastNotification from '../ToastNotification';
import { addUser, setLoginStatus, updateToken } from '../../slice/userSlice';


interface FacebookLoginButtonProps {
  text: string;
}

const FacebookLoginButton: React.FC<FacebookLoginButtonProps> = ({ text }) => {
  const { useSignInMutation } = AuthCall();
  const dispatch = useDispatch();
  const [signInMutation] = useSignInMutation();
  const randomNumber = Math.floor(Math.random() * 1000);
  const [showToast, setShowToast] = useState(false);
  const [toastConfig, setToastConfig] = useState({
    description: "",
    status: "",
  });
  const navigate = useNavigate();

  const responseFacebook = async (response: any) => {
    console.log(response);
    const userName = response.name.replace(/\s/g, ''); 
    const body = {
      email: response?.email,
      password: response.accessToken,
      name: response.name,
      role: 'user',
      username: `${userName}${randomNumber}`,
      phoneNumber: '',
      confirmPassword: response.accessToken,
      id: response.id,
      field: '',
      about: '',
    };

    try {  
      const response =  await signInMutation(body);

      console.log('Response: ', response);

  
      if ('data' in response) {
        // This is a success response
        const responseData = response.data.data; // Access the data property directly

        const { access_token } = responseData;

        if (access_token) {
          dispatch(updateToken(access_token));
        }
          
        const user = {
          name: responseData.name,
          username: responseData.username,
          email: responseData.email,
          password: responseData.password,
          phoneNumber: responseData.phoneNumber,
          role: responseData.role,
          id: responseData._id,
          about: "",
          field: "",
          confirmPassword: ""
        };

  
        setToastConfig({
          description: response.data.message || `${responseData.name} Signed In Successfully`,
          status: 'success',
        });
        setShowToast(true);       
        dispatch(setLoginStatus({ isLogin: true, user }));
        dispatch(addUser(user));
        navigate("/");
      } else if ('error' in response) {
        const responseData = response.error as { data?: { message?: string } }; // Type assertion to specify shape
        
        if (responseData.data && responseData.data.message) {
          // Check if 'responseData' has both 'data' and 'message' properties
          const errorMessage = responseData.data.message;
        
          setToastConfig({ description: errorMessage, status: 'error' });
          setShowToast(true);
        }
      }  
    } catch (error: any) {
      setToastConfig({ description: error.message, status: 'error' });
      setShowToast(true);
    }
  };

  return (
<div>
  {process.env.REACT_APP_FACEBOOK_APP_ID ? (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
      fields="name,email"
      callback={responseFacebook}
      render={(renderProps) => (
        <Button
          onClick={renderProps.onClick}
          colorScheme="facebook"
          disabled={renderProps.isDisabled}
          width="full"
          borderRadius="10px"
        >
          {text}
        </Button>
      )}
    />
  ) : (
    <p>Facebook App ID is not defined.</p>
  )}
  {showToast && (
    <ToastNotification
      description={toastConfig.description}
      status={toastConfig.status}
      onClose={() => setShowToast(false)}
    />
  )}
</div>

  );
};

export default FacebookLoginButton;