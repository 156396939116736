import htmlParser from 'html-react-parser';
const extractFirstImage = (htmlContent: string): string | null => {
    const parsedContent = htmlParser(htmlContent);
    // console.log('Parsed Content:', parsedContent);
  
    let firstImage: string | null = null;
  
    // Traverse through parsed content to find the first image
    const traverse = (node: any) => {
      if (!node) return;
  
      if (node.type === 'img' && node.props && node.props.src) {
        firstImage = node.props.src;
        // console.log('First Image Found:', firstImage); 
        return;
      }
  
      if (node.props && node.props.children && Array.isArray(node.props.children)) {
        for (const child of node.props.children) {
          traverse(child);
          if (firstImage) break; // Stop traversing once the first image is found
        }
      }
    };
  
    if (Array.isArray(parsedContent)) {
      for (const node of parsedContent) {
        traverse(node);
        if (firstImage) break; // Stop traversing once the first image is found
      }
    } else {
      traverse(parsedContent);
    }
  
    // console.log('First Image Returned:', firstImage);
    return firstImage;
  };
  
export default extractFirstImage;
