import Marquee from "react-fast-marquee";
import companyLogos from "../data/companyLogos";
import { Text, Box, useColorMode } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "../types/interface";

const Sponsors = () => {
  const { colorMode } = useColorMode();
  const language = useSelector((state: RootState) => state.language.language);
  return (
    <Box>
    <Text
     fontSize="25px"
    fontWeight="700"
    color={colorMode === 'light' ? '#345430' :  'white'} 
    fontFamily='Inter'
    textAlign="center"
    padding='10px'
    mt={10}>
{language === "en" ? "Sponsors" : language === "yo" ? 'Àwọn agbèlẹ́yìn wa pàtàkì' : 'Patrocinadores'}

      </Text>
      <Marquee direction="left" speed={50}>
      {companyLogos.map((logo) => (
          <a key={logo.index} href={logo.url} target="_blank" rel="noopener noreferrer">
          <img
            key={logo.index}
            src={logo.path}
            alt={`Sponsor ${logo.index}`}
            style={{ 
            margin: "20px",
            width: "100px", 
            height: "100px", 
            objectFit:"cover", 
            backgroundSize: "cover",
            borderRadius: '10px' }}
          />
          </a>
        ))}
      </Marquee>
      </Box>
  );
};

export default Sponsors;
