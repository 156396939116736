import React from 'react';
import { useColorMode } from '@chakra-ui/react';
import { BsToggleOff, BsToggleOn } from 'react-icons/bs';

function ColorModeToggle() {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <button onClick={toggleColorMode}>
      {colorMode === 'light' ? (
        <BsToggleOff size={24}  color="#345430"  />
      ) : (
        <BsToggleOn size={24} color="white" />
      )}
    </button>
  );
}

export default ColorModeToggle;
