import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../types/interface";
import { selectAccessToken } from "../slice/userSlice";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const storeApiSlice = createApi({
  reducerPath: 'stores',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState | undefined;
      const accessToken = state ? selectAccessToken(state) : '';
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      headers.set('Content-Type', 'application/json');
      headers.set('Access-Control-Allow-Origin', '*');
      return headers;
    },
  }),
  tagTypes: ['Stores'],
  endpoints: (builder) => ({
    getStores: builder.query({
      query: () => `stores`,
      providesTags: ['Stores'],
    }),
    getStoreByUserId: builder.query({
      query: (userId) => `stores/users/${userId}`,
      providesTags: (result, error, id) => [{ type: 'Stores', id }],
    }),
    createStore: builder.mutation({
      query: (newStore) => ({
        url: `stores`,
        method: 'POST',
        body: newStore,
      }),
      invalidatesTags: ['Stores'],
    }),
    updateStore: builder.mutation({
      query: ({ storeId, ...rest }) => ({
        url: `/stores/${storeId}`,
        method: 'PATCH',
        body: rest,
      }),
      invalidatesTags: (result, error, { storeId }) => [{ type: 'Stores', id: storeId }],
    }),
    deleteStore: builder.mutation({
      query: (storeId) => ({
        url: `/stores/${storeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Stores', id }],
    }),
    createStoreProducts: builder.mutation({
      query: ({storeId, body}) => ({
        url:  `/stores/${storeId}/products`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Stores'],
    }),
    getStoreProduct: builder.query({
      query: (storeId) => `stores/${storeId}/products`,
      providesTags: (result, error, id) => [{ type: 'Stores', id }],
    }),
  }),
});

export const { 
  useGetStoresQuery, 
  useGetStoreByUserIdQuery, 
  useCreateStoreMutation, 
  useUpdateStoreMutation, 
  useDeleteStoreMutation,
  useCreateStoreProductsMutation,
  useGetStoreProductQuery
} = storeApiSlice;
