import React, { useState, ChangeEvent } from "react";
import { Select, Flex, Box, Image, useColorMode } from "@chakra-ui/react";
import { RootState } from "../types/interface";
import { useSelector } from "react-redux";

interface FilterComponentProps {
  onFilterChange: (selectedFilter: string) => void;
  filterOptions: {
    id: number;
    icon: string;
    type: string | { [key: string]: string };
  }[];
}


const FilterComponent: React.FC<FilterComponentProps> = ({ onFilterChange, filterOptions }) => {
  const language = useSelector((state: RootState) => state.language.language);
  const { colorMode } = useColorMode();

  const [selectedType, setSelectedType] = useState("");

  const handleTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const type = event.target.value;
    setSelectedType(type);
    onFilterChange(type);
  };

  return (
    <Flex alignItems="center" width={"100%"} height={"100px"}>
      <Select
        placeholder="Filter by..."
        value={selectedType}
        onChange={handleTypeChange}
        backgroundColor={colorMode === 'light' ? '#E0EAE0' :  '#588552'}
        borderColor={"#345430"}
        borderRadius={10}
        fontFamily="Inter"
        fontSize="20px"
        _focus={{ borderColor: colorMode === 'light' ? '#345430' :  'white' }}
        width={"100%"}
        height={"60px"}
      >
        <option value="">All Types</option>
        {filterOptions.map((post) => (
          <option key={post.id} value={typeof post.type === "string" ? post.type : post.type[language]}>
            {typeof post.type === "string" ? post.type : post.type[language]}
          </option>
        ))}
      </Select>
      {selectedType ? (
        <Box marginLeft={5}>
          <Image
            src={filterOptions.find((post) =>
              typeof post.type === 'string'
                ? post.type
                : post.type[language] === selectedType
            )?.icon}
            boxSize="60px"
          />
        </Box>
      ) : (
        ""
      )}
    </Flex>
  );
};

export default FilterComponent;